import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import { flushSync } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Divider,
  Tab,
  Tabs,
  TableCell,
  Typography,
  useTheme,
  Box,
  LinearProgress,
} from '@mui/material';
import { ProjectStatus } from '../../../../types';
import { PROJECTS } from '../../../../data';
import {
  Breadcrumbs,
  Chip,
  ChipInlineWrapper,
  Container,
  DialogHeader,
  FullscreenDialog,
  LinearProgressIndicator,
  PageTitle,
  PresentationChip,
  PresentationPage,
  Section,
  TransdimensionalOffset,
  TransdimensionalRect,
  TransdimensionalTable,
} from '../../..';
import { ReferenceProjectsPageThree } from '.';

const getHashValue = (hash: string | null | undefined) =>
  (hash || '#').replace(/^#/, '') || null;

export const ProjectStatusChip: FC<{ projectStatus: ProjectStatus }> = ({
  projectStatus,
}) => {
  const theme = useTheme();

  switch (projectStatus) {
    case 'active-development':
      return (
        <Chip background={theme.palette.brandYellow.main}>
          Active Development
        </Chip>
      );
    case 'maintenance':
      return (
        <Chip background={theme.palette.background.chip}>Maintenance</Chip>
      );
    case 'hiatus':
      return <Chip background={theme.palette.background.chip}>Hiatus</Chip>;
    case 'experimental':
      return (
        <Chip background={theme.palette.brandOrange.main}>Maintenance</Chip>
      );
  }
};

export const ReferenceProjectsPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dialogElementRef = useRef<HTMLDivElement>(null);
  const handleClickRow = (
    rowId: string,
    event: React.MouseEvent<HTMLTableRowElement>
  ) => {
    navigate({
      hash: `#${rowId}`,
    });
  };

  const rowElementsRef = useRef(
    new Map<string /* row ID */, HTMLTableRowElement>()
  );
  const handleRowElement = (
    rowId: string,
    rowElement: HTMLTableRowElement | null
  ) => {
    if (rowElement) {
      rowElementsRef.current.set(rowId, rowElement);
    } else {
      rowElementsRef.current.delete(rowId);
    }
  };

  const [rowId, setRowId] = useState<string | null>(
    getHashValue(location.hash)
  );
  const [activeRowId, setActiveRowId] = useState<string | null>(rowId);
  const activeItem =
    PROJECTS.find((project) => project.id === activeRowId) ?? null;

  useEffect(() => {
    const rowId = getHashValue(location.hash);

    setRowId(rowId);

    if (rowId) {
      flushSync(() => {
        setActiveRowId(rowId);
      });
    }
  }, [location.hash]);

  const theme = useTheme();

  return (
    <>
      <PageTitle>Projects Reference</PageTitle>
      <Section
        id="referenceProjectsPage_indexSection"
        three={<ReferenceProjectsPageThree />}
      >
        <LinearProgressIndicator position="sticky" />
        <Container maxWidth="lg">
          <Breadcrumbs
            variant="pageHeader"
            prefixBrandCircle
            items={[
              {
                to: '/reference',
                text: 'Reference',
              },
              {
                to: '/reference/projects',
                text: 'Projects',
              },
            ]}
          />
          <TransdimensionalTable
            id="referenceProjectsPage_indexSection-table"
            headCells={[
              {
                id: 'id',
                content: 'Identifier',
                align: 'left',
              },
              {
                id: 'description',
                content: 'Description',
                align: 'left',
              },
              {
                id: 'status',
                content: 'Status',
                align: 'right',
              },
            ]}
            onClickRow={handleClickRow}
            refRow={handleRowElement}
            rows={PROJECTS.map((project) => ({
              id: project.id,
              button: true,
              content: (
                <>
                  <TableCell align="left">
                    <ChipInlineWrapper>
                      <TransdimensionalRect
                        id={project.id}
                        display="inline-block"
                      >
                        <TransdimensionalOffset
                          z={theme.depth.chip}
                          display="inline-block"
                        >
                          <PresentationChip presentationChip={project.chip} />
                        </TransdimensionalOffset>
                      </TransdimensionalRect>
                    </ChipInlineWrapper>
                  </TableCell>
                  <TableCell align="left">
                    <Typography variant="body1">
                      {project.description}
                    </Typography>
                  </TableCell>
                  <TableCell align="right">
                    <ChipInlineWrapper>
                      <ProjectStatusChip projectStatus={project.status} />
                    </ChipInlineWrapper>
                  </TableCell>
                </>
              ),
            }))}
          />
        </Container>
      </Section>
      <FullscreenDialog
        ref={dialogElementRef}
        open={!!rowId}
        originElement={() => {
          if (!activeRowId) return null;

          const rowElement = rowElementsRef.current.get(
            activeRowId
          ) as HTMLElement;

          return rowElement ?? null;
        }}
        originElementBackground={theme.palette.background.paper}
        onClose={() => navigate({ hash: '' })}
      >
        <Container maxWidth="lg" disableGutters>
          <DialogHeader>
            <Breadcrumbs
              variant="pageHeader"
              prefixBrandCircle
              maxItems={3}
              items={[
                {
                  to: '/reference',
                  text: 'Reference',
                },
                {
                  to: '/reference/projects',
                  text: 'Projects',
                },
                {
                  to: '/reference/projects#portfolio-web',
                  text: activeItem?.name ?? '-',
                },
              ]}
            />
          </DialogHeader>
        </Container>
        <Container maxWidth="lg">
          {activeItem && (
            <>
              <PageTitle>{`${activeItem.name} | Project Reference`}</PageTitle>
              <PresentationPage presentationPage={activeItem.page} />
            </>
          )}
        </Container>
      </FullscreenDialog>
    </>
  );
};
