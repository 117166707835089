import { FC } from 'react';
import classNames from 'classnames';
import { MathUtils, Vector2 } from 'three';
import { Box, SxProps, Theme, useTheme } from '@mui/material';
import { staggerOne, roundScalar, pointsToString } from '../../utilities';
import { LayoutRangeEffect } from '..';

import './ProgressArrow.scss';

const LINE_WIDTH = 12;
const ARROW_HEIGHT = LINE_WIDTH * 3;
const ARROW_WIDTH = ARROW_HEIGHT / 2 / Math.tan(MathUtils.degToRad(45));

const FILL_COLOR_RATIO_STEP = 0.15;
const FILL_COLOR_COUNT = 3;
const FILL_POINT_COEFFICIENT = 2;

export const RadialProgressArrow: FC<{
  id: string;
  fromLayoutPoint?: string;
  toLayoutPoint?: string;
  width: number;
  height: number;
  showTopBar?: boolean;
  topBarWidth?: number;
  bottomBarWidth?: number;
  className?: string;
  sx?: SxProps<Theme>;
}> = ({
  id,
  fromLayoutPoint,
  toLayoutPoint,
  width,
  height,
  topBarWidth,
  bottomBarWidth,
  className,
  sx,
  ...props
}) => {
  const showTopBar = !!props.showTopBar;

  const theme = useTheme();

  const maskId = `${id}-default-mask`;
  const maskUrl = `url(#${maskId})`;

  const verticalPadding = (ARROW_HEIGHT - LINE_WIDTH) / 2;
  const expandedHeight = height + 2 * verticalPadding;
  const fillRadius = expandedHeight / 2;
  const bottomBarOffsetX = (bottomBarWidth || width) - width;

  const renderFillColorPolygon = (
    fromRatio: number,
    toRatio: number,
    color: string
  ) => {
    const fromAngle = fromRatio * Math.PI;
    const toAngle = toRatio * Math.PI;

    return (
      <polygon
        points={pointsToString([
          new Vector2(width, fillRadius),
          new Vector2(
            (1 - Math.sin(fromAngle) * FILL_POINT_COEFFICIENT) * width,
            (1 - Math.cos(fromAngle) * FILL_POINT_COEFFICIENT) * fillRadius
          ),
          new Vector2(
            (1 - Math.sin(toAngle) * FILL_POINT_COEFFICIENT) * width,
            (1 - Math.cos(toAngle) * FILL_POINT_COEFFICIENT) * fillRadius
          ),
        ])}
        fill={color}
        mask={maskUrl}
      />
    );
  };

  const renderFillColorPolygons = (
    fillRatio: number,
    color: string,
    colorIndex: number
  ) => {
    const colorFillRatio = staggerOne({
      value: fillRatio,
      index: colorIndex,
      count: FILL_COLOR_COUNT,
      step: FILL_COLOR_RATIO_STEP,
    });

    return (
      <>
        {colorFillRatio > 0 &&
          renderFillColorPolygon(0, Math.min(colorFillRatio, 0.5), color)}
        {colorFillRatio > 0.5 &&
          renderFillColorPolygon(0.5, Math.min(colorFillRatio, 1), color)}
      </>
    );
  };

  return (
    <LayoutRangeEffect
      id={id}
      fromLayoutPoint={fromLayoutPoint}
      toLayoutPoint={toLayoutPoint}
      anchorOffset="-WINDOW_INNERHEIGHT / 2"
    >
      {({ ratio }) => {
        if (ratio === null) return null;

        return (
          <Box
            component="svg"
            width={roundScalar(width)}
            height={roundScalar(expandedHeight)}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            className={classNames('ProgressArrow', className)}
            sx={sx}
          >
            <mask id={maskId}>
              {showTopBar && (
                <rect
                  x={0}
                  y={0}
                  width={
                    topBarWidth ? roundScalar(topBarWidth) : roundScalar(width)
                  }
                  height={LINE_WIDTH}
                  fill="white"
                />
              )}
              <rect
                x={0}
                y={verticalPadding}
                width={LINE_WIDTH}
                height={roundScalar(height)}
                fill="white"
              />
              <rect
                x={bottomBarOffsetX}
                y={roundScalar(verticalPadding + height - LINE_WIDTH)}
                width={roundScalar(width - ARROW_WIDTH)}
                height={LINE_WIDTH}
                fill="white"
              />
              <polygon
                points={pointsToString([
                  new Vector2(
                    width + bottomBarOffsetX - ARROW_WIDTH,
                    expandedHeight - ARROW_HEIGHT
                  ),
                  new Vector2(
                    width + bottomBarOffsetX,
                    expandedHeight - ARROW_HEIGHT / 2
                  ),
                  new Vector2(
                    width + bottomBarOffsetX - ARROW_WIDTH,
                    expandedHeight
                  ),
                ])}
                fill="white"
              />
            </mask>
            <rect
              x="0"
              y="0"
              width={roundScalar(width)}
              height={roundScalar(expandedHeight)}
              fill={theme.palette.text.disabled}
              mask={maskUrl}
            />
            {renderFillColorPolygons(ratio, theme.palette.brandYellow.main, 2)}
            {renderFillColorPolygons(ratio, theme.palette.brandOrange.main, 1)}
            {renderFillColorPolygons(ratio, theme.palette.brandRed.main, 0)}
          </Box>
        );
      }}
    </LayoutRangeEffect>
  );
};

export const LinearProgressArrow: FC<{
  id: string;
  fromLayoutPoint?: string;
  toLayoutPoint?: string;
  width: number;
  className?: string;
  sx?: SxProps<Theme>;
}> = ({
  id,
  fromLayoutPoint,
  toLayoutPoint,
  width,
  className,
  sx,
  ...props
}) => {
  const theme = useTheme();

  const maskId = `${id}-default-mask`;
  const maskUrl = `url(#${maskId})`;

  const height = ARROW_HEIGHT;
  const verticalPadding = (height - LINE_WIDTH) / 2;

  const renderFillColorPolygon = (
    fromRatio: number,
    toRatio: number,
    color: string
  ) => {
    const fromOffset = fromRatio * width;
    const toOffset = toRatio * width;

    return (
      <rect
        x={roundScalar(fromOffset)}
        y={0}
        width={roundScalar(toOffset - fromOffset)}
        height={ARROW_HEIGHT}
        fill={color}
        mask={maskUrl}
      />
    );
  };

  const renderFillColorPolygons = (
    fillRatio: number,
    color: string,
    colorIndex: number
  ) => {
    const colorFillRatio = staggerOne({
      value: fillRatio,
      index: colorIndex,
      count: FILL_COLOR_COUNT,
      step: FILL_COLOR_RATIO_STEP,
    });

    return renderFillColorPolygon(0, Math.max(colorFillRatio, 0), color);
  };

  return (
    <LayoutRangeEffect
      id={id}
      fromLayoutPoint={fromLayoutPoint}
      toLayoutPoint={toLayoutPoint}
      anchorOffset="-WINDOW_INNERHEIGHT / 2"
    >
      {({ ratio }) => {
        if (ratio === null) return null;

        return (
          <Box
            component="svg"
            width={roundScalar(width)}
            height={roundScalar(height)}
            xmlns="http://www.w3.org/2000/svg"
            version="1.1"
            className={classNames('ProgressArrow', className)}
            sx={sx}
          >
            <mask id={maskId}>
              <rect
                x={0}
                y={verticalPadding}
                width={roundScalar(width - ARROW_WIDTH)}
                height={LINE_WIDTH}
                fill="white"
              />
              <polygon
                points={pointsToString([
                  new Vector2(width - ARROW_WIDTH, height - ARROW_HEIGHT),
                  new Vector2(width, height - ARROW_HEIGHT / 2),
                  new Vector2(width - ARROW_WIDTH, height),
                ])}
                fill="white"
              />
            </mask>
            <rect
              x="0"
              y="0"
              width={roundScalar(width)}
              height={roundScalar(height)}
              fill={theme.palette.text.disabled}
              mask={maskUrl}
            />
            {renderFillColorPolygons(ratio, theme.palette.brandYellow.main, 2)}
            {renderFillColorPolygons(ratio, theme.palette.brandOrange.main, 1)}
            {renderFillColorPolygons(ratio, theme.palette.brandRed.main, 0)}
          </Box>
        );
      }}
    </LayoutRangeEffect>
  );
};
