import { ElementState, ElementStateUpdate } from '../types';
import { uuid } from '../utilities';

type ElementStateChangeHandler = (
  newState: ElementState,
  previousState?: ElementState
) => void;

const DEFAULT_STATE: ElementState = {
  disabled: false,
  hover: false,
  active: false,
};

export class TransdimensionalInteractionService {
  // UUID
  private uuid: string = uuid();

  // States
  private stateById = new Map<string /* element ID */, ElementState>();
  private stateChangeHandlersById = new Map<
    string /* element ID */,
    Set<ElementStateChangeHandler>
  >();

  public mergeState(id: string, partialState: ElementStateUpdate) {
    const previousState = this.stateById.get(id);
    const state = {
      ...DEFAULT_STATE,
      ...previousState,
      ...partialState,
    };

    this.stateById.set(id, state);

    const stateChangeHandlers =
      this.stateChangeHandlersById.get(id) || new Set();
    stateChangeHandlers.forEach((stateChangeHandler) =>
      stateChangeHandler(state, previousState)
    );
  }

  public onChange(id: string, stateChangeHandler: ElementStateChangeHandler) {
    const stateChangeHandlers = new Set(this.stateChangeHandlersById.get(id));
    stateChangeHandlers.add(stateChangeHandler);

    this.stateChangeHandlersById.set(id, stateChangeHandlers);
  }

  public setDisabled(id: string, disabled: boolean) {
    this.mergeState(id, {
      disabled,
    });
  }

  // Hover
  public startInteractionHover(id: string) {
    this.mergeState(id, {
      hover: true,
    });
  }

  public endInteractionHover(id: string) {
    this.mergeState(id, {
      hover: false,
    });
  }

  // Active
  public startInteractionActive(id: string) {
    this.mergeState(id, {
      active: true,
    });
  }

  public endInteractionActive(id: string) {
    this.mergeState(id, {
      active: false,
    });
  }
}
