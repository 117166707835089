import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { Box, SxProps, Theme } from '@mui/material';

import './Section.scss';

export const Section: FC<
  PropsWithChildren<{
    id?: string;
    className?: string;
    three?: ReactNode;
    sx?: SxProps<Theme>;
  }>
> = ({ id, className, three, sx, children }) => {
  return (
    <Box
      component="section"
      id={id}
      className={classNames('Section-root', className)}
      sx={[
        {
          paddingLeft: 'env(safe-area-inset-left)',
          paddingRight: 'env(safe-area-inset-right)',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {three && <div className="Section-threeContainer">{three}</div>}
      <div className="Section-contentContainer">{children}</div>
    </Box>
  );
};
