import { FC, Fragment, useEffect, useMemo, useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import {
  Box,
  IconButton,
  MenuItem,
  MenuList,
  Typography,
  useTheme,
} from '@mui/material';
import { NavigationItem } from '../../types';
import { useSpacing } from '../../hooks';
import { roundScalar } from '../../utilities';
import './NavigationHierarchy.scss';

const MAXIMUM_DEPTH = 5;
const INDENT_SPACING = 3;
const ITEM_HEIGHT = 44;
const STROKE_WIDTH = 4;

interface NavigationItemFlat {
  item: NavigationItem;
  depth: number;
}

function flattenNavigationItems(
  items: NavigationItem[],
  depth = 0
): NavigationItemFlat[] {
  return items.reduce(
    (itemsFlat, item) => [
      ...itemsFlat,
      { item, depth },
      ...flattenNavigationItems(item.children ?? [], depth + 1),
    ],
    [] as Array<NavigationItemFlat>
  );
}

export const NavigationHierarchyItem: FC<{
  item: NavigationItem;
  active: boolean;
  depth?: number;
}> = ({ item, active, ...props }) => {
  const location = useLocation();
  const theme = useTheme();
  const depth = props.depth ?? 0;
  const isLink = !!item.path;
  const isLocationActive =
    item.path?.startsWith('#') && location.hash === item.path;

  return (
    <MenuItem
      {...(isLink && {
        component: NavLink,
        to: item.path,
      })}
      className="NavigationHierarchy-item"
      sx={{
        marginLeft: INDENT_SPACING * depth,
      }}
      onClick={() => {
        if (!item.targetSelector) return;

        const target = document.querySelector(item.targetSelector!);

        if (!target) return;

        target.scrollIntoView({ behavior: 'smooth' });
      }}
    >
      <Typography
        variant="body1"
        fontWeight={500}
        color={
          active || isLocationActive
            ? theme.palette.text.primary
            : theme.palette.text.secondary
        }
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          transition: theme.transitions.create('color'),
        }}
      >
        {item.title}
        {isLocationActive && (
          <Box
            component="span"
            sx={{ color: theme.palette.secondary.main, marginLeft: '0.25em' }}
          >
            #
          </Box>
        )}
      </Typography>
    </MenuItem>
  );
};

export const NavigationHierarchy: FC<{
  items: NavigationItem[];
}> = (props) => {
  const theme = useTheme();
  const svgPadding = useSpacing(1);
  const itemsFlat = useMemo(
    () => flattenNavigationItems(props.items),
    props.items
  );
  const indentWidth = useSpacing(INDENT_SPACING);

  const getPathPosition = (itemIndex: number, depth: number) => {
    return `L ${roundScalar(depth * indentWidth)} ${roundScalar(
      itemIndex * ITEM_HEIGHT
    )} `;
  };

  let path = 'M 0 0 ';
  let pathLength = 0;

  const itemPathOffsets = new Map<
    string /* item id */,
    number /* path offset */
  >();

  let previousDepth = 0;

  itemsFlat.forEach(({ item, depth }, itemIndex) => {
    if (depth !== previousDepth) {
      path += getPathPosition(itemIndex, depth);
      pathLength += Math.abs(depth - previousDepth) * indentWidth;
    }

    itemPathOffsets.set(item.id, pathLength);

    path += getPathPosition(itemIndex + 1, depth);
    pathLength += ITEM_HEIGHT;

    previousDepth = depth;
  });

  const [activeItemIds, setActiveItemIds] = useState(new Set<string>());

  useEffect(() => {
    const observers = itemsFlat
      .filter(({ item }) => !!item.targetSelector)
      .map(({ item }) => {
        const target = document.querySelector(item.targetSelector!);

        if (!target) return null;

        const observer = new IntersectionObserver(
          (entries) => {
            entries.forEach((entry) => {
              if (entry.isIntersecting) {
                setActiveItemIds((activeItemIds) => {
                  const newActiveItemIds = new Set(activeItemIds);
                  newActiveItemIds.add(item.id);

                  return newActiveItemIds;
                });
              } else {
                setActiveItemIds((activeItemIds) => {
                  const newActiveItemIds = new Set(activeItemIds);
                  newActiveItemIds.delete(item.id);

                  return newActiveItemIds;
                });
              }
            });
          },
          {
            threshold: [0, 1],
          }
        );
        observer.observe(target);

        return observer;
      });

    return () => {
      observers.forEach((observer) => {
        observer?.disconnect();
      });
    };
  }, [itemsFlat]);

  const activeItemPathOffsets = Array.from(activeItemIds)
    .map((itemId) => itemPathOffsets.get(itemId))
    .filter((pathOffset) => typeof pathOffset === 'number') as number[];
  const minimumActiveItemPathOffset =
    Math.min(...activeItemPathOffsets) + STROKE_WIDTH / 2;
  const maximumActiveItemPathOffset =
    Math.max(...activeItemPathOffsets) + ITEM_HEIGHT - STROKE_WIDTH / 2;
  const activeItemPathOffsetDifference =
    maximumActiveItemPathOffset - minimumActiveItemPathOffset;

  return (
    <Box component="div" sx={{ position: 'relative', top: theme.spacing(1) }}>
      <MenuList
        sx={{
          padding: 0,
        }}
      >
        {itemsFlat.map(({ item, depth }) => {
          return (
            <NavigationHierarchyItem
              key={item.id}
              item={item}
              active={activeItemIds.has(item.id)}
              depth={depth}
            />
          );
        })}
      </MenuList>
      <Box
        component="svg"
        width={MAXIMUM_DEPTH * indentWidth}
        height={itemsFlat.length * ITEM_HEIGHT + svgPadding * 2}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        sx={{
          position: 'absolute',
          left: -svgPadding,
          top: -svgPadding,
          pointerEvents: 'none',
          transition: theme.transitions.create('opacity'),
          opacity: activeItemIds.size > 0 ? 1 : 0,
        }}
      >
        <g
          transform={`translate(${svgPadding}, ${svgPadding})`}
          fill="transparent"
          strokeWidth={STROKE_WIDTH}
          strokeDashoffset="1"
          strokeLinecap="round"
          strokeLinejoin="miter"
        >
          <path
            stroke={theme.palette.brandRed.main}
            strokeDasharray={`1 ${roundScalar(
              minimumActiveItemPathOffset
            )} ${roundScalar(activeItemPathOffsetDifference / 3)} ${roundScalar(
              pathLength +
                1 -
                (minimumActiveItemPathOffset +
                  activeItemPathOffsetDifference / 3)
            )}`}
            d={path}
            style={{
              transition: 'all 0.3s ease',
            }}
          />
          <path
            stroke={theme.palette.brandYellow.main}
            strokeDasharray={`1 ${roundScalar(
              minimumActiveItemPathOffset +
                (2 * activeItemPathOffsetDifference) / 3
            )} ${roundScalar(activeItemPathOffsetDifference / 3)} ${roundScalar(
              pathLength +
                1 -
                (minimumActiveItemPathOffset + activeItemPathOffsetDifference)
            )}`}
            d={path}
            style={{
              transition: 'all 0.3s ease',
            }}
          />
          <path
            strokeLinecap="square"
            stroke={theme.palette.brandOrange.main}
            strokeDasharray={`1 ${roundScalar(
              minimumActiveItemPathOffset + activeItemPathOffsetDifference / 3
            )} ${roundScalar(activeItemPathOffsetDifference / 3)} ${roundScalar(
              pathLength +
                1 -
                (minimumActiveItemPathOffset +
                  (2 * activeItemPathOffsetDifference) / 3)
            )}`}
            d={path}
            style={{
              transition: 'all 0.3s ease',
            }}
          />
        </g>
      </Box>
    </Box>
  );
};
