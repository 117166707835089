import { FC, useContext } from 'react';
import { useTheme } from '@mui/material';
import { TransdimensionalUnitConversionServiceContext } from '../../../../contexts';
import {
  useWindowSize,
  useThreeContainerBounds,
  useMaterial,
} from '../../../../hooks';
import {
  Three,
  ThreeQuad,
  ThreeBox,
  ThreeModel,
  ThreeScrollOffsetContainer,
  TransdimensionalButtonThree,
  ThreeLighting,
  TransdimensionalButtonThreeBox,
} from '../../..';

// Component constants
const ID = 'introduction-three';

const FULL_WIDTH = 1000;
const FULL_HEIGHT = 1000;
const FULL_DEPTH = 1000;

const TOP_BEAM_SIZE = 1;
const BALL_PIPE_DIAMETER = 0.8;

const PIPE_DIAMETER = 0.5;
const PIPE_SPACING = 0.15;
const PIPE_DOUBLE_VENT_WIDTH = 2 * PIPE_DIAMETER + PIPE_SPACING;
// const PIPE_VENT_HEIGHT = TOP_BEAM_SIZE - 2 * PIPE_SPACING;
const PIPE_VENT_DEPTH = 0.1;

const RIGHT_PIPE_UPPER_SEGMENT_LENGTH = 3;
const RIGHT_PIPE_LOWER_SEGMENT_LENGTH = 0.5;

const LEFT_PIPE_UPPER_SEGMENT_LENGTH =
  RIGHT_PIPE_UPPER_SEGMENT_LENGTH - (PIPE_SPACING + PIPE_DIAMETER);

const TUNNEL_SIZE_INNER_OUTER_RATIO = 0.75;

const MainPageIntroductionSectionThreeInner: FC = () => {
  const transdimensionalUnitConversionService = useContext(
    TransdimensionalUnitConversionServiceContext
  );

  const containerBounds = useThreeContainerBounds();
  const theme = useTheme();
  const material = useMaterial();
  const materialProps = {
    material,
  };

  if (!transdimensionalUnitConversionService || !containerBounds) return null;

  const sceneHeight =
    transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);

  const tunnelSizeOuterScreen =
    transdimensionalUnitConversionService.getWindowSize().y / 2;
  const tunnelSizeOuter = transdimensionalUnitConversionService.clientToThreeX(
    tunnelSizeOuterScreen
  );
  const tunnelSizeInner = tunnelSizeOuter * TUNNEL_SIZE_INNER_OUTER_RATIO;
  const tunnelEdgeSize = (tunnelSizeOuter - tunnelSizeInner) / 2;

  return (
    <>
      {/* top beam with pipe opening */}
      <ThreeModel
        {...materialProps}
        id="pipe_block"
        position={[0, -(TOP_BEAM_SIZE / 2), -(TOP_BEAM_SIZE / 2)]}
        scale={[BALL_PIPE_DIAMETER, TOP_BEAM_SIZE, BALL_PIPE_DIAMETER]}
      />
      <ThreeBox
        {...materialProps}
        position={[
          0,
          -(TOP_BEAM_SIZE / 2),
          -(TOP_BEAM_SIZE - BALL_PIPE_DIAMETER) / 4,
        ]}
        scale={[
          BALL_PIPE_DIAMETER,
          TOP_BEAM_SIZE,
          (TOP_BEAM_SIZE - BALL_PIPE_DIAMETER) / 2,
        ]}
      />
      <ThreeBox
        {...materialProps}
        position={[
          0,
          -(TOP_BEAM_SIZE / 2),
          -TOP_BEAM_SIZE + (TOP_BEAM_SIZE - BALL_PIPE_DIAMETER) / 4,
        ]}
        scale={[
          BALL_PIPE_DIAMETER,
          TOP_BEAM_SIZE,
          (TOP_BEAM_SIZE - BALL_PIPE_DIAMETER) / 2,
        ]}
      />
      <ThreeBox
        {...materialProps}
        position={[
          (FULL_WIDTH + TOP_BEAM_SIZE) / 2,
          -(TOP_BEAM_SIZE / 2),
          -(TOP_BEAM_SIZE / 2),
        ]}
        scale={[
          FULL_WIDTH + TOP_BEAM_SIZE - BALL_PIPE_DIAMETER,
          TOP_BEAM_SIZE,
          TOP_BEAM_SIZE,
        ]}
      />
      <ThreeBox
        {...materialProps}
        position={[
          (FULL_WIDTH + TOP_BEAM_SIZE) / -2,
          -(TOP_BEAM_SIZE / 2),
          -(TOP_BEAM_SIZE / 2),
        ]}
        scale={[
          FULL_WIDTH + TOP_BEAM_SIZE - BALL_PIPE_DIAMETER,
          TOP_BEAM_SIZE,
          TOP_BEAM_SIZE,
        ]}
      />

      {/* left piping with valve handles */}
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.left - PIPE_DIAMETER / 2,
          -(TOP_BEAM_SIZE + PIPE_SPACING) / 2,
          PIPE_SPACING + PIPE_DIAMETER / 2,
        ]}
        scale={[PIPE_DIAMETER, TOP_BEAM_SIZE + PIPE_SPACING, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.left -
            (PIPE_DIAMETER + PIPE_SPACING + PIPE_DIAMETER / 2),
          -(TOP_BEAM_SIZE + PIPE_SPACING) / 2,
          PIPE_SPACING + PIPE_DIAMETER / 2,
        ]}
        scale={[PIPE_DIAMETER, TOP_BEAM_SIZE + PIPE_SPACING, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.left - PIPE_DIAMETER / 2,
          -(TOP_BEAM_SIZE + PIPE_SPACING + PIPE_DIAMETER / 2),
          PIPE_SPACING + PIPE_DIAMETER / 2,
        ]}
        rotation={[0, 0, -Math.PI]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.left -
            (PIPE_DIAMETER + PIPE_SPACING + PIPE_DIAMETER / 2),
          -(TOP_BEAM_SIZE + PIPE_SPACING + PIPE_DIAMETER / 2),
          PIPE_SPACING + PIPE_DIAMETER / 2,
        ]}
        rotation={[0, 0, -Math.PI]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.left - PIPE_DIAMETER / 2,
          -(TOP_BEAM_SIZE + PIPE_SPACING + PIPE_DIAMETER / 2),
          (PIPE_SPACING + (PIPE_DIAMETER - TOP_BEAM_SIZE) / 2) / 2,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[
          PIPE_DIAMETER,
          (TOP_BEAM_SIZE - PIPE_DIAMETER) / 2 + PIPE_SPACING,
          PIPE_DIAMETER,
        ]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.left -
            (PIPE_DIAMETER + PIPE_SPACING + PIPE_DIAMETER / 2),
          -(TOP_BEAM_SIZE + PIPE_SPACING + PIPE_DIAMETER / 2),
          (PIPE_SPACING + (PIPE_DIAMETER - TOP_BEAM_SIZE) / 2) / 2,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[
          PIPE_DIAMETER,
          (TOP_BEAM_SIZE - PIPE_DIAMETER) / 2 + PIPE_SPACING,
          PIPE_DIAMETER,
        ]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.left - PIPE_DIAMETER / 2,
          -(TOP_BEAM_SIZE + PIPE_SPACING + PIPE_DIAMETER / 2),
          -(TOP_BEAM_SIZE / 2),
        ]}
        rotation={[0, -Math.PI, 0]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.left -
            (PIPE_DIAMETER + PIPE_SPACING + PIPE_DIAMETER / 2),
          -(TOP_BEAM_SIZE + PIPE_SPACING + PIPE_DIAMETER / 2),
          -(TOP_BEAM_SIZE / 2),
        ]}
        rotation={[0, -Math.PI, 0]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.left - PIPE_DIAMETER / 2,
          -(
            TOP_BEAM_SIZE +
            PIPE_SPACING +
            PIPE_DIAMETER +
            LEFT_PIPE_UPPER_SEGMENT_LENGTH / 2
          ),
          -(TOP_BEAM_SIZE / 2),
        ]}
        scale={[PIPE_DIAMETER, LEFT_PIPE_UPPER_SEGMENT_LENGTH, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.left -
            (PIPE_DIAMETER + PIPE_SPACING + PIPE_DIAMETER / 2),
          -(
            TOP_BEAM_SIZE +
            (PIPE_SPACING + PIPE_DIAMETER + LEFT_PIPE_UPPER_SEGMENT_LENGTH) / 2
          ),
          -(TOP_BEAM_SIZE / 2),
        ]}
        scale={[
          PIPE_DIAMETER,
          LEFT_PIPE_UPPER_SEGMENT_LENGTH - (PIPE_SPACING + PIPE_DIAMETER),
          PIPE_DIAMETER,
        ]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_valve_handle"
        position={[
          containerBounds.left - PIPE_DIAMETER / 2,
          -(
            TOP_BEAM_SIZE +
            PIPE_SPACING +
            PIPE_DIAMETER +
            PIPE_SPACING +
            PIPE_DIAMETER / 2
          ),
          -TOP_BEAM_SIZE / 2 + PIPE_DIAMETER - 0.1,
        ]}
        scale={1}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_valve_handle"
        position={[
          containerBounds.left -
            (PIPE_DIAMETER + PIPE_SPACING + PIPE_DIAMETER / 2),
          -(
            TOP_BEAM_SIZE +
            PIPE_SPACING +
            PIPE_DIAMETER +
            PIPE_SPACING +
            PIPE_DIAMETER / 2
          ),
          -TOP_BEAM_SIZE / 2 + PIPE_DIAMETER - 0.1,
        ]}
        scale={1}
        rotation={[Math.PI / 2, 0, 0]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.left -
            (PIPE_DIAMETER + PIPE_SPACING + PIPE_DIAMETER / 2),
          -(TOP_BEAM_SIZE + LEFT_PIPE_UPPER_SEGMENT_LENGTH + PIPE_DIAMETER / 2),
          -(TOP_BEAM_SIZE / 2),
        ]}
        rotation={[0, Math.PI / 2, -Math.PI]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.left - PIPE_DIAMETER / 2,
          -(
            TOP_BEAM_SIZE +
            PIPE_SPACING +
            PIPE_DIAMETER +
            LEFT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(TOP_BEAM_SIZE / 2),
        ]}
        rotation={[0, Math.PI / 2, -Math.PI]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.left -
            (PIPE_DIAMETER + PIPE_SPACING + PIPE_DIAMETER + FULL_WIDTH / 2),
          -(TOP_BEAM_SIZE + LEFT_PIPE_UPPER_SEGMENT_LENGTH + PIPE_DIAMETER / 2),
          -(TOP_BEAM_SIZE / 2),
        ]}
        rotation={[0, 0, Math.PI / 2]}
        scale={[PIPE_DIAMETER, FULL_WIDTH, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.left - (PIPE_DIAMETER + FULL_WIDTH / 2),
          -(
            TOP_BEAM_SIZE +
            PIPE_SPACING +
            PIPE_DIAMETER +
            LEFT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(TOP_BEAM_SIZE / 2),
        ]}
        rotation={[0, 0, Math.PI / 2]}
        scale={[PIPE_DIAMETER, FULL_WIDTH, PIPE_DIAMETER]}
      />

      {/* right piping with vent */}
      <ThreeModel
        {...materialProps}
        id="pipe_vent_double"
        position={[
          containerBounds.right + PIPE_DOUBLE_VENT_WIDTH / 2,
          -(TOP_BEAM_SIZE / 2),
          PIPE_VENT_DEPTH / 2,
        ]}
        scale={[1, 1, 1]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.right + PIPE_DIAMETER / 2,
          -(TOP_BEAM_SIZE + RIGHT_PIPE_UPPER_SEGMENT_LENGTH / 2),
          -(TOP_BEAM_SIZE / 2),
        ]}
        scale={[PIPE_DIAMETER, RIGHT_PIPE_UPPER_SEGMENT_LENGTH, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.right +
            PIPE_DIAMETER +
            PIPE_SPACING +
            PIPE_DIAMETER / 2,
          -(TOP_BEAM_SIZE + RIGHT_PIPE_UPPER_SEGMENT_LENGTH / 2),
          -(TOP_BEAM_SIZE / 2),
        ]}
        scale={[PIPE_DIAMETER, RIGHT_PIPE_UPPER_SEGMENT_LENGTH, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.right + PIPE_DIAMETER / 2,
          -(
            TOP_BEAM_SIZE +
            RIGHT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(TOP_BEAM_SIZE / 2),
        ]}
        rotation={[0, 0, -Math.PI]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.right +
            PIPE_DIAMETER +
            PIPE_SPACING +
            PIPE_DIAMETER / 2,
          -(
            TOP_BEAM_SIZE +
            RIGHT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(TOP_BEAM_SIZE / 2),
        ]}
        rotation={[0, 0, -Math.PI]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.right +
            PIPE_DIAMETER +
            PIPE_SPACING +
            PIPE_DIAMETER / 2,
          -(
            TOP_BEAM_SIZE +
            RIGHT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(TOP_BEAM_SIZE + RIGHT_PIPE_LOWER_SEGMENT_LENGTH) / 2,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[PIPE_DIAMETER, RIGHT_PIPE_LOWER_SEGMENT_LENGTH, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.right + PIPE_DIAMETER / 2,
          -(
            TOP_BEAM_SIZE +
            RIGHT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(
            TOP_BEAM_SIZE +
            RIGHT_PIPE_LOWER_SEGMENT_LENGTH +
            PIPE_SPACING +
            PIPE_DIAMETER
          ) / 2,
        ]}
        rotation={[Math.PI / 2, 0, 0]}
        scale={[
          PIPE_DIAMETER,
          RIGHT_PIPE_LOWER_SEGMENT_LENGTH + PIPE_SPACING + PIPE_DIAMETER,
          PIPE_DIAMETER,
        ]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.right +
            PIPE_DIAMETER +
            PIPE_SPACING +
            PIPE_DIAMETER / 2,
          -(
            TOP_BEAM_SIZE +
            RIGHT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(
            TOP_BEAM_SIZE / 2 +
            RIGHT_PIPE_LOWER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
        ]}
        rotation={[-Math.PI, -Math.PI / 2, -Math.PI / 2]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.right + PIPE_DIAMETER / 2,
          -(
            TOP_BEAM_SIZE +
            RIGHT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(
            TOP_BEAM_SIZE / 2 +
            RIGHT_PIPE_LOWER_SEGMENT_LENGTH +
            PIPE_DIAMETER +
            PIPE_SPACING +
            PIPE_DIAMETER / 2
          ),
        ]}
        rotation={[-Math.PI, -Math.PI / 2, -Math.PI / 2]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe_corner"
        position={[
          containerBounds.right +
            PIPE_DIAMETER +
            PIPE_SPACING +
            PIPE_DIAMETER / 2,
          -(
            TOP_BEAM_SIZE +
            RIGHT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(
            TOP_BEAM_SIZE / 2 +
            RIGHT_PIPE_LOWER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
        ]}
        rotation={[-Math.PI, -Math.PI / 2, -Math.PI / 2]}
        scale={[PIPE_DIAMETER, PIPE_DIAMETER, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.right +
            PIPE_DIAMETER +
            PIPE_SPACING +
            PIPE_DIAMETER +
            FULL_WIDTH / 2,
          -(
            TOP_BEAM_SIZE +
            RIGHT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(
            TOP_BEAM_SIZE / 2 +
            RIGHT_PIPE_LOWER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
        ]}
        rotation={[0, 0, Math.PI / 2]}
        scale={[PIPE_DIAMETER, FULL_WIDTH, PIPE_DIAMETER]}
      />
      <ThreeModel
        {...materialProps}
        id="pipe"
        position={[
          containerBounds.right + PIPE_DIAMETER + FULL_WIDTH / 2,
          -(
            TOP_BEAM_SIZE +
            RIGHT_PIPE_UPPER_SEGMENT_LENGTH +
            PIPE_DIAMETER / 2
          ),
          -(
            TOP_BEAM_SIZE / 2 +
            RIGHT_PIPE_LOWER_SEGMENT_LENGTH +
            PIPE_DIAMETER +
            PIPE_SPACING +
            PIPE_DIAMETER / 2
          ),
        ]}
        rotation={[0, 0, Math.PI / 2]}
        scale={[PIPE_DIAMETER, FULL_WIDTH, PIPE_DIAMETER]}
      />

      <TransdimensionalButtonThree id="introduction-get-in-touch">
        <TransdimensionalButtonThreeBox colorProfile="secondary" />
      </TransdimensionalButtonThree>

      {/* bottom back plane */}
      <ThreeQuad
        {...materialProps}
        position={[
          0,
          -sceneHeight,
          -(tunnelEdgeSize + tunnelSizeInner + FULL_DEPTH / 2),
        ]}
        scale={[FULL_WIDTH, FULL_DEPTH, 1]}
        rotation={[Math.PI / -2, 0, 0]}
      />

      {/* bottom tunnel edge quad front */}
      <ThreeQuad
        {...materialProps}
        position={[
          containerBounds.left - tunnelEdgeSize - tunnelSizeInner / 2,
          -sceneHeight,
          -(tunnelEdgeSize / 2),
        ]}
        scale={[tunnelSizeInner, tunnelEdgeSize, 1]}
        rotation={[Math.PI / -2, 0, 0]}
      />

      {/* bottom tunnel edge quad left */}
      <ThreeQuad
        {...materialProps}
        position={[
          containerBounds.left -
            tunnelEdgeSize -
            tunnelSizeInner -
            FULL_WIDTH / 2,
          -sceneHeight,
          -((tunnelEdgeSize + tunnelSizeInner) / 2),
        ]}
        scale={[FULL_WIDTH, tunnelEdgeSize + tunnelSizeInner, 1]}
        rotation={[Math.PI / -2, 0, 0]}
      />

      {/* bottom tunnel edge quad right */}
      <ThreeQuad
        {...materialProps}
        position={[
          containerBounds.left - tunnelEdgeSize + FULL_WIDTH / 2,
          -sceneHeight,
          -((tunnelEdgeSize + tunnelSizeInner) / 2),
        ]}
        scale={[FULL_WIDTH, tunnelEdgeSize + tunnelSizeInner, 1]}
        rotation={[Math.PI / -2, 0, 0]}
      />

      {/* bottom tunnel cover */}
      <ThreeQuad
        {...materialProps}
        position={[
          containerBounds.left - tunnelEdgeSize - tunnelSizeInner / 2,
          -sceneHeight,
          -(tunnelEdgeSize + tunnelSizeInner / 2),
        ]}
        scale={[tunnelSizeInner, tunnelSizeInner, 1]}
        rotation={[Math.PI / -2, 0, 0]}
      />

      {/* bottom tunnel quad back */}
      <ThreeQuad
        {...materialProps}
        position={[
          containerBounds.left - tunnelEdgeSize - tunnelSizeInner / 2,
          -(sceneHeight + FULL_HEIGHT / 2),
          -(tunnelEdgeSize + tunnelSizeInner),
        ]}
        scale={[tunnelSizeInner, FULL_HEIGHT, 1]}
        rotation={[0, 0, 0]}
      />

      {/* bottom tunnel quad left */}
      <ThreeQuad
        {...materialProps}
        position={[
          containerBounds.left - tunnelEdgeSize - tunnelSizeInner,
          -(sceneHeight + FULL_HEIGHT / 2),
          -(tunnelEdgeSize + tunnelSizeInner / 2),
        ]}
        scale={[tunnelSizeInner, FULL_HEIGHT, 1]}
        rotation={[0, Math.PI / 2, 0]}
      />
    </>
  );
};

export const MainPageIntroductionSectionThree: FC = () => {
  const windowSize = useWindowSize();

  return (
    <Three
      id={ID}
      inset
      className="IntroductionThree"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeScrollOffsetContainer>
        <ThreeLighting />
        <MainPageIntroductionSectionThreeInner />
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
