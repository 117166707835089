import { FC, PropsWithChildren, useRef } from 'react';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import './FadeTransition.scss';

export const FadeTransition: FC<
  PropsWithChildren<{
    activeKey: string;
  }>
> = ({ activeKey, children }) => {
  const nodeRef = useRef<HTMLDivElement | null>(null);

  return (
    <TransitionGroup component="div" className="FadeTransition-root">
      <CSSTransition
        key={activeKey}
        classNames="FadeTransition"
        timeout={800}
        appear={true}
        enter={true}
        exit={true}
      >
        <div ref={nodeRef} className="FadeTransition">
          {children}
        </div>
      </CSSTransition>
    </TransitionGroup>
  );
};
