import { FC } from 'react';
import { Box, Container, useTheme } from '@mui/material';
import { NavigationItem } from '../../../../types';
import { Breadcrumbs, LayoutAnchor, NavigationHierarchy, PageTitle } from '../../..';
import {
  Reference3DAssetsPagePrimitivesSection,
  Reference3DAssetsPageThree,
} from '.';

import './Reference3DAssetsPage.scss';

const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    id: 'primitives',
    title: 'Primitives',
    path: '#primitives',
    targetSelector: '#primitives-section',
  },
];

export const Reference3DAssetsPage: FC = () => {
  const theme = useTheme();

  return (
    <>
      <PageTitle>3D Assets Reference</PageTitle>
      <Container maxWidth="lg">
        <Breadcrumbs
          variant="pageHeader"
          prefixBrandCircle
          items={[
            {
              to: '/reference',
              text: 'Reference',
            },
            {
              to: '/reference/3d-assets',
              text: '3D Assets',
            },
          ]}
        />
        <Reference3DAssetsPageThree />
        <div style={{ display: 'flex', position: 'relative' }}>
          <Box
            component="nav"
            sx={{
              width: 240,
              marginRight: 4,
              flexShrink: 0,
            }}
          >
            <NavigationHierarchy items={NAVIGATION_ITEMS} />
          </Box>
          <Reference3DAssetsPagePrimitivesSection />
        </div>
        <LayoutAnchor
          id="assetsPage_container_left"
          style={{ position: 'absolute', left: 0 }}
          x
        />
        <LayoutAnchor
          id="assetsPage_container_right"
          style={{ position: 'absolute', right: 0 }}
          x
        />
      </Container>
    </>
  );
};
