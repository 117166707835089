import { createContext } from 'react';
import { Progress } from '../types';

const ProgressContext = createContext<Progress>({
  active: false,
  setActive: (_progress) => {
    console.warn(
      'Progress context setActive was called on the default context value.'
    );
  },
});
ProgressContext.displayName = 'ProgressContext';

export { ProgressContext };
