import { FC, PropsWithChildren } from 'react';
import { Box, Checkbox, SxProps, Theme, useTheme } from '@mui/material';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

export const SelectionOutline: FC<
  PropsWithChildren<{
    className?: string;
    sx?: SxProps<Theme>;
    active?: boolean;
  }>
> = (props) => {
  const theme = useTheme();
  const borderWidth = theme.spacing(1);

  return (
    <Box
      className="SelectionOutline-root"
      component="div"
      sx={{
        position: 'relative',
        '&:hover > .SelectionOutline-outline': {
          outlineColor: theme.palette.secondary.dark,
          '& > .SelectionOutline-checkbox': {
            backgroundColor: theme.palette.secondary.dark,
          },
        },
      }}
    >
      {props.children}
      <Box
        className="SelectionOutline-outline"
        component="div"
        sx={{
          position: 'absolute',
          left: borderWidth,
          top: borderWidth,
          width: `calc(100% - 2 * ${borderWidth})`,
          height: `calc(100% - 2 * ${borderWidth})`,
          outline: `${borderWidth} solid`,
          outlineColor: theme.palette.secondary.main,
          pointerEvents: 'none',
          transition: theme.transitions.create(['opacity', 'outline-color']),
          opacity: props.active ? 1 : 0,
        }}
      >
        <Box
          className="SelectionOutline-checkbox"
          component="div"
          sx={{
            position: 'absolute',
            top: `calc(-${borderWidth} / 2)`,
            right: `calc(-${borderWidth} / 2)`,
            width: theme.spacing(3),
            height: theme.spacing(3),
            textAlign: 'center',
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.secondary.contrastText,
            transition: theme.transitions.create(['background-color']),
          }}
        >
          <CheckOutlinedIcon
            fontSize="small"
            color="inherit"
            sx={{ display: 'inline-block' }}
          />
        </Box>
      </Box>
    </Box>
  );
};
