import { Color } from 'three';
import { PointColorCoercible } from '../../types';

export const toColor = (value: PointColorCoercible): Color => {
  if (typeof value === 'number') {
    return new Color(value);
  } else if (Array.isArray(value)) {
    return new Color(value[0], value[1], value[2]);
  } else if (value instanceof Color) {
    return value.clone();
  } else {
    return new Color(value);
  }
};
