import { FC } from 'react';
import { Section, PageHeader, Container } from '../../..';
import { ReferencePageIntroductionSectionThree } from '.';

export const ReferencePageIntroductionSection: FC = () => {
  return (
    <Section
      id="referencePage_introductionSection"
      three={<ReferencePageIntroductionSectionThree />}
    >
      <Container maxWidth="lg">
        <PageHeader variant="search" />
      </Container>
    </Section>
  );
};
