import { FC } from 'react';
import { Box, Typography } from '@mui/material';
import { REFERENCE_ASSETS_3D } from '../../../../../constants';
import { useSpacing } from '../../../../../hooks';
import {
  Chip,
  Heading,
  Section,
  TransdimensionalGridContainer,
  TransdimensionalGridItem,
  TransdimensionalRect,
} from '../../../..';

export const Reference3DAssetsPagePrimitivesSection: FC = () => {
  return (
    <Section id="primitives-section">
      <Box component="div" sx={{ padding: 2 }}>
        <Heading
          variant="h4"
          id="primitives"
          sx={{ position: 'relative', zIndex: 1 }}
        >
          Primitives
        </Heading>
        <TransdimensionalGridContainer
          id="assetsPage_primitiveAssetsGrid"
          spacing={4}
        >
          {REFERENCE_ASSETS_3D.map((referenceAsset3d) => (
            <TransdimensionalGridItem
              key={referenceAsset3d.id}
              xs={12}
              md={4}
              lg={3}
            >
              <TransdimensionalRect
                id={referenceAsset3d.id}
                className="AssetPreview-root"
              >
                <div className="AssetPreview-content">
                  <TransdimensionalRect
                    id={`${referenceAsset3d.id}-chip`}
                    component="span"
                    className="Chip"
                  >
                    <Chip className="AssetPreview-name">
                      {referenceAsset3d.id}
                    </Chip>
                  </TransdimensionalRect>
                </div>
              </TransdimensionalRect>
            </TransdimensionalGridItem>
          ))}
        </TransdimensionalGridContainer>
      </Box>
    </Section>
  );
};
