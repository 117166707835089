import { FC } from 'react';
import { Section } from '../../..';
import { MainPageServicesGrid } from './MainPageServicesGrid';
import { Container, useTheme } from '@mui/material';

import './MainPageServicesSection.scss';

export const MainPageServicesSection: FC = () => {
  const theme = useTheme();

  return (
    <Section
      id="section-services"
      sx={{
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          left: 0,
          top: 352,
          width: '100%',
          height: 'calc(100% - 352px)',
          backgroundColor: theme.palette.background.default,
        },
      }}
    >
      <MainPageServicesGrid />
    </Section>
  );
};
