import { FC, useRef, useEffect, useState } from 'react';
import classNames from 'classnames';
import CSS from 'csstype';
import { useTheme } from '@mui/material';
import { TypealongValueService } from '../../services';
import { delay } from '../../utilities';

import './Typealong.scss';

const TYPING_DELAY_MINIMUM = 50;
const TYPING_DELAY_MAXIMUM = 100;
const GAP_DURATION = 200;
const PAUSE_DURATION = 2400;
const SELECT_DURATION = 1400;
const SHRINK_DURATION = 400;
const getRandomTypingDelay = () => {
  return (
    TYPING_DELAY_MINIMUM +
    Math.random() * (TYPING_DELAY_MAXIMUM - TYPING_DELAY_MINIMUM)
  );
};

export const Typealong: FC<{
  values: string[];
  recentValuesCount: number;
  styles?: {
    valueContainer?: {
      default?: CSS.StandardProperties;
      selecting?: CSS.StandardProperties;
      shrinking?: CSS.StandardProperties;
    };
    value?: {
      default?: CSS.StandardProperties;
      selecting?: CSS.StandardProperties;
      shrinking?: CSS.StandardProperties;
    };
    selectionBackground?: {
      default?: CSS.StandardProperties;
      selecting?: CSS.StandardProperties;
      shrinking?: CSS.StandardProperties;
    };
  };
  classes?: {
    valueContainer?: {
      default?: string;
      selecting?: string;
      shrinking?: string;
    };
    value?: {
      default?: string;
      selecting?: string;
      shrinking?: string;
    };
    selectionBackground?: {
      default?: string;
      selecting?: string;
      shrinking?: string;
    };
  };
}> = ({ values, recentValuesCount, styles, classes }) => {
  const typealongValueServiceRef = useRef(
    new TypealongValueService(values, recentValuesCount)
  );
  const typealongValueService = typealongValueServiceRef.current;

  const theme = useTheme();

  const [value, setValue] = useState('');
  const [selecting, setSelecting] = useState(false);
  const [shrinking, setShrinking] = useState(false);

  useEffect(() => {
    (async () => {
      const typeValue = async (targetValue: string) => {
        let value = '';

        setValue(value);

        for (let index = 0; index < targetValue.length; index++) {
          await delay(getRandomTypingDelay());

          value += targetValue[index];

          setValue(value);
        }
      };

      // DREAM clear these timeout values when the component unmounts
      while (true) {
        const targetValue = typealongValueService.getNextValue();

        setShrinking(false);

        await typeValue(targetValue);
        await delay(PAUSE_DURATION);

        setSelecting(true);

        await delay(SELECT_DURATION - SHRINK_DURATION);

        setShrinking(true);

        await delay(SHRINK_DURATION);

        setSelecting(false);

        await delay(GAP_DURATION);
      }
    })();
  }, [setValue, setSelecting, setShrinking]);

  return (
    <span
      className={classNames(
        'Typealong-valueContainer',
        classes?.valueContainer?.default,
        {
          [classes?.valueContainer?.selecting || '']: selecting,
          ['Typealong-valueContainer_shrinking']: shrinking,
          [classes?.valueContainer?.shrinking || '']: shrinking,
        }
      )}
      style={{
        ...styles?.valueContainer?.default,
        ...(selecting && styles?.valueContainer?.selecting),
        ...(shrinking && styles?.valueContainer?.shrinking),
      }}
    >
      <span
        className={classNames('Typealong-value', classes?.value?.default, {
          'Typealong-value_selecting': selecting,
          [classes?.value?.selecting || '']: selecting,
          [classes?.value?.shrinking || '']: shrinking,
        })}
        style={{
          ...(selecting && { color: theme.palette.text.secondary }),
          ...styles?.value?.default,
          ...(selecting && styles?.value?.selecting),
          ...(shrinking && styles?.value?.shrinking),
        }}
      >
        <div
          className={classNames(
            'Typealong-value-selectionBackground',
            classes?.selectionBackground?.default,
            {
              [classes?.selectionBackground?.selecting || '']: selecting,
              [classes?.selectionBackground?.shrinking || '']: shrinking,
            }
          )}
          style={{
            ...(selecting && { background: theme.palette.text.primary }),
            ...styles?.selectionBackground?.default,
            ...(selecting && styles?.selectionBackground?.selecting),
            ...(shrinking && styles?.selectionBackground?.shrinking),
          }}
        />
        <span>{value}</span>
      </span>
    </span>
  );
};
