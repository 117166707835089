import { FC, PropsWithChildren } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import RouterOutlinedIcon from '../../../../@mui/icons-material/RouterOutlined';
import WifiModemOutlinedIcon from '../../../../@mui/icons-material/WifiModemOutlined';

import { Breadcrumbs, Heading, Section, TransdimensionalRect } from '../../..';
import { ReferenceInfrastructurePageThree } from '.';

const InfrastructureEntity: FC<
  PropsWithChildren<{ id: string; sx?: SxProps<Theme> }>
> = ({ id, sx, children }) => {
  const theme = useTheme();

  return (
    <TransdimensionalRect
      id={id}
      sx={[
        {
          backgroundColor: theme.palette.background.paper,
          padding: theme.spacing(0, 1),
          textAlign: 'center',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </TransdimensionalRect>
  );
};

const InfrastructureConnection: FC<{}> = () => {
  const theme = useTheme();

  return (
    <Box
      component="div"
      sx={{
        position: 'relative',
        height: theme.spacing(2),
        margin: theme.spacing(0.5, 0),
        '&::before': {
          content: '""',
          position: 'absolute',
          left: `calc((100% - ${theme.spacing(3)}) / 2)`,
          width: theme.spacing(3),
          height: '100%',
          borderTop: `${theme.spacing(0.5)} solid`,
          borderBottom: `${theme.spacing(0.5)} solid`,
          borderColor: theme.palette.divider,
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          left: `calc((100% - ${theme.spacing(0.5)}) / 2)`,
          top: theme.spacing(0.5),
          width: theme.spacing(0.5),
          height: `calc(100% - 2 * ${theme.spacing(0.5)})`,
          backgroundColor: theme.palette.divider,
        },
      }}
    />
  );
};

export const ReferenceInfrastructurePage: FC = () => {
  const theme = useTheme();

  return (
    <Section
      id="referencePage_indexSection"
      three={<ReferenceInfrastructurePageThree />}
    >
      <Container maxWidth="lg">
        <Breadcrumbs
          variant="pageHeader"
          prefixBrandCircle
          items={[
            {
              to: '/reference',
              text: 'Reference',
            },
            {
              to: '/reference/infrastructure',
              text: 'Infrastructure',
            },
          ]}
        />
        <Heading variant="h2" id="network">
          Network
        </Heading>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <InfrastructureEntity id="internet">
              <Typography variant="body2">Internet</Typography>
            </InfrastructureEntity>
            <InfrastructureConnection />
            <InfrastructureEntity id="modem" sx={{ padding: 2 }}>
              <Stack
                direction="row"
                spacing={0.5}
                sx={{ justifyContent: 'center' }}
              >
                <WifiModemOutlinedIcon sx={{ margin: 0.5 }} />
                <Typography variant="body1" fontWeight="bold">
                  Modem
                </Typography>
              </Stack>
            </InfrastructureEntity>
            <InfrastructureConnection />
            <InfrastructureEntity id="router-wan">
              <Typography variant="body2">WAN - E4:5F:01:38:8A:34</Typography>
            </InfrastructureEntity>
            <InfrastructureConnection />
            <InfrastructureEntity id="router" sx={{ padding: 2 }}>
              <Stack
                direction="row"
                spacing={0.5}
                sx={{ justifyContent: 'center' }}
              >
                <RouterOutlinedIcon sx={{ margin: 0.5 }} />
                <Typography variant="body1" fontWeight="bold">
                  Router
                </Typography>
              </Stack>
            </InfrastructureEntity>
            <InfrastructureConnection />
            <InfrastructureEntity id="router-lan">
              <Typography variant="body2">LAN - 2A:E4:AA:36:F3:B6</Typography>
            </InfrastructureEntity>
          </Grid>
        </Grid>
        <Heading variant="h2" id="services">
          Services
        </Heading>
      </Container>
    </Section>
  );
};
