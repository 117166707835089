import { useEffect, useMemo, useRef } from 'react';
import { Vector2 } from 'three';
import { THREE_CAMERA_DEPTH, THREE_DEFAULT_FOV } from '../../constants';
import { getVisibleHeightAtDepth } from '../../utilities';
import { TransdimensionalOffsetOptions } from '../../types';

export const useTransdimensionalOffsetLink = (
  options: TransdimensionalOffsetOptions
): {
  link: (element: HTMLElement | null) => void;
  unlink: () => void;
} => {
  const elementRef = useRef<HTMLElement | null>();
  const handleScroll = useMemo(() => {
    return () => {
      const element = elementRef.current;

      if (!element) return;

      const { scrollX, scrollY, innerWidth, innerHeight } = window;
      const windowScroll = new Vector2(scrollX, scrollY);
      const windowSize = new Vector2(innerWidth, innerHeight);

      const defaultVisibleHeight = getVisibleHeightAtDepth(
        THREE_DEFAULT_FOV,
        THREE_CAMERA_DEPTH
      );
      const offsetVisibleHeight = getVisibleHeightAtDepth(
        THREE_DEFAULT_FOV,
        THREE_CAMERA_DEPTH - options.z
      );
      const screenSizeDifference = windowSize
        .clone()
        .multiplyScalar(offsetVisibleHeight - defaultVisibleHeight);
      const scale = defaultVisibleHeight / offsetVisibleHeight;

      const rect = element.getBoundingClientRect();
      console.log(rect);
      const rectCenter = windowScroll
        .clone()
        .add(new Vector2(rect.x, rect.y))
        .add(new Vector2(rect.width, rect.height).divideScalar(2));

      const relativeScroll = windowSize
        .clone()
        .divideScalar(2)
        .add(new Vector2(scrollX, scrollY))
        .sub(rectCenter);
      const relativeScrollRatio = relativeScroll.divide(windowSize);

      element.style.transform = `translate(${
        (relativeScrollRatio.x * screenSizeDifference.x) / 4
      }px, ${
        (relativeScrollRatio.y * screenSizeDifference.y) / 4
      }px) scale(${scale})`;
    };
  }, [options.z]);

  useEffect(() => {
    window.addEventListener('scroll', handleScroll, { passive: true });

    handleScroll();

    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);
  const unlink = useMemo(
    () => () => {
      window.removeEventListener('scroll', handleScroll);
    },
    [handleScroll]
  );

  return {
    link: (element: HTMLElement | null) => {
      if (element === elementRef.current) return;

      elementRef.current = element;

      setTimeout(handleScroll, 0);
    },
    unlink,
  };
};
