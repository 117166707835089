import { FC } from 'react';
import { useTheme } from '@mui/material';
import {
  Three,
  ThreeBox,
  ThreeLighting,
  ThreeScrollOffsetContainer,
  ThreeTransdimensionalRect,
} from '../../..';

export const ReferenceTermPageThree: FC = () => {
  const theme = useTheme();

  return (
    <Three
      id="referenceTermPage-three"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeLighting />
      <ThreeScrollOffsetContainer>{/* todo */}</ThreeScrollOffsetContainer>
    </Three>
  );
};
