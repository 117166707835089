import { FC } from 'react';
import { PresentationChip as PresentationChipType } from '../../types';
import { Chip } from '..';

export const PresentationChip: FC<{ presentationChip: PresentationChipType }> =
  ({ presentationChip }) => (
    <Chip
      background={presentationChip.background}
      textColor={presentationChip.color}
    >
      {presentationChip.content}
    </Chip>
  );
