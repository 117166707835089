import { FC, useContext } from 'react';
import { useTheme } from '@mui/material';
import { THREE_FULL_WIDTH, THREE_FULL_DEPTH } from '../../constants';
import { TransdimensionalUnitConversionServiceContext } from '../../contexts';
import {
  PageHeaderVariant,
  ThreeQuad,
  ThreeTransdimensionalBorder,
  ThreeTransdimensionalWalls,
} from '..';
import { PAGE_HEADER_SEARCH_RECT_ID } from './constants';
import { useMaterial } from '../../hooks';

export const PageHeaderThree: FC<{
  variant?: PageHeaderVariant;
}> = (props) => {
  const theme = useTheme();
  const transdimensionalUnitConversionService = useContext(
    TransdimensionalUnitConversionServiceContext
  );
  const searchFieldMaterial = useMaterial(
    (theme) => theme.palette.background.paper
  );

  if (!transdimensionalUnitConversionService) return null;

  const headerHeight = transdimensionalUnitConversionService.clientToThreeY(
    12 + 60 + 12
  );
  const verticalBorder = (84 - 48) / 2;

  return (
    <>
      {/* Front */}
      {(() => {
        switch (props.variant) {
          case 'search':
            return (
              <>
                <ThreeTransdimensionalBorder
                  id={PAGE_HEADER_SEARCH_RECT_ID}
                  left={THREE_FULL_WIDTH}
                  right={THREE_FULL_WIDTH}
                  top={verticalBorder}
                  bottom={verticalBorder}
                />
                <ThreeTransdimensionalWalls
                  id={PAGE_HEADER_SEARCH_RECT_ID}
                  material={searchFieldMaterial}
                  all={theme.depth.input}
                />
              </>
            );
          default:
            return (
              <ThreeQuad
                position={[0, -headerHeight / 2, 0]}
                scale={[THREE_FULL_WIDTH, headerHeight, 1]}
              />
            );
        }
      })()}

      {/* Ceiling */}
      <ThreeQuad
        position={[0, -headerHeight, -THREE_FULL_DEPTH / 2]}
        scale={[THREE_FULL_WIDTH, THREE_FULL_DEPTH, 1]}
        rotation={[Math.PI / 2, 0, 0]}
      />
    </>
  );
};
