import { Breakpoint, useMediaQuery, useTheme } from '@mui/material';
import { BREAKPOINTS } from '../../constants';

export const useBreakpointsMap = () => {
  const theme = useTheme();

  return BREAKPOINTS.reduce((breakpoints, breakpoint) => {
    const matches = useMediaQuery(theme.breakpoints.up(breakpoint));

    breakpoints.set(breakpoint, matches);

    return breakpoints;
  }, new Map<Breakpoint, boolean>());
};
