import { FC } from 'react';
import { Color } from 'three';
import {
  ChipThree,
  Three,
  ThreeLighting,
  ThreeScrollOffsetContainer,
} from '../../../..';
import { useMaterial } from '../../../../../hooks';

const ReferenceBrandPagePaletteSectionThreeInner: FC = () => {
  return (
    <>
      <ChipThree
        id="palette-paragraph-color-brand-red"
        material={useMaterial((theme) => theme.palette.brandRed.main)}
      />
      <ChipThree
        id="palette-paragraph-color-brand-orange"
        material={useMaterial((theme) => theme.palette.brandOrange.main)}
      />
      <ChipThree
        id="palette-paragraph-color-brand-yellow"
        material={useMaterial((theme) => theme.palette.brandYellow.main)}
      />
      <ChipThree
        id="theme.palette.brandRed.main"
        material={useMaterial((theme) => theme.palette.brandRed.main)}
      />
      <ChipThree
        id="theme.palette.brandOrange.main"
        material={useMaterial((theme) => theme.palette.brandOrange.main)}
      />
      <ChipThree
        id="theme.palette.brandYellow.main"
        material={useMaterial((theme) => theme.palette.brandYellow.main)}
      />
      <ChipThree
        id="theme.palette.brandYellow.dark"
        material={useMaterial((theme) => theme.palette.brandYellow.dark)}
      />
    </>
  );
};

export const ReferenceBrandPagePaletteSectionThree: FC = () => {
  return (
    <Three
      id="brand_palette_three"
      className="PaletteSectionThree"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
      interactive={false}
    >
      <ThreeScrollOffsetContainer>
        <ThreeLighting />
        <ReferenceBrandPagePaletteSectionThreeInner />
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
