import { FC } from 'react';
import { useSelector } from 'react-redux';
import { THREE_FULL_WIDTH } from '../../constants';
import { getOrCreateTransdimensionalRectSelector } from '../../selectors';
import { ThreeQuad, ThreeTransdimensionalRect } from '.';
import { Material } from 'three';

export const ThreeTransdimensionalWalls: FC<{
  id: string;
  material?: Material;
  all?: number;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
  leftDepth?: number;
  extendLeft?: boolean;
  extendRight?: boolean;
  back?: boolean;
}> = (props) => {
  const rect = useSelector(getOrCreateTransdimensionalRectSelector(props.id));

  if (!rect) return null;

  const quadProps = {
    material: props.material,
  };

  const wallDepth = typeof props.all === 'number' ? props.all : 0;
  const wallLeftDepth = typeof props.left === 'number' ? props.left : wallDepth;
  const wallRightDepth =
    typeof props.right === 'number' ? props.right : wallDepth;
  const wallTopDepth = typeof props.top === 'number' ? props.top : wallDepth;
  const wallBottomDepth =
    typeof props.bottom === 'number' ? props.bottom : wallDepth;

  const sideLeftX = -(1 / 2 + (props.extendLeft ? THREE_FULL_WIDTH : 0));
  const sideRightX = 1 / 2 + (props.extendRight ? THREE_FULL_WIDTH : 0);

  const width = sideRightX - sideLeftX;
  const centerX = (sideLeftX + sideRightX) / 2;

  return (
    <ThreeTransdimensionalRect id={props.id}>
      {wallLeftDepth > 0 && !props.extendLeft && (
        <ThreeQuad
          {...quadProps}
          position={[-1 / 2, 0, -(wallLeftDepth / 2 + (props.leftDepth || 0))]}
          scale={[wallLeftDepth, 1, 1]}
          rotation={[0, Math.PI / 2, 0]}
        />
      )}
      {wallRightDepth > 0 && !props.extendRight && (
        <ThreeQuad
          {...quadProps}
          position={[1 / 2, 0, -wallRightDepth / 2]}
          scale={[wallRightDepth, 1, 1]}
          rotation={[0, Math.PI / -2, 0]}
        />
      )}
      {wallTopDepth > 0 && (
        <ThreeQuad
          {...quadProps}
          position={[centerX, 1 / 2, -wallTopDepth / 2]}
          scale={[width, wallTopDepth, 1]}
          rotation={[Math.PI / 2, 0, 0]}
        />
      )}
      {wallBottomDepth > 0 && (
        <ThreeQuad
          {...quadProps}
          position={[centerX, -1 / 2, -wallBottomDepth / 2]}
          scale={[width, wallBottomDepth, 1]}
          rotation={[Math.PI / -2, 0, 0]}
        />
      )}
      {props.back !== false && (
        <ThreeQuad
          {...quadProps}
          position={[centerX, 0, -wallDepth]}
          scale={[width, 1, 1]}
        />
      )}
    </ThreeTransdimensionalRect>
  );
};
