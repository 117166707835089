import { FC, useMemo } from 'react';
import { Provider } from 'react-redux';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { CssBaseline, useMediaQuery } from '@mui/material';
import { Experimental_CssVarsProvider as CssVarsProvider } from '@mui/material/styles';
import { LIGHT_THEME, DARK_THEME, EXTENDED_THEME } from '../../constants';
import store from '../../store';
import { TransdimensionalInteractionService } from '../../services';
import {
  EnvironmentContext,
  TransdimensionalInteractionServiceContext,
} from '../../contexts';
import { getEnvironment } from '../../utilities';
import {
  ApplicationLoadingContainer,
  LinearProgressIndicator,
  ProgressManager,
  ThemeProvider,
} from '..';
import { router } from './applicationRoutes';
import './Application.scss';

export const Application: FC = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme: dark)');
  const theme = useMemo(
    () => (prefersDarkMode ? DARK_THEME : LIGHT_THEME),
    [prefersDarkMode]
  );

  const transdimensionalInteractionService = useMemo(
    () => new TransdimensionalInteractionService(),
    []
  );

  return (
    <EnvironmentContext.Provider value={getEnvironment()}>
      <Provider store={store}>
        {/* <CssVarsProvider theme={EXTENDED_THEME}> */}
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ApplicationLoadingContainer>
            <TransdimensionalInteractionServiceContext.Provider
              value={transdimensionalInteractionService}
            >
              <div
                className="Application"
                style={{
                  backgroundColor: theme.palette.background.clear,
                }}
              >
                <ProgressManager>
                  <LinearProgressIndicator position="fixed" />
                  <RouterProvider router={router} />
                </ProgressManager>
              </div>
            </TransdimensionalInteractionServiceContext.Provider>
          </ApplicationLoadingContainer>
          <div id="embed-root"></div>
        </ThemeProvider>
        {/* </CssVarsProvider> */}
      </Provider>
    </EnvironmentContext.Provider>
  );
};
