import {
  ElementType,
  PropsWithChildren,
  createElement,
  forwardRef,
  useEffect,
  useRef,
} from 'react';
import { useDispatch } from 'react-redux';
import { useInView } from 'react-intersection-observer';
import classNames from 'classnames';
import { Box, SxProps, Theme } from '@mui/material';
import { TransdimensionalRectOptions } from '../../types';
import { setTransdimensionalRect } from '../../actions';
import { DebugTransdimensionalRect } from '..';
import './TransdimensionalRect.scss';
import { useTransdimensionalRectLink } from '../../hooks';

export const TransdimensionalRect = forwardRef<
  HTMLElement,
  PropsWithChildren<
    TransdimensionalRectOptions & {
      component?: 'div' | 'span' | 'button' | 'thead' | 'tr';
      disabled?: boolean;
      display?: string;
      sx?: SxProps<Theme>;
      className?: string;
      onClick?: (event: React.MouseEvent<any>) => void;
      onMouseEnter?: (event: React.MouseEvent<any>) => void;
      onMouseLeave?: (event: React.MouseEvent<any>) => void;
      onMouseMove?: (event: React.MouseEvent<any>) => void;
    }
  >
>(
  (
    {
      id,
      offsetTop,
      offsetLeft,
      component,
      display,
      sx,
      children,
      log,
      ...props
    },
    forwardedRef
  ) => {
    const { link, unlink } = useTransdimensionalRectLink({
      id,
      offsetLeft,
      offsetTop,
      log,
    });

    useEffect(() => {
      return unlink;
    }, [unlink]);

    if (log) console.log('render for id ', id);

    return (
      <Box
        component={component ?? 'div'}
        ref={(element: HTMLElement) => {
          link(element);

          if (forwardedRef instanceof Function) {
            forwardedRef(element);
          } else if (forwardedRef) {
            forwardedRef.current = element;
          }
        }}
        className={classNames('TransdimensionalRect', props.className)}
        sx={[
          {
            position: 'relative',
            display,
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        {...props}
      >
        <DebugTransdimensionalRect id={id} />
        {children}
      </Box>
    );
  }
);
