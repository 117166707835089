import { forwardRef, PropsWithChildren } from 'react';
import { useTheme } from '@mui/material';
import { DialogBaseProps, DialogBase } from './DialogBase';

export type ColumnDialogAlignment = 'left' | 'right';

export interface ColumnDialogProps extends DialogBaseProps {
  align: ColumnDialogAlignment;
}

export const ColumnDialog = forwardRef<
  HTMLDivElement,
  PropsWithChildren<ColumnDialogProps>
>(({ align, children, ...baseProps }, ref) => {
  const theme = useTheme();
  return (
    <DialogBase
      ref={ref}
      {...baseProps}
      sx={{
        ...(align === 'left' && { left: 0 }),
        ...(align === 'right' && { right: 0 }),
        width: '30vw',
        minWidth: 384,
        [theme.breakpoints.down('md')]: {
          width: '100vw',
          minWidth: '',
        },
      }}
    >
      {children}
    </DialogBase>
  );
});
