import { FC } from 'react';
import { useTheme } from '@mui/material';
import { REFERENCE_INDEX_ITEMS } from '../../../../constants';
import {
  Three,
  ThreeBox,
  ThreeLighting,
  ThreeScrollOffsetContainer,
  TransdimensionalButtonThree,
  TransdimensionalButtonThreeBox,
  ThreeTransdimensionalRect,
} from '../../..';

export const ReferenceOverviewPageThree: FC = () => {
  const theme = useTheme();

  return (
    <Three
      id="referenceOverviewPage-three"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeScrollOffsetContainer>
        <ThreeLighting />
        <ThreeTransdimensionalRect id="referencePage_indexSection_sidebar">
          <ThreeBox position={[0, 0, -0.1]} scale={[1, 1, 0.2]} />
        </ThreeTransdimensionalRect>
        {REFERENCE_INDEX_ITEMS.map((indexItem) => {
          return (
            <TransdimensionalButtonThree key={indexItem.id} id={indexItem.id}>
              <TransdimensionalButtonThreeBox colorProfile="default" />
            </TransdimensionalButtonThree>
          );
        })}
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
