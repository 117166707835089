import { PropsWithChildren, forwardRef } from 'react';
import { DialogBaseProps, DialogBase } from './DialogBase';

export interface FullscreenDialogProps extends DialogBaseProps {}

export const FullscreenDialog = forwardRef<
  HTMLDivElement,
  PropsWithChildren<FullscreenDialogProps>
>(({ children, ...baseProps }, ref) => {
  return (
    <DialogBase
      ref={ref}
      {...baseProps}
      sx={{
        left: 0,
        width: '100vw',
      }}
    >
      {children}
    </DialogBase>
  );
});
