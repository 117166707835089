import { FC } from 'react';
import { Color, Material, Object3D, Vector3 } from 'three';
import { useTheme } from '@mui/material';
import { AWS_CERTIFICATION_LEVEL_COLORS } from '../../constants';
import { AWSCertificationLevel } from '../../types';
import { useMaterial } from '../../hooks';
import { ThreeBox, ThreeModel, ThreeTransdimensionalRect } from '..';
import {
  getCertificationAwsBodyRectId,
  getCertificationAwsHeaderRectId,
} from './utilities';

const PADDING = 0.05;
const PADDING_HORIZONTAL_INTRINSIC = (1 - Math.cos(Math.PI / 6)) / 2;

const LOGO_DEFAULT_COLOR = new Color(0.5, 0.5, 0.5);

const AWS_LOGO_DEPTH_RATIO = 0.25;
const AWS_LOGO_LAYERS_COUNT = 2;
const AWS_LOGO_LAYER_INSET = 0.1;
const AWS_LOGO_DIVIDER_THICKNESS = 0.05;

const CertificationAWSThreeLogoLayer: FC<{
  layerIndex: number;
  certificationLevelMaterial?: Material;
}> = ({ layerIndex, certificationLevelMaterial }) => {
  const theme = useTheme();
  const logoLayerDepth =
    (theme.depth.paper * AWS_LOGO_DEPTH_RATIO) / AWS_LOGO_LAYERS_COUNT;
  const layerDepth = -(layerIndex + 1 / 2) * logoLayerDepth;
  const layerScale = 1 - layerIndex * AWS_LOGO_LAYER_INSET - 2 * PADDING;
  const layerColorProps = {
    material: certificationLevelMaterial,
  };

  return (
    <>
      {/* top padding, standard material */}
      <ThreeBox
        position={[0, (1 - PADDING) / 2, layerDepth]}
        scale={[1, PADDING, logoLayerDepth]}
      />
      {/* left padding, standard material */}
      <ThreeBox
        position={[(1 - PADDING) / 2, 0, layerDepth]}
        scale={[PADDING, 1, logoLayerDepth]}
      />
      {/* right padding, standard material */}
      <ThreeBox
        position={[-(1 - PADDING) / 2, 0, layerDepth]}
        scale={[PADDING, 1, logoLayerDepth]}
      />
      {/* top inset, in material */}
      <ThreeBox
        {...layerColorProps}
        position={[0, (1 - (1 - layerScale) / 2 - PADDING) / 2, layerDepth]}
        scale={[
          1 - 2 * PADDING,
          (1 - layerScale) / 2 - PADDING,
          logoLayerDepth,
        ]}
      />
      {/* mesh in material */}
      <ThreeModel
        {...layerColorProps}
        id="certification_aws_negative_layer1"
        position={[0, 0, layerDepth]}
        scale={[layerScale, layerScale, logoLayerDepth]}
        rotation={[0, 0, 0]}
      />
      {/* bottom inset, in material */}
      <ThreeBox
        {...layerColorProps}
        position={[0, -(1 - (1 - layerScale) / 2) / 2, layerDepth]}
        scale={[1 - 2 * PADDING, (1 - layerScale) / 2, logoLayerDepth]}
      />
    </>
  );
};

export const CertificationAWSThree: FC<{
  id: string;
  level: AWSCertificationLevel;
}> = ({ id, level }) => {
  const theme = useTheme();
  const certificationLevelColor =
    AWS_CERTIFICATION_LEVEL_COLORS.get(level) ?? LOGO_DEFAULT_COLOR;
  const certificationLevelMaterial = useMaterial(certificationLevelColor);

  const headerRectId = getCertificationAwsHeaderRectId(id);
  const bodyRectId = getCertificationAwsBodyRectId(id);
  const depth = theme.depth.paper;
  const allLayersThickness = depth * AWS_LOGO_DEPTH_RATIO;

  return (
    <>
      <ThreeTransdimensionalRect id={headerRectId}>
        <CertificationAWSThreeLogoLayer layerIndex={0} />
        <CertificationAWSThreeLogoLayer
          layerIndex={1}
          certificationLevelMaterial={certificationLevelMaterial}
        />
        {/* divider */}
        <ThreeBox
          material={certificationLevelMaterial}
          position={[0, 0, -AWS_LOGO_DIVIDER_THICKNESS / 2]}
          scale={[0.5, AWS_LOGO_DIVIDER_THICKNESS, AWS_LOGO_DIVIDER_THICKNESS]}
        />
        {/* back */}
        <ThreeBox
          position={[
            0,
            0,
            -(allLayersThickness + (depth - allLayersThickness) / 2),
          ]}
          scale={[1, 1, depth - allLayersThickness]}
        />
      </ThreeTransdimensionalRect>
      <ThreeTransdimensionalRect id={bodyRectId}>
        <ThreeBox position={[0, 0, -depth / 2]} scale={[1, 1, depth]} />
      </ThreeTransdimensionalRect>
    </>
  );
};
