import { FC } from 'react';
import { Vector3 } from 'three';
import {
  Three,
  ThreeBox,
  ThreeLighting,
  ThreeScrollOffsetContainer,
  ThreeTransdimensionalRect,
} from '../../../..';

export const ReferenceBrandPageAssetsSectionThree: FC = () => {
  return (
    <Three
      id="brand_assets_three"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
      interactive={false}
    >
      <ThreeScrollOffsetContainer>
        <ThreeLighting />
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
