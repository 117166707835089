import { FC, PropsWithChildren } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import {
  Link as MuiLink,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import { NoWrap } from '..';

export type HeadingVariant = 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';

export const Heading: FC<
  PropsWithChildren<{
    variant: HeadingVariant;
    id?: string;
    sx?: SxProps<Theme>;
    typographySx?: SxProps<Theme>;
  }>
> = (props) => {
  const hash = `#${props.id}`;
  const theme = useTheme();
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = location.hash === hash;

  return (
    <NoWrap sx={props.sx}>
      <Typography
        variant={props.variant}
        id={props.id}
        sx={[
          {
            ...(!isActive && {
              '& > a': {
                opacity: 0,
              },
              '&:hover': {
                '& > a': {
                  opacity: 1,
                },
              },
            }),
          },
          ...(Array.isArray(props.typographySx)
            ? props.typographySx
            : [props.typographySx]),
        ]}
      >
        {props.children}
        <MuiLink
          component={Link}
          to={hash}
          sx={{
            marginLeft: '0.25em',
            fontWeight: 500,
            userSelect: 'none',
            color: theme.palette.text.secondary,
            transition: theme.transitions.create('opacity', {
              duration: theme.transitions.duration.short,
            }),
            '&, &:hover, &:focus, &:active': {
              textDecoration: 'none',
            },
            ...(isActive && {
              color: theme.palette.secondary.main,
            }),
          }}
          onClick={(event) => {
            if (isActive) {
              event.preventDefault();

              navigate(
                {
                  hash: '',
                },
                {
                  replace: true,
                }
              );
            }
          }}
        >
          #
        </MuiLink>
      </Typography>
    </NoWrap>
  );
};
