import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Box, useTheme } from '@mui/material';

import './ProjectCard.scss';

export enum ProjectCardVariant {
  Small = 'small',
  Large = 'large',
}

export const ProjectCard: FC<
  PropsWithChildren<{
    variant: ProjectCardVariant;
    color?: string;
    className?: string;
  }>
> = (props) => {
  const theme = useTheme();

  return (
    <Box
      component="div"
      className={classNames(
        'ProjectCard',
        `ProjectCard-${props.variant}`,
        props.className
      )}
      sx={{
        padding: 2,
        backgroundColor: props.color,
        color: props.color && theme.palette.getContrastText(props.color),
      }}
    >
      {props.children}
    </Box>
  );
};
