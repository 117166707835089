export const tokenizeCssvalue = (value: string) => {
  const coalescedValue = value.replace(/\s+/g, ' ');
  const tokens: string[] = [];
  let currentToken = '';
  let parenthesesDepth = 0;

  for (let index = 0; index < coalescedValue.length; index++) {
    const character = coalescedValue[index];

    if (character === '(') {
      parenthesesDepth++;
    } else if (character === ')') {
      if (parenthesesDepth <= 0) {
        return null;
      }

      parenthesesDepth--;
    } else if (character === ' ' && parenthesesDepth === 0) {
      tokens.push(currentToken);

      currentToken = '';

      continue;
    }

    currentToken += character;
  }

  if (currentToken) {
    tokens.push(currentToken);
  }

  if (parenthesesDepth !== 0) return null;

  return tokens;
};
