import { FC, useRef } from 'react';
import Measure from 'react-measure';
import { Box, SxProps, Theme } from '@mui/material';

export const HeightMaintainer: FC<{
  sx?: SxProps<Theme>;
  children: (measure: {
    measureRef: (element: HTMLElement | null) => void;
  }) => JSX.Element;
}> = (props) => {
  const containerRef = useRef<HTMLDivElement>(null);

  return (
    <Box ref={containerRef} component="div" sx={props.sx}>
      <Measure
        bounds
        onResize={(contentRect) => {
          if (containerRef.current) {
            containerRef.current.style.height = `${
              contentRect.bounds?.height ?? 0
            }px`;
          }
        }}
      >
        {({ measureRef }) => props.children({ measureRef })}
      </Measure>
    </Box>
  );
};
