import { FC } from 'react';
import { DebugModeMask } from '..';
import './DebugTransdimensionalRect.scss';

export const DebugTransdimensionalRect: FC<{ id: string }> = (props) => {
  return (
    <DebugModeMask>
      <div className="DebugTransdimensionalRect">
        <span>{props.id}</span>
      </div>
    </DebugModeMask>
  );
};
