import { FC, useContext, useMemo } from 'react';
import { MeshStandardMaterial } from 'three';
import { useTheme } from '@mui/material';
import { BoxColorCoercible, ElementState } from '../../types';
import { enhance } from '../../utilities';
import { ThreeBox } from '..';
import { TransdimensionalButtonThreeContext } from './TransdimensionalButtonThree';

export interface ButtonBoxColorProfileObject {
  default: BoxColorCoercible;
  hover?: BoxColorCoercible;
  active?: BoxColorCoercible;
}

export type ButtonBoxColorProfile =
  | 'default'
  | 'primary'
  | 'secondary'
  | ButtonBoxColorProfileObject;

export const useButtonBoxColorProfileObject = (
  boxColorProfile: ButtonBoxColorProfile | null
) => {
  const theme = useTheme();

  if (typeof boxColorProfile === 'string') {
    switch (boxColorProfile) {
      case 'primary':
        return {
          default: theme.palette.primary.main,
          hover: enhance(theme.palette.primary.main, 0.05),
          active: enhance(theme.palette.primary.main, 0.1),
        };
      case 'secondary':
        return {
          default: theme.palette.secondary.main,
          hover: enhance(theme.palette.secondary.main, 0.05),
          active: enhance(theme.palette.secondary.main, 0.1),
        };
      default:
        return {
          default: theme.palette.background.paper,
          hover: enhance(theme.palette.background.paper, 0.05),
          active: enhance(theme.palette.background.paper, 0.1),
        };
    }
  } else {
    return boxColorProfile;
  }
};

export const TransdimensionalButtonThreeBox: FC<{
  colorProfile?: ButtonBoxColorProfile;
}> = (props) => {
  const state: ElementState = useContext(
    TransdimensionalButtonThreeContext
  ) ?? {
    disabled: false,
    hover: false,
    active: false,
  };
  const material = useMemo(
    () => new MeshStandardMaterial({ vertexColors: true }),
    []
  );

  const colorProfile = props.colorProfile ?? 'default';
  const buttonBoxColorProfileObject =
    useButtonBoxColorProfileObject(colorProfile)!;

  let color = buttonBoxColorProfileObject.default;

  if (!state.disabled) {
    if (state.active && buttonBoxColorProfileObject.active) {
      color = buttonBoxColorProfileObject.active;
    } else if (state.hover && buttonBoxColorProfileObject.hover) {
      color = buttonBoxColorProfileObject.hover;
    }
  }

  return (
    <ThreeBox
      position={[0, 0, 0]}
      scale={[1, 1, 1]}
      material={material}
      color={color}
    />
  );
};
