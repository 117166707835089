import { FC } from 'react';
import { useSelector } from 'react-redux';
import { getOrCreateTransdimensionalRectSelector } from '../../selectors';
import { ThreeQuad, ThreeTransdimensionalRect } from '.';

export const ThreeTransdimensionalBorder: FC<{
  id: string;
  all?: number;
  left?: number;
  right?: number;
  top?: number;
  bottom?: number;
}> = (props) => {
  const rect = useSelector(getOrCreateTransdimensionalRectSelector(props.id));

  if (!rect) return null;

  const borderThickness = typeof props.all === 'number' ? props.all : 0;
  const borderLeftThickness =
    typeof props.left === 'number' ? props.left : borderThickness;
  const borderRightThickness =
    typeof props.right === 'number' ? props.right : borderThickness;
  const borderTopThickness =
    typeof props.top === 'number' ? props.top : borderThickness;
  const borderBottomThickness =
    typeof props.bottom === 'number' ? props.bottom : borderThickness;

  const borderLeftRatio = borderLeftThickness / rect.width;
  const borderRightRatio = borderRightThickness / rect.width;

  const borderTopRatio = borderTopThickness / rect.height;
  const borderBottomRatio = borderBottomThickness / rect.height;

  const borderRatioX = borderLeftRatio + borderRightRatio;
  const borderRatioY = borderTopRatio + borderBottomRatio;

  const centerX = (borderRightRatio - borderLeftRatio) / 2;
  const centerY = (borderTopRatio - borderBottomRatio) / 2;

  return (
    <ThreeTransdimensionalRect id={props.id}>
      {borderLeftThickness > 0 && (
        <ThreeQuad
          position={[-(1 + borderLeftRatio) / 2, centerY, 0]}
          scale={[borderLeftRatio, 1 + borderRatioY, 1]}
        />
      )}
      {borderRightThickness > 0 && (
        <ThreeQuad
          position={[(1 + borderRightRatio) / 2, centerY, 0]}
          scale={[borderRightRatio, 1 + borderRatioY, 1]}
        />
      )}
      {borderTopThickness > 0 && (
        <ThreeQuad
          position={[centerX, (1 + borderTopRatio) / 2, 0]}
          scale={[1 + borderRatioX, borderTopRatio, 1]}
        />
      )}
      {borderBottomThickness > 0 && (
        <ThreeQuad
          position={[centerX, -(1 + borderBottomRatio) / 2, 0]}
          scale={[1 + borderRatioX, borderBottomRatio, 1]}
        />
      )}
    </ThreeTransdimensionalRect>
  );
};
