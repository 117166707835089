import { Color } from 'three';

import { RectCornerColors } from '../../types';

export const colorToRectCornerColors = (color: Color): RectCornerColors => ({
  topRight: color.clone(),
  topLeft: color.clone(),
  bottomRight: color.clone(),
  bottomLeft: color.clone(),
});
