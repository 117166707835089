import { FC, PropsWithChildren } from 'react';
import {
  THREE_FULL_WIDTH,
  SERVICES_THREE_LIGHT_POSITION,
  SERVICES_THREE_LIGHT_INTENSITY,
  LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_BODY_BOTTOM,
  LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_BODY_TOP,
  SERVICES_DEVELOP_SERVICE_ITEM_SECTIONS,
} from '../../../../constants';
import { TransdimensionalUnitConversionServiceContext } from '../../../../contexts';
import { useLayoutAnchor, useWindowSize } from '../../../../hooks';
import {
  ChipThree,
  Three,
  ThreeLighting,
  ThreeQuad,
  ThreeScrollOffsetContainer,
} from '../../..';

export const MainPageServicesGridDevelopThree: FC<
  PropsWithChildren<{
    className?: string;
  }>
> = ({ className, children }) => {
  const windowSize = useWindowSize();
  const width = useLayoutAnchor('services_contentRight')?.x || 0;
  const height =
    (useLayoutAnchor(LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_BODY_BOTTOM)?.y || 0) -
    (useLayoutAnchor(LAYOUT_ANCHOR_ID_SERVICES_DEVELOP_BODY_TOP)?.y || 0);

  return (
    <Three
      id="services_develop_three"
      className={className}
      inset
      fogFar={50}
      sx={{
        position: 'absolute',
        width,
        height: '100%',
      }}
      domChildren={children}
    >
      <ThreeLighting />
      <ThreeScrollOffsetContainer>
        <TransdimensionalUnitConversionServiceContext.Consumer>
          {(transdimensionalUnitConversionService) => {
            if (!transdimensionalUnitConversionService) return null;

            const sceneWidth =
              transdimensionalUnitConversionService.getVisibleWidthAtDepth(0);
            const sceneHeight =
              transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);
            const sceneDepth = 0.5;

            return (
              <>
                {/* Ceiling */}
                <ThreeQuad
                  position={[0, 0, -sceneDepth / 2]}
                  scale={[THREE_FULL_WIDTH, sceneDepth, 1]}
                  rotation={[Math.PI / 2, 0, 0]}
                />

                {/* Back wall */}
                <ThreeQuad
                  position={[0, -(sceneHeight / 2), -sceneDepth]}
                  scale={[THREE_FULL_WIDTH, sceneHeight, 1]}
                  rotation={[0, 0, 0]}
                />

                {/* Floor */}
                <ThreeQuad
                  position={[0, -sceneHeight, -sceneDepth / 2]}
                  scale={[THREE_FULL_WIDTH, sceneDepth, 1]}
                  rotation={[Math.PI / -2, 0, 0]}
                />

                {/* Right wall */}
                <ThreeQuad
                  position={[sceneWidth / 2, -sceneHeight / 2, -sceneDepth / 2]}
                  scale={[sceneDepth, sceneHeight, 1]}
                  rotation={[0, Math.PI / -2, 0]}
                />

                {/* Chips */}
                {SERVICES_DEVELOP_SERVICE_ITEM_SECTIONS.map(
                  (itemSectionProps) => {
                    return itemSectionProps.items.map((itemProps) => {
                      return <ChipThree key={itemProps.id} id={itemProps.id} />;
                    });
                  }
                )}
              </>
            );
          }}
        </TransdimensionalUnitConversionServiceContext.Consumer>
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
