import { lighten, darken, useTheme } from '@mui/material';

export const enhance = (color: string, amount: number) => {
  const theme = useTheme();

  switch (theme.palette.mode) {
    case 'dark':
      return lighten(color, amount);
    case 'light':
      return darken(color, amount);
    default:
      return color;
  }
};
