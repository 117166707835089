import { FC, ReactElement } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Color } from 'three';
import { useTheme } from '@mui/material';

import { standardMaterial } from '../../materials';
import {
  ChipThree,
  ThreeBox,
  TransdimensionalButtonThree,
  TransdimensionalButtonThreeBox,
  ThreeTransdimensionalRect,
  ThreeTransdimensionalWalls,
} from '..';
import { useSearchParamsDate } from './utilities';
import { useMaterial } from '../../hooks';

export const MeetingCreationThree: FC<{
  id: string;
}> = (props) => {
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const { year, month, date: selectedDate } = useSearchParamsDate(searchParams);
  const selectedDateMaterial = useMaterial(
    (theme) => theme.palette.secondary.main
  );
  const calendarDateElements: ReactElement[] = [];

  for (let date = 1; date <= 31; date++) {
    const isSelected = date === selectedDate;

    calendarDateElements.push(
      <TransdimensionalButtonThree key={date} id={date.toString()}>
        <TransdimensionalButtonThreeBox
          colorProfile={isSelected ? 'secondary' : 'default'}
        />
      </TransdimensionalButtonThree>
    );
  }

  return (
    <>
      <ThreeTransdimensionalRect id="meetingCreation_meetingTypeDetails">
        <ThreeBox
          position={[0, 0, -theme.depth.paper / 2]}
          scale={[1, 1, theme.depth.paper]}
        />
      </ThreeTransdimensionalRect>
      <ChipThree
        id="meetingCreation_meetingDurationChip"
        depthOffset="foreground"
      />
      {calendarDateElements}
    </>
  );
};
