import { FC } from 'react';
import { Box, Container } from '@mui/material';
import {
  LinearProgressIndicator,
  MeetingCreation,
  PageHeader,
  PageTitle,
  ProgressManager,
  Section,
} from '../..';
import { MeetPageThree } from '.';

export const MeetPage: FC = () => {
  return (
    <ProgressManager>
      <PageTitle>Let's Meet</PageTitle>
      <Section id="meetPage_introduction" three={<MeetPageThree />}>
        <Container maxWidth="md">
          <PageHeader></PageHeader>
        </Container>
        <LinearProgressIndicator position="sticky" />
        <Container maxWidth="md">
          <br />
          <MeetingCreation />
          <br />
        </Container>
        <Box component="div" sx={{ height: 'env(safe-area-inset-bottom)' }} />
      </Section>
    </ProgressManager>
  );
};
