import { BufferAttribute, Vector3, BoxGeometry } from 'three';

import { BoxColorCoercible } from '../../types';

import { getCornerColorFromVertexPosition, toBoxCornerColors } from '.';

const GEOMETRY = new BoxGeometry(1, 1, 1);

export const COUNT = 12;
export const ITEM_SIZE = 3;

export const getBoxColorAttribute = (
  meshColor: BoxColorCoercible
): BufferAttribute => {
  const boxColors = toBoxCornerColors(meshColor);
  const vertexPositionsAttribute = GEOMETRY.attributes.position;
  const vertexColors = new BufferAttribute(
    new Float32Array(vertexPositionsAttribute.count * ITEM_SIZE),
    ITEM_SIZE
  );

  // boxColors.frontTopLeft = boxColors.frontTopLeft.convertSRGBToLinear();
  // boxColors.frontTopRight = boxColors.frontTopRight.convertSRGBToLinear();
  // boxColors.frontBottomLeft = boxColors.frontBottomLeft.convertSRGBToLinear();
  // boxColors.frontBottomRight = boxColors.frontBottomRight.convertSRGBToLinear();

  // boxColors.backTopLeft = boxColors.backTopLeft.convertSRGBToLinear();
  // boxColors.backTopRight = boxColors.backTopRight.convertSRGBToLinear();
  // boxColors.backBottomLeft = boxColors.backBottomLeft.convertSRGBToLinear();
  // boxColors.backBottomRight = boxColors.backBottomRight.convertSRGBToLinear();

  for (let index = 0; index < vertexPositionsAttribute.count; index++) {
    const vertexPosition = new Vector3(
      vertexPositionsAttribute.getX(index),
      vertexPositionsAttribute.getY(index),
      vertexPositionsAttribute.getZ(index)
    );

    const color = getCornerColorFromVertexPosition(boxColors, vertexPosition);

    vertexColors.setXYZ(index, color.r, color.g, color.b);
  }

  return vertexColors;
};
