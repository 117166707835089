import axios, { AxiosInstance } from 'axios';
import moment, { Duration, Moment } from 'moment';
import {
  CalendlyCollectionResponse,
  CalendlyAvailableTime,
  CalendlyGetAvailableTimesParameters,
} from '../types';
import { uniqBy } from 'lodash';

const CALENDLY_TIME_CHUNK_SIZE = moment.duration(1, 'week');

interface TimeRange {
  start: Moment;
  end: Moment;
}

const chunkTimeRange = (
  timeRange: TimeRange,
  chunkSize: Duration = CALENDLY_TIME_CHUNK_SIZE
) => {
  const chunks: TimeRange[] = [];
  let currentChunkStart = timeRange.start.clone();

  while (currentChunkStart.isBefore(timeRange.end)) {
    const currentChunkEnd = currentChunkStart.clone().add(chunkSize);

    chunks.push({
      start: currentChunkStart,
      end: currentChunkEnd,
    });

    currentChunkStart = currentChunkEnd;
  }

  return chunks;
};

export class CalendlyProxyClient {
  private axiosInstance: AxiosInstance;

  constructor(private readonly baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
    });
  }

  public async getAvailableTimes(eventTypeUri: string, timeRange: TimeRange) {
    const minimumStartTime = moment.utc().add(30, 'seconds');
    const timeRangeChunks = chunkTimeRange(timeRange);
    const availableTimes = (
      await Promise.all(
        timeRangeChunks.map(async (timeRangeChunk) => {
          const clampedTimeRangeChunk = {
            ...timeRangeChunk,
            start: timeRangeChunk.start.isBefore(minimumStartTime)
              ? minimumStartTime
              : timeRangeChunk.start,
            end: timeRangeChunk.end.isBefore(minimumStartTime)
              ? minimumStartTime
              : timeRangeChunk.end,
          };

          if (clampedTimeRangeChunk.start.isSame(clampedTimeRangeChunk.end)) {
            return [];
          }

          const response = await this.axiosInstance.get<
            CalendlyCollectionResponse<CalendlyAvailableTime>
          >('/event_type_available_times', {
            params: {
              event_type: eventTypeUri,
              start_time: clampedTimeRangeChunk.start.toISOString(),
              end_time: clampedTimeRangeChunk.end.toISOString(),
            } as CalendlyGetAvailableTimesParameters,
          });

          return response.data.collection ?? [];
        })
      )
    ).flat();

    return uniqBy(
      availableTimes,
      (availableTime) => availableTime.scheduling_url
    );
  }
}
