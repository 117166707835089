import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import {
  Breadcrumbs,
  PageTitle,
  Section,
  TransdimensionalButton,
  TransdimensionalRect,
} from '../../..';
import { REFERENCE_INDEX_ITEMS } from '../../../../constants';
import { ReferenceOverviewPageThree } from '.';

export const ReferenceOverviewPage: FC = () => {
  const theme = useTheme();

  return (
    <>
      <PageTitle>Reference Overview</PageTitle>
      <Section
        id="referencePage_indexSection"
        three={<ReferenceOverviewPageThree />}
      >
        <Container maxWidth="lg">
          <Breadcrumbs
            variant="pageHeader"
            prefixBrandCircle
            items={[
              {
                to: '/reference',
                text: 'Reference',
              },
              {
                to: '/reference',
                text: 'Overview',
              },
            ]}
          />
          <Grid container spacing={4}>
            {REFERENCE_INDEX_ITEMS.map((indexItem) => {
              return (
                <Grid key={indexItem.id} item xs={12} sm={6} md={4} lg={3}>
                  <Link to={indexItem.url}>
                    <TransdimensionalButton
                      id={indexItem.id}
                      sx={{
                        display: 'block',
                        aspectRatio: '1 / 1',
                        padding: 1,
                      }}
                    >
                      <Typography variant="body1" fontWeight="bold">
                        {indexItem.name}
                      </Typography>
                    </TransdimensionalButton>
                  </Link>
                </Grid>
              );
            })}
          </Grid>
        </Container>
      </Section>
    </>
  );
};
