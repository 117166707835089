import { FC, useContext } from 'react';
import { useFrame } from '@react-three/fiber';
import { ThreeStatisticsContext } from '../../../contexts';

export const DebugThreeStatisticsUpdater: FC = () => {
  const statistics = useContext(ThreeStatisticsContext);

  useFrame(() => {
    statistics.update();
  });

  return null;
};
