// React
import { createContext } from 'react';

// Services
import { TransdimensionalInteractionService } from '../services';

const TransdimensionalInteractionServiceContext =
  createContext<TransdimensionalInteractionService>(
    new TransdimensionalInteractionService()
  );
TransdimensionalInteractionServiceContext.displayName =
  'TransdimensionalInteractionServiceContext';

export { TransdimensionalInteractionServiceContext };
