// React Redux
import { useSelector } from 'react-redux';

// Selectors
import { selectLayoutAnchors } from '../../selectors';

// Types
import { LayoutAnchor } from '../../types';

export const useLayoutAnchor = (anchorId: string): LayoutAnchor | null => {
  const layoutAnchors = useSelector(selectLayoutAnchors);

  return layoutAnchors[anchorId] || null;
};
