import { ReactElement, ReactNode, useMemo } from 'react';
import moment from 'moment';
import { URLSearchParams } from 'url';
import { useTheme } from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import {
  MeetingLocation,
  MEETING_LOCATION_LABELS,
  MICROSOFT_TEAMS_COLOR,
  ZOOM_COLOR,
  GOOGLE_MEET_COLOR,
} from '../../constants';
import {
  GoogleMeetColorIcon,
  MicrosoftTeamsColorIcon,
  ZoomColorIcon,
} from '..';

export interface MeetingLocationOption {
  value: MeetingLocation;
  color: string;
  icon: ReactElement<any, any>;
  label: ReactNode;
  calendlyLocationValue: string;
}

export const useMeetingLocationOptions = (): MeetingLocationOption[] => {
  const theme = useTheme();

  return [
    {
      value: 'microsoft-teams',
      color: MICROSOFT_TEAMS_COLOR,
      icon: <MicrosoftTeamsColorIcon />,
      label: MEETING_LOCATION_LABELS.get('microsoft-teams'),
      calendlyLocationValue: 'Microsoft Teams (Sam will provide meeting link)',
    },
    {
      value: 'zoom',
      color: ZOOM_COLOR,
      icon: <ZoomColorIcon />,
      label: MEETING_LOCATION_LABELS.get('zoom'),
      calendlyLocationValue: 'Zoom Video (Sam will provide meeting link)',
    },
    {
      value: 'google-meet',
      color: GOOGLE_MEET_COLOR,
      icon: <GoogleMeetColorIcon />,
      label: MEETING_LOCATION_LABELS.get('google-meet'),
      calendlyLocationValue: 'Google Meet (Sam will provide meeting link)',
    },
    {
      value: 'phone',
      color: theme.palette.primary.main,
      icon: <PhoneIcon />,
      label: MEETING_LOCATION_LABELS.get('phone'),
      calendlyLocationValue: 'Phone Call (Sam will send you his phone number)',
    },
  ];
};

export const useSearchParamsDate = (searchParams: URLSearchParams) => {
  const localeNow = moment();
  const searchParamsMonth = searchParams.get('month');
  const { year, month } = useMemo(() => {
    if (
      searchParamsMonth &&
      searchParamsMonth.match(/^\d{4}-\d{2}$/) !== null
    ) {
      const [yearString, monthString] = searchParamsMonth.split('-');
      const year = parseInt(yearString);
      const month = parseInt(monthString);

      if (
        month >= 1 &&
        month <= 12 &&
        (year > localeNow.year() ||
          (year === localeNow.year() && month - 1 >= localeNow.month()))
      ) {
        return { year, month: month - 1 };
      }
    }

    return { year: localeNow.year(), month: localeNow.month() };
  }, [searchParamsMonth]);

  const searchParamsDate = searchParams.get('date');
  const date = useMemo(() => {
    if (searchParamsDate && searchParamsDate.match(/^\d{4}-\d{2}-\d{2}$/)) {
      const localeMonth = moment({ year, month });
      const [yearString, monthString, dateString] = searchParamsDate.split('-');
      const dateYear = parseInt(yearString);
      const dateMonth = parseInt(monthString);
      const date = parseInt(dateString);

      if (
        dateYear === year &&
        dateMonth - 1 === month &&
        date >= 1 &&
        date <= localeMonth.daysInMonth()
      ) {
        return date;
      }
    }

    return null;
  }, [searchParamsDate, year, month]);

  return { year, month, date };
};
