// React
import { useState, useEffect } from 'react';

// Utilities
import { getWindowSizeVector2 } from '../../utilities';

export const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState(getWindowSizeVector2());

  useEffect(() => {
    const handleResize = () => {
      setWindowSize(getWindowSizeVector2());
    };

    window.addEventListener('resize', handleResize, { passive: true });

    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowSize;
};
