import { FC, useMemo } from 'react';
import { Color, Material, MeshStandardMaterial, Vector3 } from 'three';
import { useTheme } from '@mui/material';

import { ThreeBox, ThreeTransdimensionalRect } from '../three';
import { useMaterial } from '../../hooks';
import { BoxColorCoercible } from '../../types';

export type ChipThreeVariant = 'solid' | 'fade';

export const ChipThree: FC<{
  id: string;
  variant?: ChipThreeVariant;
  depth?: number;
  depthOffset?: 'foreground' | 'background' | number;
  material?: Material;
  boxColor?: BoxColorCoercible;
  log?: boolean;
}> = ({ id, log, ...props }) => {
  // @todo actually use variant prop
  const theme = useTheme();
  const depth = props.depth ?? theme.depth.chip;
  const depthOffset = (() => {
    switch (true) {
      case typeof props.depthOffset === 'number':
        return props.depthOffset as number;
      case props.depthOffset === 'foreground':
        return depth / 2;
      case props.depthOffset === 'background':
      default:
        return -depth / 2;
    }
  })();
  const defaultMaterial = useMaterial((theme) => theme.palette.background.chip);
  const material = useMemo(() => {
    if (props.material) return props.material;
    if (!props.boxColor) return defaultMaterial;

    return new MeshStandardMaterial({ vertexColors: true });
  }, [props.material, props.boxColor]);

  // let boxColor: BoxColorCoercible;

  // switch (variant) {
  //   case 'solid':
  //     boxColor = color;

  //     break;
  //   case 'fade':
  //     const frontColor = new Color(1, 1, 1);
  //     // const frontColor = new Color(color);
  //     // frontColor.convertSRGBToLinear();
  //     const backColor = new Color(1, 1, 1);
  //     boxColor = getBoxCornerColorsFromFrontAndBackRects(
  //       colorToRectCornerColors(frontColor),
  //       colorToRectCornerColors(backColor)
  //     );

  //     break;
  // }

  return (
    <ThreeTransdimensionalRect id={id} log={log}>
      <ThreeBox
        position={new Vector3(0, 0, depthOffset)}
        scale={new Vector3(1, 1, depth)}
        material={material}
        color={props.boxColor}
      />
    </ThreeTransdimensionalRect>
  );
};
