import { useSelector } from 'react-redux';
import { Parser } from 'expr-eval';

import { selectLayoutAnchors } from '../../selectors';
import { getLayoutPointExpressionVariables } from '../../utilities';

export const useLayoutPoint = (layoutPoint: string): number | null => {
  const layoutAnchors = useSelector(selectLayoutAnchors);

  try {
    return Parser.evaluate(
      layoutPoint,
      getLayoutPointExpressionVariables(layoutAnchors)
    );
  } catch (error) {
    return null;
  }
};
