// Redux
import { createSelector } from '@reduxjs/toolkit';

// Types
import { RootState } from '../store';

export const selectTransdimensionalInteraction = (state: RootState) =>
  state.transdimensionalInteraction;

export const selectTransdimensionalRects = createSelector(
  selectTransdimensionalInteraction,
  (transdimensionalInteractionState) =>
    transdimensionalInteractionState.rectsById
);

export const selectTransdimensionalGrids = createSelector(
  selectTransdimensionalInteraction,
  (transdimensionalInteractionState) =>
    transdimensionalInteractionState.gridsById
);

const createTransdimensionalRectSelector = (id: string) =>
  createSelector(
    selectTransdimensionalInteraction,
    (transdimensionalInteractionState) =>
      transdimensionalInteractionState.rectsById[id]
  );

const createTransdimensionalGridSelector = (id: string) =>
  createSelector(
    selectTransdimensionalInteraction,
    (transdimensionalInteractionState) =>
      transdimensionalInteractionState.gridsById[id]
  );

const transdimensionalRectSelectorsById = new Map<
  string /* rect ID */,
  ReturnType<typeof createTransdimensionalRectSelector>
>();

const transdimensionalGridSelectorsById = new Map<
  string /* grid ID */,
  ReturnType<typeof createTransdimensionalGridSelector>
>();

export const getOrCreateTransdimensionalRectSelector = (id: string) => {
  let selector = transdimensionalRectSelectorsById.get(id);

  if (!selector) {
    selector = createTransdimensionalRectSelector(id);

    transdimensionalRectSelectorsById.set(id, selector);
  }

  return selector;
};

export const getOrCreateTransdimensionalGridSelector = (id: string) => {
  let selector = transdimensionalGridSelectorsById.get(id);

  if (!selector) {
    selector = createTransdimensionalGridSelector(id);

    transdimensionalGridSelectorsById.set(id, selector);
  }

  return selector;
};
