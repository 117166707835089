import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { useTheme } from '@mui/material';

import './Badge.scss';

export const Badge: FC<
  PropsWithChildren<{
    id?: string;
    className?: string;
    background?: string;
    color?: string;
    spacingLeft?: boolean;
  }>
> = ({ id, className, background, color, spacingLeft, children }) => {
  const theme = useTheme();

  return (
    <span
      id={id}
      className={classNames('Badge-root', className, {
        'Badge-spacingLeft': spacingLeft,
      })}
      style={{
        background,
        color:
          color ??
          (background ? theme.palette.getContrastText(background) : undefined),
      }}
    >
      {children}
    </span>
  );
};
