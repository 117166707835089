// Redux
import { PayloadAction } from '@reduxjs/toolkit';
import { call, takeEvery } from 'redux-saga/effects';

// Actions
import * as debugActions from '../actions/debugActions';

// Utilities
import { persistDebugMode } from '../utilities';

function* setDebugMode(action: PayloadAction<boolean>) {
  yield call(persistDebugMode, action.payload);
}

export function* debugSaga() {
  yield takeEvery(debugActions.setDebugMode.type, setDebugMode);
}
