import { FC, useEffect } from 'react';
import { Link, useMatch, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Breadcrumbs,
  Chip,
  PresentationPage,
  Section,
  TransdimensionalRect,
} from '../../..';
import { ReferenceSchemaPageThree } from '.';
import BudOutlinedIcon from '../../../../@mui/icons-material/BudOutlined';
import NutOutlinedIcon from '../../../../@mui/icons-material/NutOutlined';
import PottedPlantOutlinedIcon from '../../../../@mui/icons-material/PottedPlantOutlined';
import OakTreeOutlinedIcon from '../../../../@mui/icons-material/OakTreeOutlined';
import LabelOutlinedIcon from '@mui/icons-material/Topic';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

import AceEditor from 'react-ace';

import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-monokai';
import 'ace-builds/src-noconflict/ext-language_tools';
import {
  useTransdimensionalOffsetLink,
  useTransdimensionalRectLink,
} from '../../../../hooks';

export const ReferenceSchemaPage: FC = () => {
  const theme = useTheme();
  const { schemaId } = useParams();
  const transdimensionalRectOptions = {
    id: 'aceEditorTest-gutter',
  };
  const transdimensionalRectLink = useTransdimensionalRectLink(
    transdimensionalRectOptions
  );
  const transdimensionalOffsetLink = useTransdimensionalOffsetLink({
    z: -0.1,
  });

  useEffect(() => {
    return () => {
      transdimensionalRectLink.unlink();
      transdimensionalOffsetLink.unlink();
    };
  }, [transdimensionalRectLink.unlink, transdimensionalOffsetLink.unlink]);

  return (
    <Section
      id="referenceSchemaPage_indexSection"
      three={<ReferenceSchemaPageThree />}
    >
      <Container maxWidth="lg">
        <Breadcrumbs
          variant="pageHeader"
          prefixBrandCircle
          maxItems={3}
          items={[
            {
              to: '/reference',
              text: 'Reference',
            },
            {
              to: '/reference/schemas',
              text: 'Schemas',
            },
            {
              to: `/reference/schemas/${schemaId}`,
              text: 'OwnTracks Log Message',
            },
          ]}
        />
        <Typography variant="body1">
          A geographical coordinate on a planet (most commonly Earth).
        </Typography>
        <br />
        <AceEditor
          mode="json"
          theme="monokai"
          onChange={(newValue) => {}}
          onLoad={(editor) => {
            const gutterElements =
              editor.renderer.container.getElementsByClassName('ace_gutter');

            if (gutterElements.length !== 1) {
              console.warn('Ace gutter was not found.');

              return;
            }

            const gutterElement = gutterElements[0] as HTMLDivElement;

            transdimensionalRectLink.link(gutterElement);

            const contentElements =
              editor.renderer.container.getElementsByClassName('ace_content');

            if (contentElements.length !== 1) {
              console.warn('Ace content was not found.');

              return;
            }

            const contentElement = contentElements[0]
              .parentElement as HTMLDivElement;

            transdimensionalOffsetLink.link(contentElement);

            // const gutterLayerElements =
            //   gutterElement.getElementsByClassName('ace_gutter-layer');

            // if (gutterLayerElements.length !== 1) {
            //   console.warn('Ace gutter layer was not found.');

            //   return;
            // }

            // const gutterLayerElement = gutterLayerElements[0] as HTMLDivElement;

            // transdimensionalOffsetLink.link(gutterLayerElement);
          }}
          onCursorChange={(selection, event) => {
            console.log(selection);
          }}
          name="UNIQUE_ID_OF_DIV"
          editorProps={{ $blockScrolling: true }}
          setOptions={{ useWorker: false }}
          width="100%"
          style={
            {
              // opacity: 0.5,
            }
          }
          minLines={-1}
          maxLines={Infinity}
          showPrintMargin={false}
          tabSize={2}
          value={JSON.stringify(
            {
              $id: 'https://example.com/geographical-location.schema.json',
              $schema: 'https://json-schema.org/draft/2020-12/schema',
              title: 'Longitude and Latitude',
              description:
                'A geographical coordinate on a planet (most commonly Earth).',
              required: ['latitude', 'longitude'],
              type: 'object',
              properties: {
                latitude: {
                  type: 'number',
                  minimum: -90,
                  maximum: 90,
                },
                longitude: {
                  type: 'number',
                  minimum: -180,
                  maximum: 180,
                },
              },
            },
            null,
            2
          )}
        />
        <br />
        <Chip startAdornment={<LabelOutlinedIcon />}>Logging</Chip>
      </Container>
    </Section>
  );
};
