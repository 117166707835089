import { Box, Typography, useTheme } from '@mui/material';
import { FC } from 'react';
import { Status, TransdimensionalRect } from '..';

export const PAGE_FOOTER_RECT_ID = 'pageFooter_root';

export const PageFooter: FC = () => {
  const theme = useTheme();

  return (
    <TransdimensionalRect
      id={PAGE_FOOTER_RECT_ID}
      sx={{
        padding: theme.spacing(2, 0),
      }}
    ></TransdimensionalRect>
  );
};
