import {
  FC,
  PropsWithChildren,
  ReactNode,
  Children,
  cloneElement,
  isValidElement,
} from 'react';
import classNames from 'classnames';

import './ChipList.scss';

export const ChipList: FC<
  PropsWithChildren<{
    className?: string;
    label?: ReactNode;
  }>
> = ({ className, label, children }) => {
  return (
    <div className={classNames('ChipList', className)}>
      {label && <label className="ChipList-label">{label}</label>}
      <ul className="ChipList-list">
        {Children.map(children, (child) => {
          if (!isValidElement(child)) {
            return child;
          }

          return cloneElement(child, { component: 'li' } as any);
        })}
      </ul>
    </div>
  );
};
