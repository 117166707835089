import './@mui/material/styles';
import 'leaflet/dist/leaflet.css';
import './history';

import React from 'react';
import ReactDOM from 'react-dom';

import { ThreeModelsService } from './services';
import { applyStandardMaterial } from './utilities';
import { Application } from './components';

ThreeModelsService.defineTransformation(
  'standardizeMaterials',
  applyStandardMaterial
);

ReactDOM.render(
  <React.StrictMode>
    <Application />
  </React.StrictMode>,
  document.getElementById('root')
);
