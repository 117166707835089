import { Vector3, Color } from 'three';

import { BoxCornerColors } from '../../types';

export const getCornerColorFromVertexPosition = (
  cornerColors: BoxCornerColors,
  vertexPosition: Vector3
): Color => {
  const { x, y, z } = vertexPosition;

  switch (true) {
    case x > 0 && y > 0 && z > 0:
      return cornerColors.frontTopRight;
    case x < 0 && y > 0 && z > 0:
      return cornerColors.frontTopLeft;
    case x > 0 && y < 0 && z > 0:
      return cornerColors.frontBottomRight;
    case x < 0 && y < 0 && z > 0:
      return cornerColors.frontBottomLeft;
    case x > 0 && y > 0 && z < 0:
      return cornerColors.backTopRight;
    case x < 0 && y > 0 && z < 0:
      return cornerColors.backTopLeft;
    case x > 0 && y < 0 && z < 0:
      return cornerColors.backBottomRight;
    case x < 0 && y < 0 && z < 0:
      return cornerColors.backBottomLeft;
  }

  return new Color(1, 1, 1);
};
