import { FC } from 'react';

import { THREE_FULL_DEPTH, THREE_FULL_WIDTH } from '../../../../constants';
import {
  CertificationType,
  AWSCertificationType,
  AWSCertificationLevel,
  HashicorpCertificationType,
  HashicorpCertificationLevel,
} from '../../../../types';
import {
  CertificationAWSThree,
  CertificationHashicorpThree,
  Three,
  ThreeLighting,
  ThreeQuad,
  ThreeScrollOffsetContainer,
} from '../../..';

export const MainPageCertificationsSectionThree: FC = () => {
  return (
    <Three
      id="certifications_three"
      order={2}
      className="CertificationsThree"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeLighting />
      <ThreeScrollOffsetContainer>
        {/* Ceiling */}
        <ThreeQuad
          position={[0, 0, -THREE_FULL_DEPTH / 2]}
          scale={[THREE_FULL_WIDTH, THREE_FULL_DEPTH, 1]}
          rotation={[Math.PI / 2, 0, 0]}
        />

        <CertificationAWSThree
          id={`certifications-${CertificationType.AWS}-${AWSCertificationType.CloudPractitioner}-${AWSCertificationLevel.Foundational}`}
          level={AWSCertificationLevel.Foundational}
        />
        <CertificationAWSThree
          id={`certifications-${CertificationType.AWS}-${AWSCertificationType.SolutionsArchitect}-${AWSCertificationLevel.Associate}`}
          level={AWSCertificationLevel.Associate}
        />
        <CertificationAWSThree
          id={`certifications-${CertificationType.AWS}-${AWSCertificationType.DevOpsEngineer}-${AWSCertificationLevel.Professional}`}
          level={AWSCertificationLevel.Professional}
        />
        <CertificationHashicorpThree
          id={`certifications-${CertificationType.Hashicorp}-${HashicorpCertificationType.Terraform}-${HashicorpCertificationLevel.Associate}`}
          type={HashicorpCertificationType.Terraform}
        />
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
