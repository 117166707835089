import { RefObject, createContext, createRef } from 'react';

export interface DialogContextValue {
  open: boolean;
  elementRef: RefObject<HTMLDivElement | null>;
  onClose: () => void;
}

export const DialogContext = createContext<DialogContextValue>({
  open: false,
  elementRef: createRef(),
  onClose: () => {},
});
DialogContext.displayName = 'DialogContext';
