import { FC } from 'react';
import { Box, Container, Stack, Typography, useTheme } from '@mui/material';
import { NavigationItem } from '../../../../types';
import {
  Breadcrumbs,
  Heading,
  NavigationHierarchy,
  PageTitle,
  Section,
} from '../../..';
import {
  ReferenceBrandPagePaletteSection,
  ReferenceBrandPageAssetsSection,
} from '.';

import './ReferenceBrandPage.scss';

const NAVIGATION_ITEMS: NavigationItem[] = [
  {
    id: 'objectives',
    title: 'Objectives',
    path: '#objectives',
    targetSelector: '#objectives-section',
  },
  {
    id: 'visuals',
    title: 'Visuals',
    path: '#visuals',
    targetSelector: '#visuals',
    children: [
      {
        id: 'color-palette',
        title: 'Color Palette',
        path: '#color-palette',
        targetSelector: '#color-palette-section',
      },
      {
        id: 'logo',
        title: 'Logo',
        path: '#logo',
        targetSelector: '#logo-section',
      },
      {
        id: 'typography',
        title: 'Typography',
        path: '#typography',
        targetSelector: '#typography-section',
      },
    ],
  },
  {
    id: 'assets',
    title: 'Assets',
    path: '#assets',
    targetSelector: '#assets-section',
  },
];

export const ReferenceBrandPage: FC = () => {
  const theme = useTheme();

  return (
    <>
      <PageTitle>Brand Reference</PageTitle>
      <Container maxWidth="lg">
        <Breadcrumbs
          variant="pageHeader"
          prefixBrandCircle
          items={[
            {
              to: '/reference',
              text: 'Reference',
            },
            {
              to: '/reference/brand',
              text: 'Brand',
            },
          ]}
        />
        <Stack direction="row" spacing={4} sx={{ position: 'relative' }}>
          <Box
            component="div"
            sx={{
              width: 240,
              height: '100%',
              flexShrink: 0,
              marginTop: '1.5em',
            }}
          >
            <Box
              component="div"
              sx={{
                position: 'sticky',
                top: 0,
              }}
            >
              <NavigationHierarchy items={NAVIGATION_ITEMS} />
            </Box>
          </Box>
          <Box component="div" sx={{ flexGrow: 1 }}>
            <Section id="objectives-section">
              <Heading variant="h2" id="objectives">
                Objectives
              </Heading>
              <Typography variant="body1">
                It is important to me that I consistently express myself in a
                way that facilitates achieving my goals.&nbsp;&nbsp;In order to
                do this, I have developed a personal brand that communicates my
                individual personality, strengths, and values.&nbsp;&nbsp;My
                brand is more than just a logo and some colors - it is the story
                I tell, the things I do, and the way I conduct myself every day.
              </Typography>
            </Section>
            <Heading variant="h2" id="visuals">
              Visuals
            </Heading>
            <ReferenceBrandPagePaletteSection />
            <Section id="logo-section">
              <Heading variant="h3" id="logo">
                Logo
              </Heading>
            </Section>
            <Section id="typography-section">
              <Heading variant="h3" id="typography">
                Typography
              </Heading>
            </Section>
            <ReferenceBrandPageAssetsSection />
            <br />
          </Box>
        </Stack>
      </Container>
    </>
  );
};
