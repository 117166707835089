import { FC, PropsWithChildren } from 'react';
import { MeshProps, ObjectProps } from '../../types';
import { useMaterial } from '../../hooks';

export interface QuadProps extends ObjectProps, MeshProps {}

export const ThreeQuad: FC<PropsWithChildren<QuadProps>> = (props) => {
  const standardMaterial = useMaterial();
  const material = props.material ?? standardMaterial;

  return (
    <mesh {...props} material={material}>
      <planeGeometry args={[1, 1]} />
    </mesh>
  );
};
