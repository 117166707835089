import { Project } from '../../types';

export const PROJECT_FOREVERDAUNT_BRAND_COLOR_PRIMARY = 'rgb(5, 42, 148)';
export const PROJECT_FOREVERDAUNT_BRAND_COLOR_SECONDARY = 'rgb(69, 181, 200) ';
export const PROJECT_FOREVERDAUNT: Project = {
  id: '@foreverdaunt',
  name: 'Foreverdaunt:8bit',
  description: 'Open-world, retro-themed online multiplayer sandbox game',
  status: 'hiatus',
  chip: {
    background: `linear-gradient(135deg, ${PROJECT_FOREVERDAUNT_BRAND_COLOR_PRIMARY} 0%, ${PROJECT_FOREVERDAUNT_BRAND_COLOR_SECONDARY} 100%)`,
    color: 'rgb(255, 255, 255)',
    content: '@foreverdaunt',
  },
  page: {
    tabs: [
      {
        label: 'Overview',
        page: {
          markdown: `\
_TODO_
`,
        },
      },
    ],
  },
};
