import { FC } from 'react';
import { THREE_FULL_WIDTH } from '../../../../constants';
import { TransdimensionalUnitConversionServiceContext } from '../../../../contexts';
import {
  MeetingCreationThree,
  Three,
  ThreeLighting,
  ThreeScrollOffsetContainer,
  ThreeTransdimensionalBorder,
} from '../../..';

export const MainPageContactSectionThree: FC = () => {
  const width = window.innerWidth;
  const height = window.innerHeight;

  return (
    <Three
      id="mainPage_contactSection_three"
      inset
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeLighting />
      <ThreeScrollOffsetContainer>
        <MeetingCreationThree id="" />
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
