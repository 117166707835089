import { useEffect, useRef } from 'react';
import * as easingTypes from 'js-easing-functions';

import { EasingType } from '../../types';
import { minArray, maxArray } from '../../utilities';
import { useLayoutPoint } from '.';

export const useLayoutRangeEffect = ({
  fromLayoutPoint,
  toLayoutPoint,
  easingType,
  onTriggerEnter,
  onTriggerLeave,
  onTriggerRatio,
}: {
  fromLayoutPoint: string;
  toLayoutPoint: string;
  easingType?: EasingType;
  onTriggerEnter?: () => void;
  onTriggerLeave?: () => void;
  onTriggerRatio?: (ratio: number) => void;
}): void => {
  const fromLayoutPointScreenY = useLayoutPoint(fromLayoutPoint);
  const toLayoutPointScreenY = useLayoutPoint(toLayoutPoint);

  const layoutPointScreenYs = [
    fromLayoutPointScreenY,
    toLayoutPointScreenY,
  ] as number[];
  const lesserScreenY = minArray(layoutPointScreenYs);
  const greaterScreenY = maxArray(layoutPointScreenYs);

  const inRangeRef = useRef(false);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollY } = window;
      const inRange = scrollY > lesserScreenY && scrollY <= greaterScreenY;

      if (inRange !== inRangeRef.current) {
        switch (inRange) {
          case true:
            if (onTriggerEnter) onTriggerEnter();

            break;
          case false:
            if (onTriggerLeave) onTriggerLeave();

            break;
        }

        inRangeRef.current = inRange;
      }

      let ratio = (scrollY - lesserScreenY) / (greaterScreenY - lesserScreenY);

      // Invert the ratio (i.e. 0 becomes 1 and 1 becomes 0) if the layout
      // points are not in the presumed from-to => lesser-greater fashion.
      if (lesserScreenY !== fromLayoutPointScreenY) {
        ratio = 1 - ratio;
      }

      if (ratio > 1) {
        ratio = 1;
      } else if (ratio < 0) {
        ratio = 0;
      }

      if (easingType) {
        ratio = easingTypes[easingType](ratio, 0, 1, 1);
      }

      if (onTriggerRatio) onTriggerRatio(ratio);
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [
    fromLayoutPointScreenY,
    toLayoutPointScreenY,
    onTriggerEnter,
    onTriggerLeave,
    onTriggerRatio,
  ]);
};
