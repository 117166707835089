import { FC, ReactChild, useState } from 'react';
import { toLayoutPointY } from '../../../utilities';
import { useLayoutRangeEffect } from '../../../hooks';
import { EasingType } from '../../../types';
import { LayoutAnchor } from '..';

export const LayoutRangeEffect: FC<{
  id: string;
  fromLayoutPoint?: string;
  toLayoutPoint?: string;
  anchorOffset?: string;
  easingType?: EasingType;
  children: (parameters: { ratio: number | null }) => ReactChild | null;
}> = ({ id, anchorOffset, easingType, children, ...props }) => {
  if (typeof children !== 'function') {
    throw new TypeError('Children must be specified as a callback');
  }

  const fromAnchorId = id + '_from';
  const hasControlledFromLayoutPoint = !!props.fromLayoutPoint;
  const fromLayoutPoint = props.fromLayoutPoint
    ? props.fromLayoutPoint
    : toLayoutPointY(fromAnchorId);

  const toAnchorId = id + '_to';
  const hasControlledToLayoutPoint = !!props.toLayoutPoint;
  const toLayoutPoint = props.toLayoutPoint
    ? props.toLayoutPoint
    : toLayoutPointY(toAnchorId);

  const applyAnchorOffset = (layoutPoint: string) => {
    return `(${layoutPoint}) + (${anchorOffset || 0})`;
  };

  const [ratio, setRatio] = useState<number | null>(null);

  useLayoutRangeEffect({
    easingType,
    fromLayoutPoint: applyAnchorOffset(fromLayoutPoint),
    toLayoutPoint: applyAnchorOffset(toLayoutPoint),
    onTriggerRatio: setRatio,
  });

  return (
    <>
      {!hasControlledFromLayoutPoint && <LayoutAnchor y id={fromAnchorId} />}
      {children({ ratio })}
      {!hasControlledToLayoutPoint && <LayoutAnchor y id={toAnchorId} />}
    </>
  );
};
