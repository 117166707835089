import { Mesh } from 'three';

import { BoxColorCoercible } from '../../types';
import { getBoxColorAttribute } from '.';

export const setCubeColor = (mesh: Mesh, meshColor: BoxColorCoercible) => {
  const geometry = mesh.geometry;
  const colorAttribute = getBoxColorAttribute(meshColor);

  geometry.setAttribute('color', colorAttribute);

  if (!Array.isArray(mesh.material)) {
    mesh.material.vertexColors = true;
    mesh.material.needsUpdate = true;
  }
};
