import { BoxCornerColors, RectCornerColors } from '../../types';

export const getBoxCornerColorsFromFrontAndBackRects = (
  front: RectCornerColors,
  back: RectCornerColors
): BoxCornerColors => {
  return {
    frontTopRight: front.topRight.clone(),
    frontTopLeft: front.topLeft.clone(),
    frontBottomRight: front.bottomRight.clone(),
    frontBottomLeft: front.bottomLeft.clone(),
    backTopRight: back.topRight.clone(),
    backTopLeft: back.topLeft.clone(),
    backBottomRight: back.bottomRight.clone(),
    backBottomLeft: back.bottomLeft.clone(),
  };
};
