import { FC, PropsWithChildren, useEffect, useRef, useState } from 'react';
import {
  ObjectProps,
  MeshProps,
  BoxCornerColors,
  BoxColorCoercible,
} from '../../types';
import { useMaterial } from '../../hooks';
import { Mesh, Vector3 } from 'three';
import { setCubeColor, toBoxCornerColors, toVector3 } from '../../utilities';

export interface ThreeBoxProps extends ObjectProps, MeshProps {
  color?: BoxColorCoercible;
}

export const ThreeBox: FC<PropsWithChildren<ThreeBoxProps>> = ({
  color,
  ...props
}) => {
  const meshRef = useRef<Mesh>();
  const standardMaterial = useMaterial();
  const material = props.material ?? standardMaterial;
  const scale = props.scale ? toVector3(props.scale) : new Vector3(1, 1, 1);
  const aspectRatio = scale.x / scale.y;
  const [boxCornerColors, setBoxCornerColors] =
    useState<BoxCornerColors | null>(null);

  useEffect(() => {
    const mesh = meshRef.current;

    if (!mesh) return;
    if (boxCornerColors === null) return;

    setCubeColor(mesh, boxCornerColors);
  }, [boxCornerColors]);

  useEffect(() => {
    if (color) {
      const boxCornerColors = toBoxCornerColors(color, aspectRatio);

      setBoxCornerColors(boxCornerColors);
    } else {
      setBoxCornerColors(null);
    }
  }, [color, aspectRatio]);

  useEffect(() => {
    const mesh = meshRef.current;

    if (!mesh) return;
    if (!color) return;

    const boxCornerColors = toBoxCornerColors(color);

    setCubeColor(mesh, boxCornerColors);
  }, [boxCornerColors]);

  return (
    <mesh ref={meshRef as any} {...props} material={material}>
      <boxGeometry args={[1, 1, 1]}></boxGeometry>
    </mesh>
  );
};
