import { FC, PropsWithChildren } from 'react';
import { Box, SxProps, Theme, Typography, useMediaQuery } from '@mui/material';
import { DocumentSize } from '../../../types';

import './DocumentPage.scss';
import { DocumentContainer } from '../DocumentContainer';

export const DocumentPage: FC<
  PropsWithChildren<{
    documentSize: DocumentSize;
    pageNumber: number;
    sx?: SxProps<Theme>;
  }>
> = ({ documentSize, pageNumber, sx, children }) => {
  return (
    <Box
      component="div"
      className="DocumentPage-root"
      sx={{
        position: 'relative',
        overflow: 'hidden',
      }}
    >
      <Typography variant="body2" className="DocumentPage-label">
        Page {pageNumber}
      </Typography>
      <DocumentContainer documentSize={documentSize} sx={sx}>
        {children}
      </DocumentContainer>
    </Box>
  );
};
