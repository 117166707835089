import { FC, useRef, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import CSS from 'csstype';
import { setLayoutAnchorScreenPosition } from '../../../actions';
import { useWindowSize } from '../../../hooks';
import { DebugLayoutAnchor } from '../..';

export const LayoutAnchor: FC<{
  id: string;
  order?: number;
  style?: CSS.StandardProperties;
  x?: boolean;
  y?: boolean;
}> = ({ id, order, style, x, y }) => {
  const dispatch = useDispatch();
  const windowSize = useWindowSize();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!ref.current) return;

    const element = ref.current as HTMLDivElement;
    const updateScreenPosition = () => {
      const boundingRect = element.getBoundingClientRect();
      const screenX = window.scrollX + boundingRect.left;
      const screenY = window.scrollY + boundingRect.top;

      dispatch(
        setLayoutAnchorScreenPosition({
          id,
          x: screenX,
          y: screenY,
        })
      );
    };

    updateScreenPosition();

    setTimeout(updateScreenPosition, order || 0);
  }, [dispatch, id, ref.current, windowSize.x, windowSize.y]);

  return (
    <div ref={ref} id={id} style={style}>
      <DebugLayoutAnchor id={id} x={x} y={y} />
    </div>
  );
};
