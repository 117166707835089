import { FC } from 'react';
import { Color, MathUtils, Vector3 } from 'three';
import { HASHICORP_CERTIFICATION_TYPE_COLORS } from '../../constants';
import { HashicorpCertificationType } from '../../types';
import { useMaterial } from '../../hooks';
import { ThreeTransdimensionalRect, ThreeBox, ThreeModel } from '..';
import {
  getCertificationHashicorpHeaderRectId,
  getCertificationHashicorpBodyRectId,
} from './utilities';
import { useTheme } from '@mui/material';

const LOGO_DEFAULT_COLOR = new Color(0.5, 0.5, 0.5);

const HASHICORP_LOGO_DEPTH_RATIO = 0.25;
const HASHICORP_LOGO_LAYERS_COUNT = 2;
const HASHICORP_LOGO_PADDING_TOP = 0.1;
const HASHICORP_LOGO_PADDING_BOTTOM = 0.05;
const HASHICORP_LOGO_PADDING_HORIZONTAL = 0.1;
const HASHICORP_LOGO_LAYER_INSET = 0.045;
const HASHICORP_LOGO_BOTTOM_ANGLE = MathUtils.degToRad(30);
const HASHICORP_LOGO_DIVIDER_WIDTH = 0.4;
const HASHICORP_LOGO_DIVIDER_depth = 0.05;

export const CertificationHashicorpThree: FC<{
  id: string;
  type: HashicorpCertificationType;
}> = ({ id, type }) => {
  const theme = useTheme();

  const depth = theme.depth.paper;
  const logoDepth = depth * HASHICORP_LOGO_DEPTH_RATIO;
  const logoLayerDepth = logoDepth / HASHICORP_LOGO_LAYERS_COUNT;
  const headerRectId = getCertificationHashicorpHeaderRectId(id);
  const bodyRectId = getCertificationHashicorpBodyRectId(id);
  const certificationTypeColor =
    HASHICORP_CERTIFICATION_TYPE_COLORS.get(type) ?? LOGO_DEFAULT_COLOR;
  const certificationTypeMaterial = useMaterial(certificationTypeColor);
  const layerColorProps = {
    material: certificationTypeMaterial,
  };
  const paddingTopY = 1 / 2 - HASHICORP_LOGO_PADDING_TOP;
  const paddingBottomY = -(1 / 2 - HASHICORP_LOGO_PADDING_BOTTOM);
  const centerY =
    (HASHICORP_LOGO_PADDING_BOTTOM - HASHICORP_LOGO_PADDING_TOP) / 2;
  const paddingHeight =
    1 - HASHICORP_LOGO_PADDING_TOP - HASHICORP_LOGO_PADDING_BOTTOM;
  const paddingX = 1 / 2 - HASHICORP_LOGO_PADDING_HORIZONTAL;
  const paddingWidth = 1 - 2 * HASHICORP_LOGO_PADDING_HORIZONTAL;
  const insetX = paddingX - HASHICORP_LOGO_LAYER_INSET;
  const insetBottom =
    (1 / Math.cos(HASHICORP_LOGO_BOTTOM_ANGLE)) * HASHICORP_LOGO_LAYER_INSET;
  const insetBottomY = paddingBottomY + insetBottom;
  const wedgeHeight = Math.sin(HASHICORP_LOGO_BOTTOM_ANGLE) * paddingX;
  const insetWedgeHeight = Math.sin(HASHICORP_LOGO_BOTTOM_ANGLE) * insetX;

  return (
    <>
      <ThreeTransdimensionalRect id={headerRectId}>
        {/* backing */}
        <ThreeBox
          position={new Vector3(0, 0, -(logoDepth + (depth - logoDepth) / 2))}
          scale={new Vector3(1, 1, depth - logoDepth)}
        />
        {/* padding top */}
        <ThreeBox
          position={[
            0,
            paddingTopY + HASHICORP_LOGO_PADDING_TOP / 2,
            -logoDepth / 2,
          ]}
          scale={[paddingWidth, HASHICORP_LOGO_PADDING_TOP, logoDepth]}
        />
        {/* padding bottom */}
        <ThreeBox
          position={[
            0,
            paddingBottomY - HASHICORP_LOGO_PADDING_BOTTOM / 2,
            -logoDepth / 2,
          ]}
          scale={[paddingWidth, HASHICORP_LOGO_PADDING_BOTTOM, logoDepth]}
        />
        {/* padding left */}
        <ThreeBox
          position={[
            -(paddingX + HASHICORP_LOGO_PADDING_HORIZONTAL / 2),
            0,
            -logoDepth / 2,
          ]}
          scale={[HASHICORP_LOGO_PADDING_HORIZONTAL, 1, logoDepth]}
        />
        {/* padding right */}
        <ThreeBox
          position={[
            paddingX + HASHICORP_LOGO_PADDING_HORIZONTAL / 2,
            0,
            -logoDepth / 2,
          ]}
          scale={[HASHICORP_LOGO_PADDING_HORIZONTAL, 1, logoDepth]}
        />
        {/* padding bottom left */}
        <ThreeModel
          id="wedge"
          position={[
            -paddingX / 2,
            paddingBottomY + wedgeHeight / 2,
            -logoDepth / 2,
          ]}
          scale={[logoDepth, wedgeHeight, paddingX]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        {/* padding bottom right */}
        <ThreeModel
          id="wedge"
          position={[
            paddingX / 2,
            paddingBottomY + wedgeHeight / 2,
            -logoDepth / 2,
          ]}
          scale={[logoDepth, wedgeHeight, paddingX]}
          rotation={[0, Math.PI / 2, 0]}
        />
        {/* padding top, in color */}
        <ThreeBox
          {...layerColorProps}
          position={[
            0,
            paddingTopY - HASHICORP_LOGO_LAYER_INSET / 2,
            (logoLayerDepth * -3) / 2,
          ]}
          scale={[paddingWidth, HASHICORP_LOGO_LAYER_INSET, logoLayerDepth]}
        />
        {/* padding bottom, in color */}
        <ThreeBox
          {...layerColorProps}
          position={[
            0,
            paddingBottomY + insetBottom / 2,
            (logoLayerDepth * -3) / 2,
          ]}
          scale={[paddingWidth, insetBottom, logoLayerDepth]}
        />
        {/* padding left, in color */}
        <ThreeBox
          {...layerColorProps}
          position={[
            -(insetX + HASHICORP_LOGO_LAYER_INSET / 2),
            centerY,
            (logoLayerDepth * -3) / 2,
          ]}
          scale={[HASHICORP_LOGO_LAYER_INSET, paddingHeight, logoLayerDepth]}
        />
        {/* padding right, in color */}
        <ThreeBox
          {...layerColorProps}
          position={[
            insetX + HASHICORP_LOGO_LAYER_INSET / 2,
            centerY,
            (logoLayerDepth * -3) / 2,
          ]}
          scale={[HASHICORP_LOGO_LAYER_INSET, paddingHeight, logoLayerDepth]}
        />
        {/* padding bottom left, in color */}
        <ThreeModel
          {...layerColorProps}
          id="wedge"
          position={[
            -insetX / 2,
            insetBottomY + insetWedgeHeight / 2,
            (logoLayerDepth * -3) / 2,
          ]}
          scale={[logoLayerDepth, insetWedgeHeight, insetX]}
          rotation={[0, -Math.PI / 2, 0]}
        />
        {/* padding bottom right, in color */}
        <ThreeModel
          {...layerColorProps}
          id="wedge"
          position={[
            insetX / 2,
            insetBottomY + insetWedgeHeight / 2,
            (logoLayerDepth * -3) / 2,
          ]}
          scale={[logoLayerDepth, insetWedgeHeight, insetX]}
          rotation={[0, Math.PI / 2, 0]}
        />
        {/* padding bottom, in color */}
        <ThreeBox
          {...layerColorProps}
          position={[
            0,
            insetBottomY - HASHICORP_LOGO_LAYER_INSET / 2,
            (logoLayerDepth * -3) / 2,
          ]}
          scale={[paddingWidth, HASHICORP_LOGO_LAYER_INSET, logoLayerDepth]}
        />
        {/* divider, in color */}
        <ThreeBox
          {...layerColorProps}
          position={[0, centerY + 0.15, (logoLayerDepth * -3) / 2]}
          scale={[
            HASHICORP_LOGO_DIVIDER_WIDTH,
            HASHICORP_LOGO_DIVIDER_depth,
            logoLayerDepth,
          ]}
        />
      </ThreeTransdimensionalRect>
      <ThreeTransdimensionalRect id={bodyRectId}>
        <ThreeBox position={[0, 0, -depth / 2]} scale={[1, 1, depth]} />
      </ThreeTransdimensionalRect>
    </>
  );
};
