import { FC } from 'react';
import { PROJECTS } from '../../../../data';
import {
  ChipThree,
  Three,
  ThreeLighting,
  ThreeScrollOffsetContainer,
  TransdimensionalTableThree,
  TransdimensionlTableVariant,
} from '../../..';

export const ReferenceProjectsPageThree: FC = () => {
  return (
    <Three
      id="referenceProjectsPage_indexSection-three"
      interactive={false}
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeLighting />
      <ThreeScrollOffsetContainer>
        <TransdimensionalTableThree
          variant={TransdimensionlTableVariant.RowContained}
          id="referenceProjectsPage_indexSection-table"
          rowIds={new Set(PROJECTS.map((project) => project.id))}
        />
        {PROJECTS.map((project) => {
          return (
            <ChipThree
              key={project.id}
              id={project.id}
              depthOffset="foreground"
              boxColor={project.chip.background}
            />
          );
        })}
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
