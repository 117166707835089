import { FC, PropsWithChildren, ReactNode } from 'react';
import color from 'color';
import {
  Box,
  Container,
  Divider,
  Grid,
  Link,
  Stack,
  SxProps,
  Theme,
  Typography,
  alpha,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

import { TransdimensionalUnitConversionServiceContext } from '../../../contexts';
import {
  BrandCircle,
  Chip,
  DocumentPage,
  LayoutAnchor,
  Logo,
  LogoFillVariant,
  NoWrap,
  ThemeInverter,
  Three,
  ThreeBox,
  ThreeLighting,
  ThreeModel,
  ThreeQuad,
  ThreeTransdimensionalRect,
  TransdimensionalOffset,
  TransdimensionalRect,
} from '../..';
import {
  CONTACT_EMAIL_ADDRESS,
  CONTACT_TELEPHONE_NUMBER,
  THREE_FULL_DEPTH,
  THREE_FULL_HEIGHT,
  THREE_FULL_WIDTH,
} from '../../../constants';
import { useLayoutPoint, useMaterial } from '../../../hooks';
import { DocumentSize } from '../../../types';
import {
  emailAddressToHref,
  getColorFilter,
  telephoneNumberToHref,
  toLayoutPointX,
  toLayoutPointY,
} from '../../../utilities';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';

export const RESUME_DOCUMENT_SIZE: DocumentSize = {
  width: '8.5in',
  height: '11in',
  aspectRatio: '8.5 / 11',
};

const ResumeThree: FC<
  PropsWithChildren<{
    pageNumber: number;
  }>
> = ({ pageNumber, children }) => {
  return (
    <Three
      id={`resumeDocument_three${pageNumber}`}
      sx={{ position: 'absolute', width: '100%', height: '100%' }}
      fovScaling={false}
      frameloop="demand"
    >
      <ambientLight intensity={0.875} />
      <directionalLight color="white" position={[10, 5, 7.5]} intensity={0.4} />
      <TransdimensionalUnitConversionServiceContext.Consumer>
        {(transdimensionalUnitConversionService) => {
          if (!transdimensionalUnitConversionService) return null;

          const sceneWidth =
            transdimensionalUnitConversionService.getVisibleWidthAtDepth(0);
          const sceneHeight =
            transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);

          return <group position={[0, sceneHeight / 2, 0]}>{children}</group>;
        }}
      </TransdimensionalUnitConversionServiceContext.Consumer>
    </Three>
  );
};

const ResumeContainer: FC<PropsWithChildren<{}>> = ({ children }) => {
  const theme = useTheme();

  return (
    <Box
      component="div"
      sx={{ position: 'relative', padding: theme.spacing(0, 4) }}
    >
      {children}
    </Box>
  );
};

const ResumeSection: FC<PropsWithChildren<{ sideContent?: ReactNode }>> = ({
  sideContent,
  children,
}) => {
  return (
    <Box component="section" sx={{ display: 'flex', position: 'relative' }}>
      <Box
        component="div"
        sx={{
          position: 'relative',
          width: 62,
          height: '100%',
          flexShrink: 0,
          flexGrow: 0,
        }}
      >
        {sideContent}
      </Box>
      <Box
        component="div"
        sx={{ position: 'relative', flexGrow: 1, paddingLeft: 4 }}
      >
        {children}
      </Box>
    </Box>
  );
};

const ResumeSectionLabel: FC<
  PropsWithChildren<{
    sx?: SxProps<Theme>;
  }>
> = ({ sx, children }) => {
  return (
    <Typography
      variant="body2"
      fontSize="1.25rem"
      fontWeight={700}
      lineHeight="48px"
      sx={[
        {
          // background: 'url(/images/grainy.svg)',
          // backgroundClip: 'text',
          // textFillColor: 'transparent',
          transform: 'rotate(-90deg) translateX(-100%)',
          transformOrigin: 'top left',
          width: 'min-content',
          paddingRight: '9px',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {children}
    </Typography>
  );
};

const ResumeList: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Box
      component="ul"
      sx={{ paddingLeft: 0, margin: 0, listStyle: 'inside square' }}
    >
      {children}
    </Box>
  );
};

const ResumeEducationItem: FC<{
  id: string;
  institution: ReactNode;
  degree: ReactNode;
  completion: ReactNode;
}> = (props) => {
  return (
    <TransdimensionalRect
      id={`resumeDocument_educationItem_${props.id}`}
      display="block"
      sx={{ padding: 0 }}
    >
      <Typography component="span" variant="body1" fontWeight="bold">
        {props.institution}
      </Typography>
      <Typography component="span" variant="body2">
        {' '}
        — {props.completion}
      </Typography>
      <Typography variant="body1">{props.degree}</Typography>
    </TransdimensionalRect>
  );
};

const ResumeEducationItemThree: FC<{ id: string }> = (props) => {
  return (
    <ThreeTransdimensionalRect id={`resumeDocument_educationItem_${props.id}`}>
      <ThreeBox position={[0, 0, -0.1 / 2]} scale={[1, 1, 0.1]} />
    </ThreeTransdimensionalRect>
  );
};

const ResumeExperienceItem: FC<{
  id: string;
  organization: ReactNode;
  location: ReactNode;
  time: ReactNode;
  role: ReactNode;
  description: ReactNode;
}> = (props) => {
  return (
    <TransdimensionalRect
      id={`resumeDocument_experienceItem_${props.id}`}
      display="block"
      sx={{ padding: 0 }}
    >
      <Typography component="span" variant="body1" fontWeight="bold">
        {props.organization}
      </Typography>
      <Typography component="span" variant="body2">
        {' '}
        — {props.location} — {props.time}
      </Typography>
      <Typography variant="body1" fontWeight={600} fontStyle="italic">
        {props.role}
      </Typography>
      {props.description}
    </TransdimensionalRect>
  );
};

const ResumeExperienceItemThree: FC<{ id: string }> = (props) => {
  return (
    <ThreeTransdimensionalRect id={`resumeDocument_experienceItem_${props.id}`}>
      <ThreeBox position={[0, 0, -0.1 / 2]} scale={[1, 1, 0.1]} />
    </ThreeTransdimensionalRect>
  );
};

const HERO_BRAND_CIRCLE_SIZE = 103;
const HERO_H1_FONT_SIZE = '2.5rem';
const HERO_H1_OFFSET_LEFT = '14px';

export const ResumeDocument: FC = () => {
  const theme = useTheme();
  const dividerColor = 'rgb(224, 224, 224)';
  const matchesPrint = useMediaQuery('print');
  const contactMaterial = useMaterial(
    (theme) => theme.palette.brandYellow.main,
    { convertSRGBToLinear: true }
  );
  const basicMaterial = useMaterial('#C0C0C0', {
    convertSRGBToLinear: true,
  });
  const basicLightMaterial = useMaterial('#D0D0D0', {
    convertSRGBToLinear: true,
  });
  const basicLighterMaterial = useMaterial('#E4E4E4', {
    convertSRGBToLinear: true,
  });

  return (
    <>
      <DocumentPage
        documentSize={RESUME_DOCUMENT_SIZE}
        pageNumber={1}
        sx={{
          position: 'relative',
          backgroundColor: theme.palette.background.clear,
        }}
      >
        <ResumeThree pageNumber={1}>
          {/* <ThreeQuad
            scale={[THREE_FULL_WIDTH, THREE_FULL_HEIGHT, 1]}
            position={[0, 0, -THREE_FULL_DEPTH / 2]}
            rotation={[Math.PI / 2, 0, 0]}
          /> */}
          <ThreeTransdimensionalRect id="resumeDocument_biographyQuotationMarks">
            <ThreeModel
              id="quotation_marks"
              position={[0, 0, -0.0625 / 2]}
              scale={[1, 1, 0.0625]}
              rotation={[0, 0, 0]}
              material={basicLightMaterial}
            />
          </ThreeTransdimensionalRect>
          <ThreeTransdimensionalRect id="resumeDocument_contact">
            <ThreeBox
              position={[0, 0, -theme.depth.card / 2]}
              scale={[1, 1, theme.depth.card]}
              material={contactMaterial}
            />
          </ThreeTransdimensionalRect>
          <ThreeTransdimensionalRect id="resumeDocument_page1_sideContentTopPipe">
            <ThreeModel
              id="pipe_attachment"
              position={[0, 0, 0.1 / 2]}
              scale={[1, 0.1, 1]}
              rotation={[-Math.PI / 2, 0, 0]}
              material={basicLightMaterial}
            />
            <ThreeModel
              id="pipe_corner"
              position={[0, 0, 0.1 + 0.5 / 2]}
              scale={[1, 1, 0.5]}
              rotation={[0, 0, -Math.PI / 2]}
              material={basicMaterial}
            />
            <ThreeModel
              id="pipe"
              position={[-1, 0, 0.1 + 0.5 / 2]}
              scale={[1, 1, 0.5]}
              rotation={[0, 0, Math.PI / 2]}
              material={basicMaterial}
            />
          </ThreeTransdimensionalRect>
          <ThreeTransdimensionalRect id="resumeDocument_page1_sideContentTop">
            <ThreeBox
              position={[-1 / 2, -1 / 2, -theme.depth.card / 2]}
              scale={[2, 2, theme.depth.card]}
              rotation={[0, 0, 0]}
              material={basicLighterMaterial}
            />
          </ThreeTransdimensionalRect>
          <ThreeTransdimensionalRect id="resumeDocument_page1_sideContent">
            <ThreeBox
              position={[-1 / 2, -1 / 2, -theme.depth.card / 2]}
              scale={[2, 2, theme.depth.card]}
              rotation={[0, 0, 0]}
              material={basicLighterMaterial}
            />
          </ThreeTransdimensionalRect>
        </ResumeThree>
        <ResumeContainer>
          {/* Hero */}
          <Box
            component="div"
            sx={{
              position: 'relative',
              marginLeft: 0,
              marginTop: 4,
              marginBottom: 4,
              height: HERO_BRAND_CIRCLE_SIZE,
            }}
          >
            {/* Brand circle red gradient */}
            <Box
              component="div"
              sx={{
                width: 512,
                height: 512,
                backgroundImage:
                  'url(/images/ResumeDocumentBrandCircleBlur.png)',
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                position: 'absolute',
                left: -(512 - 103) / 2 - 24,
                top: -(512 - 103) / 2 - 24,
                filter: getColorFilter(theme.palette.brandRed.main),
                opacity: 0.5,
                pointerEvents: 'none',
                userSelect: 'none',
              }}
            />
            {/* Brand circle yellow gradient */}
            <Box
              component="div"
              sx={{
                width: 512,
                height: 512,
                backgroundImage:
                  'url(/images/ResumeDocumentBrandCircleBlur.png)',
                backgroundPosition: 'center center',
                backgroundSize: 'contain',
                position: 'absolute',
                left: -(512 - 103) / 2 + 24,
                top: -(512 - 103) / 2 + 24,
                filter: getColorFilter(theme.palette.brandYellow.main),
                opacity: 0.5,
                pointerEvents: 'none',
                userSelect: 'none',
              }}
            />
            {/* Hero text container */}
            <Box
              component="div"
              sx={{
                position: 'relative',
                left: HERO_H1_OFFSET_LEFT,
                top: 0,
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'baseline',
              }}
            >
              <Typography
                variant="h1"
                fontSize={HERO_H1_FONT_SIZE}
                sx={{ flexGrow: 0, lineHeight: HERO_BRAND_CIRCLE_SIZE + 'px' }}
              >
                Samuel Goodell
              </Typography>
              <Typography
                variant="body1"
                fontSize="1.25rem"
                sx={{ marginLeft: 2 }}
              >
                BSc, NREMT-P
              </Typography>
            </Box>
            <Box component="div" sx={{ position: 'absolute', top: 0 }}>
              <BrandCircle
                size={HERO_BRAND_CIRCLE_SIZE}
                sx={{
                  pointerEvents: 'none',
                  userSelect: 'none',
                }}
              >
                <Typography
                  variant="h1"
                  fontSize={HERO_H1_FONT_SIZE}
                  lineHeight={HERO_BRAND_CIRCLE_SIZE + 'px'}
                  color="white"
                  whiteSpace="pre"
                  sx={{
                    position: 'relative',
                    left: HERO_H1_OFFSET_LEFT,
                  }}
                >
                  Samuel Goodell
                </Typography>
              </BrandCircle>
            </Box>
            {/* Contact */}
            <Box
              component="div"
              sx={{
                position: 'absolute',
                top: -19,
                right: 0,
                textAlign: 'right',
              }}
            >
              <Link
                href={telephoneNumberToHref(CONTACT_TELEPHONE_NUMBER)}
                sx={{ textDecoration: 'none !important' }}
              >
                <Typography
                  variant="body1"
                  color={theme.palette.text.secondary}
                >
                  {CONTACT_TELEPHONE_NUMBER}
                </Typography>
              </Link>
              <Link
                href={emailAddressToHref(CONTACT_EMAIL_ADDRESS)}
                sx={{ textDecoration: 'none !important' }}
              >
                <Typography
                  variant="body1"
                  color={theme.palette.text.secondary}
                >
                  {CONTACT_EMAIL_ADDRESS}
                </Typography>
              </Link>
              <Link
                href="https://samuelgoodell.com"
                sx={{ textDecoration: 'none !important' }}
              >
                <Typography
                  variant="body1"
                  color={theme.palette.text.secondary}
                >
                  https://samuelgoodell.com
                </Typography>
              </Link>
            </Box>
          </Box>
          {/* Quote */}
          <Box
            component="div"
            sx={{
              position: 'relative',
              marginLeft: 0,
              marginRight: 0,
              paddingLeft: '94px',
              paddingBottom: '16px',
              minHeight: 48,
              '&::before': {
                content: "''",
                position: 'absolute',
                left: 70,
                top: 0,
                width: 'calc(100% - 70px)',
                height: '100%',
                borderLeft: '2px solid',
                borderBottom: '2px solid',
                borderColor: dividerColor,
                pointerEvents: 'none',
              },
              '&::after': {
                content: "''",
                position: 'absolute',
                left: theme.spacing(-4),
                bottom: 0,
                width: `calc(70px + ${theme.spacing(4)})`,
                height: 2,
                background: `linear-gradient(to left, ${dividerColor} 0%, ${theme.palette.background.clear} 100%)`,
              },
            }}
          >
            <TransdimensionalRect
              id="resumeDocument_biographyQuotationMarks"
              sx={{
                position: 'absolute',
                left: 0,
                top: 0,
                width: 48,
                height: 48,
              }}
            />
            <Typography variant="body1">
              {/* I'm an experienced software engineer specializing in
              human-computer interaction. My favorite thing to do is create
              rich, beautiful, interactive user experiences. I leverage a deep
              understanding of web technologies, design acumen, and
              interpersonal skills to bring products to life. */}
              I'm a recently-graduated paramedic with 911 response experience
              working for an AEMT level service. My goal is to obtain employment
              with a paramedic service in Dane County as I establish my EMS
              career and improve my skills as an ALS provider.
            </Typography>
          </Box>
          <br />
          <Box component="div" sx={{ position: 'relative' }}>
            <TransdimensionalRect
              id="resumeDocument_page1_sideContentTop"
              sx={{
                position: 'absolute',
                left: theme.spacing(-4),
                top: 0,
                width: `calc(${theme.spacing(4)} + 70px)`,
                height: '100%',
              }}
            />
            <TransdimensionalRect
              id="resumeDocument_page1_sideContentTopPipe"
              sx={{
                position: 'absolute',
                left: 0,
                top: 'calc((100% - 48px) / 2)',
                width: 48,
                height: 48,
              }}
            />
            <ResumeSection sideContent={null}>
              <TransdimensionalRect
                id="resumeDocument_contact"
                sx={{
                  padding: 1,
                  paddingBottom: 1.5,
                  marginBottom: 1,
                  textAlign: 'center',
                }}
              >
                <Typography variant="body1">
                  You have open access to my calendar!&nbsp;&nbsp;I'm happy to
                  connect.
                </Typography>
                <a href="https://samuelgoodell.com/meet" target="_blank">
                  <TransdimensionalRect
                    id="resumeDocument_contact_chip"
                    display="inline-block"
                  >
                    <Chip
                      endAdornment={
                        <OpenInNewOutlinedIcon
                          sx={{
                            position: 'relative',
                            top: -1.5,
                            transform: 'scale(1.05)',
                          }}
                        />
                      }
                    >
                      <Typography
                        component="span"
                        variant="body1"
                        color="rgb(192, 192, 192)"
                        sx={{ position: 'relative', top: -2 }}
                      >
                        https://
                      </Typography>
                      <Typography
                        component="span"
                        variant="body1"
                        color="white"
                        sx={{ position: 'relative', top: -2 }}
                      >
                        samuelgoodell.com/meet
                      </Typography>
                    </Chip>
                  </TransdimensionalRect>
                </a>
              </TransdimensionalRect>
            </ResumeSection>
          </Box>
          <Box component="div" sx={{ position: 'relative', paddingTop: 1 }}>
            <TransdimensionalRect
              id="resumeDocument_page1_sideContent"
              sx={{
                position: 'absolute',
                left: theme.spacing(-4),
                top: 0,
                width: `calc(${theme.spacing(4)} + 70px)`,
                height: '100%',
              }}
            />
            <ResumeSection
              sideContent={<ResumeSectionLabel>Education</ResumeSectionLabel>}
            >
              <Stack spacing={1}>
                <ResumeEducationItem
                  id="universityOfWisconsin"
                  institution="University of Wisconsin - Madison"
                  degree="Bachelor of Science, Computer Sciences"
                  completion="May 2018"
                />
                <ResumeEducationItem
                  id="madisonCollege"
                  institution="Madison Area Technical College"
                  degree="Paramedic Technical Diploma"
                  completion="December 2023"
                />
              </Stack>
              <Box
                component="hr"
                sx={{
                  width: '100%',
                  height: 0,
                  border: '1px solid',
                  borderColor: dividerColor,
                  marginTop: 1,
                  marginBottom: 1,
                }}
              />
            </ResumeSection>
            <ResumeSection
              sideContent={
                <ResumeSectionLabel>
                  <NoWrap>Work Experience</NoWrap>
                </ResumeSectionLabel>
              }
            >
              <Stack spacing={1}>
                <ResumeExperienceItem
                  id="crossPlainsEms"
                  organization="Cross Plains EMS"
                  location="Cross Plains, WI"
                  time="June 2023 to Present"
                  role="Emergency Medical Technician"
                  description={
                    <ResumeList>
                      <li>
                        <Typography component="span" variant="body1">
                          Responding to 911 calls and providing emergency
                          medical care as an ambulance crew member with an AEMT
                          level service.
                        </Typography>
                      </li>
                      <li>
                        <Typography component="span" variant="body1">
                          Assisting with department needs such as training,
                          recruitment, community outreach, CAD technology
                          support, and ambulance improvements.
                        </Typography>
                      </li>
                    </ResumeList>
                  }
                />
                <ResumeExperienceItem
                  id="dottid"
                  organization="Dottid"
                  location="Remote"
                  time="June 2022 to March 2023"
                  role="Senior Software Engineer"
                  description={
                    <ResumeList>
                      <li>
                        <Typography component="span" variant="body1">
                          Acted as lead frontend software engineer for a data
                          visualization suite developed for the commercial real
                          estate industry.
                        </Typography>
                      </li>
                      <li>
                        <Typography component="span" variant="body1">
                          Mentored junior engineers and interviewed engineering
                          candidates.
                        </Typography>
                      </li>
                    </ResumeList>
                  }
                />
                <ResumeExperienceItem
                  id="undigital"
                  organization="UnDigital"
                  location="Remote"
                  time="June 2021 to March 2022"
                  role="Software Engineer"
                  description={
                    <ResumeList>
                      <li>
                        <Typography component="span" variant="body1">
                          Worked with an early-revenue startup engineering team
                          to develop the company's software controlled on-demand
                          printed marketing product.
                        </Typography>
                      </li>
                    </ResumeList>
                  }
                />
              </Stack>
            </ResumeSection>
          </Box>
        </ResumeContainer>
        <Box component="div" sx={{ color: theme.palette.text.secondary }}>
          <ArrowDownwardOutlinedIcon
            fontSize="large"
            color="inherit"
            sx={{
              position: 'absolute',
              left: `calc(${theme.spacing(4)} + 7px)`,
              bottom: theme.spacing(2),
            }}
          />
        </Box>
      </DocumentPage>
      <DocumentPage
        documentSize={RESUME_DOCUMENT_SIZE}
        pageNumber={2}
        sx={{
          backgroundColor: theme.palette.background.clear,
        }}
      >
        <ResumeThree pageNumber={2}>
          <ThreeTransdimensionalRect id="resumeDocument_page2_sideContent">
            <ThreeBox
              position={[-1 / 2, 1 / 2, -theme.depth.card / 2]}
              scale={[2, 2, theme.depth.card]}
              rotation={[0, 0, 0]}
              material={basicLighterMaterial}
            />
          </ThreeTransdimensionalRect>
        </ResumeThree>
        <Box component="div" sx={{ color: theme.palette.text.secondary }}>
          <ArrowDownwardOutlinedIcon
            fontSize="large"
            color="inherit"
            sx={{
              position: 'absolute',
              left: `calc(${theme.spacing(4)} + 7px)`,
              top: theme.spacing(2),
            }}
          />
        </Box>
        <ResumeContainer>
          <Box component="div" sx={{ position: 'relative' }}>
            <TransdimensionalRect
              id="resumeDocument_page2_sideContent"
              sx={{
                position: 'absolute',
                left: theme.spacing(-4),
                top: 0,
                width: `calc(${theme.spacing(4)} + 70px)`,
                height: '100%',
              }}
            />
            <ResumeSection sideContent={null}>
              <Stack spacing={1}>
                <ResumeExperienceItem
                  id="gettyImages"
                  organization="Getty Images"
                  location="Madison, WI"
                  time="June 2019 to March 2021"
                  role="Software Engineer"
                  description={
                    <ResumeList>
                      <li>
                        <Typography component="span" variant="body1">
                          As a member of the Content Ingestion software
                          development team, engineered software features used in
                          uploading and storing images and videos.
                        </Typography>
                      </li>
                      <li>
                        <Typography component="span" variant="body1">
                          Interfaced with field editors and contributing
                          photographers to determine project requirements.
                        </Typography>
                      </li>
                    </ResumeList>
                  }
                />
                <ResumeExperienceItem
                  id="hardinDesignAndDevelopment"
                  organization="Hardin Design and Development"
                  location="Madison, WI"
                  time="August 2018 to March 2019"
                  role="Web Applications Developer"
                  description={
                    <ResumeList>
                      <li>
                        <Typography component="span" variant="body1">
                          Developed and maintained web applications used by
                          major commercial real estate and insurance firms,
                          working as a consultant to identify and meet project
                          goals.
                        </Typography>
                      </li>
                    </ResumeList>
                  }
                />
              </Stack>
            </ResumeSection>
          </Box>
        </ResumeContainer>
      </DocumentPage>
      <DocumentPage
        documentSize={RESUME_DOCUMENT_SIZE}
        pageNumber={3}
        sx={{
          backgroundColor: theme.palette.background.clear,
        }}
      >
        <ResumeThree pageNumber={3}></ResumeThree>
        <ResumeContainer>
          <Stack spacing={2} sx={{ marginTop: 2 }}>
            <ResumeSection
              sideContent={
                <ResumeSectionLabel sx={{ marginTop: 0 }}>
                  Certifications
                </ResumeSectionLabel>
              }
            >
              {/* TODO */}
            </ResumeSection>
          </Stack>
        </ResumeContainer>
      </DocumentPage>
    </>
  );
};
