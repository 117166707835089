import { FC } from 'react';
import { useTheme } from '@mui/material';
import {
  Three,
  ThreeBox,
  ThreeLighting,
  ThreeScrollOffsetContainer,
  ThreeTransdimensionalRect,
} from '../../..';
import { useMaterial } from '../../../../hooks';

export const ReferenceSchemaPageThree: FC = () => {
  const theme = useTheme();
  const gutterMaterial = useMaterial('rgb(67, 69, 58)', {
    convertSRGBToLinear: true,
  });
  const gutterActiveLineMaterial = useMaterial('rgb(51, 51, 51)', {
    convertSRGBToLinear: true,
  });

  const editorLinesCount = 23;
  const editorActiveLineIndex = 9;

  return (
    <Three
      id="referenceSchemaPage-three"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeLighting />
      <ThreeScrollOffsetContainer>
        <ThreeTransdimensionalRect id="aceEditorTest-gutter">
          <ThreeBox
            position={[0, 0, -0.1]}
            scale={[1, 1, 0.2]}
            material={gutterMaterial}
          />
          <ThreeBox
            position={[
              0,
              -0.5 + (1 - (editorActiveLineIndex + 0.5) / editorLinesCount),
              -0.1,
            ]}
            scale={[1 + 0.00001, 1 / editorLinesCount, 0.2]}
            material={gutterActiveLineMaterial}
          />
        </ThreeTransdimensionalRect>
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
