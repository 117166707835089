import { createAction } from '@reduxjs/toolkit';
import { ThreeModelTransformationPipelineParameters } from '../types';
import { threeModelsSlice } from '../slices';

export const { setThreeModel } = threeModelsSlice.actions;

export const requestThreeModel = createAction<{
  id: string;
  url: string;
  transform?: ThreeModelTransformationPipelineParameters;
}>('threeModels/requestThreeModel');
