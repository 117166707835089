import {
  FC,
  ReactElement,
  ReactNode,
  cloneElement,
  useEffect,
  useRef,
  useState,
} from 'react';
import { flushSync } from 'react-dom';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Container as MuiContainer,
  Divider,
  Tab,
  Tabs,
  Typography,
  alpha,
  useTheme,
  Grid,
  Stack,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TableRow,
  Table,
  TableBody,
  TableCell,
} from '@mui/material';
import AirOutlinedIcon from '@mui/icons-material/AirOutlined';
import HvacOutlinedIcon from '@mui/icons-material/HvacOutlined';
import DnsOutlinedIcon from '@mui/icons-material/DnsOutlined';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import FormatColorResetOutlinedIcon from '@mui/icons-material/FormatColorResetOutlined';
import WaterDropOutlinedIcon from '@mui/icons-material/WaterDropOutlined';
import ThermostatOutlinedIcon from '@mui/icons-material/ThermostatOutlined';
import FanSpeedOutlinedIcon from '../../../../@mui/icons-material/FanSpeedOutlined';
import WaterTapOutlinedIcon from '../../../../@mui/icons-material/WaterTapOutlined';
import FireOutlinedIcon from '../../../../@mui/icons-material/FireOutlined';
import RouterOutlinedIcon from '@mui/icons-material/RouterOutlined';
import BorderOuterOutlinedIcon from '@mui/icons-material/BorderOuterOutlined';
import SettingsSystemDaydreamOutlinedIcon from '@mui/icons-material/SettingsSystemDaydreamOutlined';
import SensorDoorOutlinedIcon from '@mui/icons-material/SensorDoorOutlined';
import DoorSlidingOutlinedIcon from '@mui/icons-material/DoorSlidingOutlined';
import GarageDoorOutlinedIcon from '../../../../@mui/icons-material/GarageDoorOutlined';
import LightOutlinedIcon from '@mui/icons-material/LightOutlined';
import {
  Breadcrumbs,
  Chip,
  ColumnDialog,
  Container,
  DialogHeader,
  PageTitle,
  Section,
  TransdimensionalOffset,
  TransdimensionalRect,
  TransdimensionalTable,
} from '../../..';
import {
  EMPTY_VALUE_INDICATOR,
  HOME_ACCESSORIES,
  HOME_ROOMS,
} from '../../../../constants';
import { HomeAccessoryCategory } from '../../../../types';
import AmazonEchoOutlinedIcon from '../../../../@mui/icons-material/AmazonEchoOutlined';
import AirConditionerOutlinedIcon from '../../../../@mui/icons-material/AirConditionerOutlined';
import AppleTvOutlinedIcon from '../../../../@mui/icons-material/AppleTvOutlined';
import CameraOnTripodOutlinedIcon from '../../../../@mui/icons-material/CameraOnTripodOutlined';
import ClosedWindowOutlinedIcon from '../../../../@mui/icons-material/ClosedWindowOutlined';
import HumidifierOutlinedIcon from '../../../../@mui/icons-material/HumidifierOutlined';
import JalousieOutlinedIcon from '../../../../@mui/icons-material/JalousieOutlined';
import LightBulbOutlinedIcon from '../../../../@mui/icons-material/LightBulbOutlined';
import LoudspeakerIcon from '../../../../@mui/icons-material/Loudspeaker';
import MotionSensorIcon from '../../../../@mui/icons-material/MotionSensor';
import WallSocketOutlinedIcon from '../../../../@mui/icons-material/WallSocketOutlined';
import ButtonOutlinedIcon from '../../../../@mui/icons-material/ButtonOutlined';
import AlarmOutlinedIcon from '../../../../@mui/icons-material/AlarmOutlined';
import SensorIcon from '../../../../@mui/icons-material/Sensor';
import BathtubOutlinedIcon from '../../../../@mui/icons-material/BathtubOutlined';
import GardenSprinklerOutlinedIcon from '../../../../@mui/icons-material/GardenSprinklerOutlined';
import SpeakerOutlinedIcon from '@mui/icons-material/SpeakerOutlined';
import SwitchOutlinedIcon from '../../../../@mui/icons-material/SwitchOutlined';
import RemoteControlOutlinedIcon from '../../../../@mui/icons-material/RemoteControlOutlined';
import TvOutlinedIcon from '../../../../@mui/icons-material/TvOutlined';
import DoorbellOutlinedIcon from '@mui/icons-material/DoorbellOutlined';
import InfraredSensorOutlinedIcon from '../../../../@mui/icons-material/InfraredSensorOutlined';
import SphereOutlinedIcon from '../../../../@mui/icons-material/SphereOutlined';
// import { ReferenceProjectsPageThree } from '.';

const HOME_ACCESSORY_CATEGORY_ICONS = new Map<HomeAccessoryCategory, ReactNode>(
  [
    ['airConditioner', <AirConditionerOutlinedIcon />],
    ['airport', <SettingsSystemDaydreamOutlinedIcon />],
    ['airPurifier', <AirOutlinedIcon />],
    ['appleTv', <AppleTvOutlinedIcon />],
    ['bridge', <DnsOutlinedIcon />],
    ['dehumidifier', <FormatColorResetOutlinedIcon />],
    ['humidifier', <HumidifierOutlinedIcon />],
    ['door', <SensorDoorOutlinedIcon />],
    ['slidingDoor', <DoorSlidingOutlinedIcon />],
    ['doorLock', <LockOutlinedIcon />],
    ['fan', <FanSpeedOutlinedIcon />],
    ['faucet', <WaterTapOutlinedIcon />],
    ['garage', <GarageDoorOutlinedIcon />],
    ['heater', <FireOutlinedIcon />],
    ['homePod', <AmazonEchoOutlinedIcon />],
    ['homePodMini', <SphereOutlinedIcon />],
    ['ipCamera', <CameraOnTripodOutlinedIcon />],
    ['lightbulb', <LightBulbOutlinedIcon />],
    ['outlet', <WallSocketOutlinedIcon />],
    ['programmableSwitch', <ButtonOutlinedIcon />],
    ['rangeExtender', <BorderOuterOutlinedIcon />],
    ['securitySystem', <AlarmOutlinedIcon />],
    ['sensor', <SensorIcon />],
    ['contactSensor', <InfraredSensorOutlinedIcon />],
    ['motionSensor', <MotionSensorIcon />],
    ['showerHead', <BathtubOutlinedIcon />],
    ['speaker', <SpeakerOutlinedIcon />],
    ['sprinkler', <GardenSprinklerOutlinedIcon />],
    ['switch', <SwitchOutlinedIcon />],
    ['targetController', <RemoteControlOutlinedIcon />],
    ['television', <TvOutlinedIcon />],
    ['thermostat', <ThermostatOutlinedIcon />],
    ['videoDoorBell', <DoorbellOutlinedIcon />],
    ['window', <ClosedWindowOutlinedIcon />],
    ['windowCovering', <JalousieOutlinedIcon />],
  ]
);

const getHashValue = (hash: string | null | undefined) =>
  (hash || '#').replace(/^#/, '') || null;

export const ReferenceHomeAccessoriesPage: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const dialogElementRef = useRef<HTMLDivElement>(null);
  const handleClickItem = (
    itemId: string,
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    navigate({
      hash: `#${itemId}`,
    });
  };

  const itemElementsRef = useRef(
    new Map<string /* item ID */, HTMLDivElement>()
  );
  const handleItemElement = (
    itemId: string,
    itemElement: HTMLDivElement | null
  ) => {
    if (itemElement) {
      itemElementsRef.current.set(itemId, itemElement);
    } else {
      itemElementsRef.current.delete(itemId);
    }
  };

  const [itemId, setItemId] = useState<string | null>(
    getHashValue(location.hash)
  );
  const [activeItemId, setActiveItemId] = useState<string | null>(itemId);
  const activeItem =
    HOME_ACCESSORIES.find((accessory) => accessory.id === activeItemId) ?? null;

  useEffect(() => {
    const itemId = getHashValue(location.hash);

    setItemId(itemId);

    if (itemId) {
      flushSync(() => {
        setActiveItemId(itemId);
      });
    }
  }, [location.hash]);

  const theme = useTheme();

  const [roomIds, setRoomIds] = useState<string[]>([]);

  const handleChangeRoomIds = (event: SelectChangeEvent<typeof roomIds>) => {
    console.log(event.target.value);
  };

  return (
    <>
      <PageTitle>Home Accessories Reference</PageTitle>
      <Section
        id="referenceProjectsPage_indexSection"
        // three={<ReferenceProjectsPageThree />}
      >
        <MuiContainer maxWidth="lg">
          <Breadcrumbs
            variant="pageHeader"
            prefixBrandCircle
            items={[
              {
                to: '/reference',
                text: 'Reference',
              },
              {
                to: '/reference/home-accessories',
                text: 'Home Accessories',
              },
            ]}
          />
          <Box component="div" sx={{ marginBottom: 1.5 }}>
            <FormControl sx={{ width: 300 }}>
              <InputLabel id="rooms-select-label">Rooms</InputLabel>
              <Select
                labelId="rooms-select-label"
                id="rooms-select"
                multiple
                value={roomIds}
                onChange={handleChangeRoomIds}
                input={<OutlinedInput label="Rooms" />}
                renderValue={(selected) => (
                  <Box
                    component="div"
                    sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}
                  >
                    {selected.map((value) => (
                      <Chip key={value}>{value}</Chip>
                    ))}
                  </Box>
                )}
              >
                {HOME_ROOMS.map((room) => {
                  const isSelected = roomIds.includes(room.id);

                  return (
                    <MenuItem
                      key={room.id}
                      value={room.id}
                      style={{
                        ...(isSelected && {
                          backgroundColor: theme.palette.action.selected,
                        }),
                      }}
                    >
                      {room.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>
          <Typography variant="h4">Bedroom</Typography>
          <Grid container spacing={2}>
            {HOME_ACCESSORIES.map((item) => (
              <Grid key={item.id} item xs={12} sm={6} md={4} lg={3}>
                <Box
                  ref={(itemElement: HTMLDivElement) =>
                    handleItemElement(item.id, itemElement)
                  }
                  component="div"
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: theme.palette.background.paper,
                    padding: 2,
                  }}
                  onClick={(event) => handleClickItem(item.id, event)}
                >
                  <Stack direction="row" spacing={1.5}>
                    {(() => {
                      const node = HOME_ACCESSORY_CATEGORY_ICONS.get(
                        item.category
                      );

                      return cloneElement(node as ReactElement, {
                        sx: {
                          margin: 0.5,
                        },
                      });
                    })()}
                    <Typography variant="body1" fontWeight="bold">
                      {item.name}
                    </Typography>
                  </Stack>
                </Box>
              </Grid>
            ))}
          </Grid>
        </MuiContainer>
      </Section>
      <ColumnDialog
        key={activeItemId}
        ref={dialogElementRef}
        open={!!itemId}
        originElement={() => {
          if (!activeItemId) return null;

          const itemElement = itemElementsRef.current.get(
            activeItemId
          ) as HTMLElement;

          return itemElement ?? null;
        }}
        align="right"
        onClose={() => navigate({ hash: '' })}
      >
        <DialogHeader>
          <Breadcrumbs
            variant="pageHeader"
            prefixBrandCircle
            maxItems={2}
            items={[
              {
                to: '/reference',
                text: 'Reference',
              },
              {
                to: '/reference/home-accessories',
                text: 'Home Accessories',
              },
              {
                to: '/reference/home-accessories',
                text: (
                  <>
                    {activeItem &&
                      cloneElement(
                        HOME_ACCESSORY_CATEGORY_ICONS.get(
                          activeItem.category
                        ) as any,
                        {
                          fontSize: 'inherit',
                          sx: { marginRight: 1, marginTop: '2px' },
                        }
                      )}
                    {activeItem?.name}
                  </>
                ),
              },
            ]}
          />
        </DialogHeader>
        <Container maxWidth="md">
          <Tabs
            value={0}
            onChange={() => {}}
            variant="scrollable"
            scrollButtons={false}
          >
            <Tab label="Details" />
            <Tab label="Status" />
            <Tab label="Logs" />
            <Tab label="Pair" />
          </Tabs>
          <Table>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row">
                  Manufacturer
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">
                    {activeItem?.manufacturer ?? EMPTY_VALUE_INDICATOR}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Model
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">
                    {activeItem?.model ?? EMPTY_VALUE_INDICATOR}
                  </Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row">
                  Serial Number
                </TableCell>
                <TableCell align="right">
                  <Typography variant="body1">
                    {activeItem?.serial ?? EMPTY_VALUE_INDICATOR}
                  </Typography>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Container>
      </ColumnDialog>
    </>
  );
};
