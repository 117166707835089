import { FC } from 'react';
import Marquee from 'react-fast-marquee';
import { Box, Container, Typography, useTheme } from '@mui/material';
import {
  Breadcrumbs,
  Chip,
  ProjectForeverdaunt,
  ProjectEnsemBLE,
  ProjectAirBlock,
  ProjectCameraStatusIndicator,
  Section,
  ThemeInverter,
  TransdimensionalRect,
  TransdimensionalOffset,
  Heading,
  ChipInlineWrapper,
} from '../../..';

import { MainPageProjectsSectionThree } from '.';

export const MainPageProjectsSection: FC = () => {
  const theme = useTheme();

  return (
    <Section id="section-projects" three={<MainPageProjectsSectionThree />}>
      <Container maxWidth="md">
        <Heading variant="h2" id="my-projects">
          My Projects
        </Heading>
      </Container>
      <Marquee gradient={false}>
        <Box component="div" sx={{ marginRight: 2 }}>
          <ProjectForeverdaunt />
        </Box>
      </Marquee>
      <Container maxWidth="md" sx={{ paddingTop: 4, paddingBottom: 4 }}>
        <TransdimensionalRect id="projects-reference-panel" display="block">
          <Box component="div" sx={{ padding: 2 }}>
            <Typography variant="body1">
              You can see the full list of my projects and their associated
              documentation at{' '}
              <ChipInlineWrapper>
                <TransdimensionalRect
                  id="mainPage_projectsSection_referenceChip"
                  display="inline-block"
                >
                  <TransdimensionalOffset
                    z={theme.depth.chip}
                    display="inline-block"
                  >
                    <Chip>
                      <ThemeInverter>
                        <Breadcrumbs
                          variant="chip"
                          prefixBrandCircle
                          items={[
                            {
                              to: '/reference',
                              text: 'Reference',
                            },
                            {
                              to: '/reference/projects',
                              text: 'Projects',
                            },
                          ]}
                        />
                      </ThemeInverter>
                    </Chip>
                  </TransdimensionalOffset>
                </TransdimensionalRect>
              </ChipInlineWrapper>
              .
            </Typography>
          </Box>
        </TransdimensionalRect>
      </Container>
    </Section>
  );
};
