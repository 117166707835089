import { Color, Material, MeshStandardMaterial } from 'three';
import { PointColorCoercible, MaterialOptions } from '../../types';
import { toColor } from '.';

const materials = new Map<string /* color hex */, Material>();

export const getOrCreateMaterial = (
  colorCoercible: PointColorCoercible,
  options: MaterialOptions = {
    convertSRGBToLinear: false,
  }
): Material => {
  let threeColor = toColor(colorCoercible);

  if (options.convertSRGBToLinear) {
    threeColor = threeColor.clone().convertSRGBToLinear();
  }

  const colorHex = threeColor.getHex().toString(16);

  if (materials.has(colorHex)) {
    return materials.get(colorHex)!;
  }

  const material = new MeshStandardMaterial();
  material.color.set(threeColor);

  materials.set(colorHex, material);

  return material;
};
