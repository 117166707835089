// Redux
import { createSelector } from '@reduxjs/toolkit';

// Types
import { RootState } from '../store';

export const selectLayout = (state: RootState) => state.layout;

export const selectLayoutAnchors = createSelector(
  selectLayout,
  (layoutState) => layoutState.layoutAnchorById
);
