import { FC } from 'react';
import { Container } from '@mui/material';
import { ApplicationLoadingContainer, PageFooter } from '../..';
import {
  MainPageIntroductionSection,
  MainPageServicesSection,
  MainPageProjectsSection,
  MainPageEducationSection,
  MainPageCertificationsSection,
  MainPageExperienceSection,
  MainPageContactSection,
} from '.';

import './MainPage.scss';

export const MainPage: FC = () => {
  return (
    <>
      {/* <ApplicationScrollContainer height="300vh"> */}
      <MainPageIntroductionSection />
      <MainPageServicesSection />
      <MainPageProjectsSection />
      <MainPageEducationSection />
      <MainPageCertificationsSection />
      <MainPageContactSection />
      <Container maxWidth="md">
        <PageFooter />
      </Container>
    </>
  );
};
