import { FC, useEffect, useState } from 'react';
import moment, { Moment } from 'moment';
import {
  Stack,
  Box,
  Typography,
  Container,
  Divider,
  useTheme,
} from '@mui/material';
import { CalendlyAvailableTime } from '../../types';
import {
  ColumnDialog,
  DialogHeader,
  FadeTransition,
  NoWrap,
  Section,
  Slide,
} from '..';
import {
  MeetingCreationForm,
  MeetingCreationFormValues,
  MeetingDetailsList,
  MeetingTimeSelection,
} from '.';

export const MeetingCreationDateDialog: FC<{
  in?: boolean;
  onClose: () => void;
  originElement: () => HTMLElement | null;
  localeDate: Moment;
  availableTimes: CalendlyAvailableTime[];
  onSubmit: (formValues: MeetingCreationFormValues) => void;
}> = (props) => {
  const theme = useTheme();
  const open = Boolean(props.in);
  const [timeSelectionClearSymbol, setTimeSelectionClearSymbol] =
    useState<Symbol>(Symbol());
  const [selectedStartTime, setSelectedStartTime] = useState<string | null>(
    null
  );
  const selectedAvailableTime =
    props.availableTimes.find(
      (availableTime) => availableTime.start_time === selectedStartTime
    ) ?? null;
  const [persistedSelectedStartTime, setPersistedSelectedStartTime] = useState<
    string | null
  >(selectedStartTime);

  useEffect(() => {
    if (selectedStartTime) {
      setPersistedSelectedStartTime(selectedStartTime);
    }
  }, [selectedStartTime]);

  const [slideActiveIndex, setSlideActiveIndex] = useState(0);

  useEffect(() => {
    if (!open) {
      setTimeout(() => {
        setSlideActiveIndex(0);
      }, theme.transitions.duration.standard);
    }
  }, [open]);

  return (
    <ColumnDialog
      open={open}
      onClose={props.onClose}
      originElement={props.originElement}
      persistOriginElement
      align="right"
    >
      {(() => {
        if (!props.localeDate) return null;

        return (
          <Stack sx={{ height: '100%' }}>
            <DialogHeader>
              <FadeTransition activeKey={slideActiveIndex.toString()}>
                <Box
                  component="div"
                  sx={{
                    position: 'absolute',
                    height: 56,
                    top: theme.spacing(1.5),
                  }}
                >
                  <NoWrap>
                    <Typography variant="h3" fontWeight="bold">
                      {(() => {
                        switch (slideActiveIndex) {
                          case 0:
                            return 'Select Time';
                          case 1:
                            return 'Booking Details';
                        }

                        return null;
                      })()}
                    </Typography>
                  </NoWrap>
                </Box>
              </FadeTransition>
            </DialogHeader>
            <Container maxWidth="md">
              <Divider />
              <MeetingDetailsList
                active
                delay={slideActiveIndex === 0 ? 0 : 300}
                localeDate={props.localeDate}
                startTime={selectedStartTime ? moment(selectedStartTime) : null}
                showChangeTimeButton
                onChangeTime={() => {
                  setSelectedStartTime(null);
                  setTimeSelectionClearSymbol(Symbol());
                  setSlideActiveIndex(0);
                }}
              />
              <Divider />
            </Container>
            <Box
              component="div"
              sx={{
                flexGrow: 1,
              }}
            >
              <Slide
                activeIndex={slideActiveIndex}
                renderIndex={(index) => {
                  switch (index) {
                    case 0:
                      return (
                        <Container maxWidth="md">
                          <MeetingTimeSelection
                            clearSymbol={timeSelectionClearSymbol}
                            availableTimes={props.availableTimes}
                            onSelect={(availableTime) => {
                              setSelectedStartTime(availableTime.start_time);
                              setSlideActiveIndex(1);
                            }}
                          />
                          <Box
                            component="div"
                            sx={{ height: 'env(safe-area-inset-bottom)' }}
                          />
                        </Container>
                      );
                    case 1:
                      return (
                        <Container maxWidth="md">
                          <MeetingCreationForm
                            key={persistedSelectedStartTime}
                            initialValues={{
                              startTime:
                                selectedAvailableTime?.start_time ?? null,
                            }}
                            onSubmit={props.onSubmit}
                          />
                          <Box
                            component="div"
                            sx={{ height: 'env(safe-area-inset-bottom)' }}
                          />
                        </Container>
                      );
                  }

                  return null;
                }}
              />
            </Box>
          </Stack>
        );
      })()}
    </ColumnDialog>
  );
};
