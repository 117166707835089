import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDebugMode } from '../../../actions';
import { selectDebugMode } from '../../../selectors';

export const DebugModeToggle: FC<PropsWithChildren<{}>> = ({ children }) => {
  const dispatch = useDispatch();
  const debugMode = useSelector(selectDebugMode);

  const debounceRef = useRef(Symbol());
  const handleMouseDown = () => {
    const debounce = (debounceRef.current = Symbol());

    setTimeout(() => {
      if (debounceRef.current !== debounce) return;

      dispatch(setDebugMode(!debugMode));
    }, 10 * 1000);
  };

  useEffect(() => {
    const handleMouseUp = () => {
      debounceRef.current = Symbol();
    };

    document.addEventListener('mouseup', handleMouseUp, { passive: true });
  }, []);

  return (
    <div className="DebugModeToggle" onMouseDown={handleMouseDown}>
      {children}
    </div>
  );
};
