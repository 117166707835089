export enum AWSCertificationType {
  CloudPractitioner = 'cloudPractitioner',
  Developer = 'developer',
  SolutionsArchitect = 'solutionsArchitect',
  SysOpsAdministrator = 'sysOpsAdministrator',
  DevOpsEngineer = 'devOpsEngineer',
  AdvancedNetworking = 'advancedNetworking',
  DataAnalytics = 'dataAnalytics',
  Database = 'database',
  MachineLearning = 'machineLearning',
  Security = 'security',
}
