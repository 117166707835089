import { FC } from 'react';
import { Moment } from 'moment';
import { useFormik } from 'formik';
import * as yup from 'yup';
import {
  Stack,
  Typography,
  TextField,
  Box,
  Button,
  useTheme,
} from '@mui/material';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';
import { MeetingLocation, MEETING_LOCATIONS } from '../../constants';
import { MeetingLocationSelection } from '.';

export interface MeetingCreationFormValues {
  startTime: string | null;
  name: string;
  email: string;
  meetingLocation: MeetingLocation | null;
}

export const MEETING_CREATION_FORM_SCHEMA = yup.object({
  startTime: yup.string().required(),
  name: yup.string().required(),
  email: yup.string().email().required(),
  meetingLocation: yup.string().oneOf(Array.from(MEETING_LOCATIONS)).required(),
});

export const MeetingCreationForm: FC<{
  initialValues?: Partial<MeetingCreationFormValues>;
  onSubmit: (values: MeetingCreationFormValues) => void;
}> = (props) => {
  const theme = useTheme();
  const formik = useFormik<MeetingCreationFormValues>({
    initialValues: {
      startTime: null,
      name: '',
      email: '',
      meetingLocation: null,
      ...props.initialValues,
    },
    onSubmit: (values, formikHelpers) => {
      props.onSubmit(values);
    },
  });

  return (
    <form onSubmit={formik.handleSubmit}>
      <Stack
        direction="column"
        spacing={2}
        sx={{ margin: theme.spacing(2, 0) }}
      >
        <Typography variant="body2">Your Information</Typography>
        <TextField
          fullWidth
          variant="filled"
          label="Name"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
        />
        <TextField
          fullWidth
          variant="filled"
          type="email"
          label="Email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
        />
        <Typography variant="body2">How We'll Meet</Typography>
        <Box component="div">
          <MeetingLocationSelection
            value={formik.values.meetingLocation}
            onChange={(meetingLocation) => {
              formik.setFieldValue('meetingLocation', meetingLocation);
            }}
          />
        </Box>
        <Button
          type="submit"
          fullWidth
          disableElevation
          variant="contained"
          color="secondary"
          startIcon={<OpenInNewOutlinedIcon />}
        >
          Schedule on Calendly
        </Button>
      </Stack>
    </form>
  );
};
