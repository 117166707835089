import { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { Container } from '@mui/material';
import { LayoutAnchor, PageFooter } from '../..';
import { ReferencePageIntroductionSection, ReferencePageThree } from '.';

import './ReferencePage.scss';

export const ReferencePage: FC = (props) => {
  return (
    <>
      <ReferencePageIntroductionSection />
      <ReferencePageThree />
      <LayoutAnchor
        id="referencePage_container_left"
        style={{ position: 'absolute', left: 0 }}
        x
      />
      <LayoutAnchor
        id="referencePage_container_right"
        style={{ position: 'absolute', right: 0 }}
        x
      />
      <Outlet />
      <Container maxWidth="lg">
        <PageFooter />
      </Container>
    </>
  );
};
