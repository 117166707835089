import { forwardRef } from 'react';
import moment from 'moment';
import {
  Box,
  Divider,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';
import {
  BRAND_COLOR_YELLOW_DARK,
  BRAND_COLOR_YELLOW_LIGHT,
  MeetingLocation,
  MEETING_LOCATION_LABELS,
  VIDEO_MEETING_LOCATIONS,
  PHONE_MEETING_LOCATIONS,
} from '../../constants';
import { MeetingCreationFormValues, MeetingDetailsList } from '.';

export const MeetingConfirmation = forwardRef<
  HTMLDivElement,
  {
    active: boolean;
    formValues: MeetingCreationFormValues | null;
    sx?: SxProps<Theme>;
  }
>(({ formValues, active, sx }, ref) => {
  const theme = useTheme();
  let meetingLocationText = 'a way for us to get in touch';

  if (formValues?.meetingLocation) {
    const meetingLocationLabel = MEETING_LOCATION_LABELS.get(
      formValues.meetingLocation
    );

    if (VIDEO_MEETING_LOCATIONS.has(formValues.meetingLocation)) {
      meetingLocationText = `a ${meetingLocationLabel} link for our meeting`;
    } else if (PHONE_MEETING_LOCATIONS.has(formValues.meetingLocation)) {
      meetingLocationText = 'my phone number so you can call me';
    }
  }

  const startTime = formValues?.startTime ? moment(formValues.startTime) : null;

  return (
    <Box
      ref={ref}
      component="div"
      sx={[
        {
          display: 'flex',
          flexDirection: 'column',
          position: 'absolute',
          left: 0,
          top: 0,
          minHeight: '100%',
          width: '100%',
          padding: 4,
          background: `linear-gradient(135deg, ${BRAND_COLOR_YELLOW_DARK} 0%, ${BRAND_COLOR_YELLOW_LIGHT} 100%)`,
          transition: theme.transitions.create('opacity'),
          ...(active
            ? {
                opacity: 1,
                pointerEvents: 'auto',
              }
            : {
                opacity: 0,
                pointerEvents: 'none',
              }),
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Box
        component="div"
        sx={{
          flexGrow: 1,
          [theme.breakpoints.up('md')]: {
            paddingTop: '28px',
          },
        }}
      >
        <Typography variant="h1">We're on!</Typography>
        <Typography variant="body1">
          Thanks for booking an introduction with me. Calendly will send you a
          confirmation email, and I'll follow up with {meetingLocationText}.
        </Typography>
        <br />
        <Typography variant="body1">- Sam</Typography>
      </Box>
      <Divider
        sx={{
          margin: theme.spacing(2, 0),
        }}
      />
      {startTime && (
        <MeetingDetailsList
          active={active}
          localeDate={startTime}
          startTime={startTime}
          meetingLocation={formValues?.meetingLocation ?? undefined}
        />
      )}
    </Box>
  );
});
