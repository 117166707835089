import { createContext } from 'react';

export interface ButtonSelectionContextType {
  value: string | null;
  onChange: (value: string | null) => void;
}

const ButtonSelectionContext = createContext<ButtonSelectionContextType>({
  value: null,
  onChange: () => {},
});
ButtonSelectionContext.displayName = 'ButtonSelectionContext';

export { ButtonSelectionContext };
