import { FC } from 'react';
import { TransdimensionalUnitConversionServiceContext } from '../../../contexts';
import { useWindowSize } from '../../../hooks';
import {
  PageHeaderThree,
  Three,
  ThreeLighting,
  ThreeScrollOffsetContainer,
  TransdimensionalTableThree,
  TransdimensionlTableVariant,
} from '../..';

export const StatusPageThree: FC = () => {
  return (
    <Three
      id="status_page_three"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
      }}
    >
      <ThreeLighting />
      <ThreeScrollOffsetContainer>
        <TransdimensionalUnitConversionServiceContext.Consumer>
          {(transdimensionalUnitConversionService) => {
            if (!transdimensionalUnitConversionService) return null;

            const sceneWidth =
              transdimensionalUnitConversionService.getVisibleWidthAtDepth(0);
            const sceneHeight =
              transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);
            const sceneDepth = 0.5;

            return (
              <>
                <PageHeaderThree />
                <TransdimensionalTableThree
                  variant={TransdimensionlTableVariant.RowContained}
                  id="statusPage_indexSection-table"
                  rowIds={
                    new Set([
                      '@local/homebridge',
                      '@local/airblock',
                      '@local/docker',
                      '@local/proxy',
                      '@local/homebridge-multibridge',
                      '@local/homebridge-camera-ui',
                      '@local/homebridge-zing',
                      '@local/samba',
                    ])
                  }
                />
              </>
            );
          }}
        </TransdimensionalUnitConversionServiceContext.Consumer>
      </ThreeScrollOffsetContainer>
    </Three>
  );
};

export default StatusPageThree;
