import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import {
  Theme as MuiTheme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material';

export const ThemeProvider: FC<
  PropsWithChildren<{
    theme: MuiTheme;
  }>
> = ({ theme, children }) => {
  return (
    <MuiThemeProvider theme={theme}>
      <div
        className={classNames('ThemeProvider-root', {
          'ThemeProvider-root_light': theme.palette.mode === 'light',
          'ThemeProvider-root_dark': theme.palette.mode === 'dark',
        })}
        style={{
          color: theme.palette.text.primary,
        }}
        data-mui-color-scheme={theme.palette.mode}
      >
        {children}
      </div>
    </MuiThemeProvider>
  );
};
