import React from 'react';
import { RouteObject, createMemoryRouter } from 'react-router';
import { createBrowserRouter } from 'react-router-dom';

import {
  MainPage,
  MeetPage,
  StatusPage,
  BookmarksPage,
  ReferencePage,
  ReferenceOverviewPage,
  ReferenceTermsPage,
  ReferenceTermPage,
  ReferenceSchemaPage,
  ReferenceBrandPage,
  ReferenceProjectsPage,
  ReferenceInfrastructurePage,
  Reference3DAssetsPage,
  ReferenceHomeAccessoriesPage,
} from '../pages';
import { ErrorPage } from '../pages/error-page';
import { ResumeDocument } from '../documents';

export const APPLICATION_ROUTES = [
  {
    path: '/status',
    element: <StatusPage />,
  },
  {
    path: '/bookmarks',
    element: <BookmarksPage />,
  },
  {
    path: '/meet',
    element: <MeetPage />,
  },
  {
    path: '/reference',
    element: <ReferencePage />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: 'terms',
        children: [
          {
            path: ':termId',
            element: <ReferenceTermPage />,
          },
          {
            path: '',
            element: <ReferenceTermsPage />,
          },
        ],
      },
      {
        path: 'schemas',
        children: [
          {
            path: ':schemaId',
            element: <ReferenceSchemaPage />,
          },
          {
            path: '',
            element: <ReferenceTermsPage />,
          },
        ],
      },
      {
        path: 'brand',
        element: <ReferenceBrandPage />,
      },
      {
        path: 'projects',
        element: <ReferenceProjectsPage />,
      },
      {
        path: 'infrastructure',
        element: <ReferenceInfrastructurePage />,
      },
      {
        path: '3d-assets',
        element: <Reference3DAssetsPage />,
      },
      {
        path: 'home-accessories',
        element: <ReferenceHomeAccessoriesPage />,
      },
      {
        path: '',
        element: <ReferenceOverviewPage />,
      },
      {
        path: '*',
      },
    ],
  },
  {
    path: '/documents',
    children: [
      {
        path: 'resume',
        element: <ResumeDocument />,
      },
    ],
  },
  {
    path: '/',
    element: <MainPage />,
  },
  {
    path: '*',
    errorElement: <ErrorPage />,
  },
] as Array<RouteObject>;

const createRouter = () => {
  if (process.env.REACT_APP_ROUTER_PATH) {
    return createMemoryRouter(APPLICATION_ROUTES, {
      initialEntries: [process.env.REACT_APP_ROUTER_PATH],
      initialIndex: 1,
    });
  }

  return createBrowserRouter(APPLICATION_ROUTES);
};

export const router = createRouter();
