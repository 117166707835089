import { FC, useContext } from 'react';
import { useTheme } from '@mui/material';
import { THREE_FULL_WIDTH, THREE_FULL_DEPTH } from '../../../../constants';
import { TransdimensionalUnitConversionServiceContext } from '../../../../contexts';
import {
  Three,
  ThreeQuad,
  ThreeBox,
  ThreeModel,
  ThreeTransdimensionalRect,
  ThreeScrollOffsetContainer,
  ChipThree,
  ThreeLighting,
} from '../../..';

const MainPageProjectsSectionThreeInner: FC = () => {
  const theme = useTheme();
  const transdimensionalUnitConversionService = useContext(
    TransdimensionalUnitConversionServiceContext
  );

  if (!transdimensionalUnitConversionService) return null;

  const sceneHeight =
    transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);

  return (
    <>
      <ThreeLighting />
      <ThreeScrollOffsetContainer>
        {/* Ceiling */}
        <ThreeQuad
          position={[0, 0, -THREE_FULL_DEPTH / 2]}
          scale={[THREE_FULL_WIDTH, THREE_FULL_DEPTH, 1]}
          rotation={[Math.PI / 2, 0, 0]}
        />

        {/* Floor */}
        <ThreeQuad
          position={[0, -sceneHeight, -THREE_FULL_DEPTH / 2]}
          scale={[THREE_FULL_WIDTH, THREE_FULL_DEPTH, 1]}
          rotation={[-Math.PI / 2, 0, 0]}
        />

        <ThreeTransdimensionalRect id="projects-reference-panel">
          <ThreeBox
            position={[0, 0, -theme.depth.card / 2]}
            scale={[1, 1, theme.depth.card]}
          />
        </ThreeTransdimensionalRect>
        <ChipThree
          id="mainPage_projectsSection_referenceChip"
          depthOffset="foreground"
        />
      </ThreeScrollOffsetContainer>
    </>
  );
};

export const MainPageProjectsSectionThree: FC = () => {
  return (
    <Three
      id="projects_three"
      className="ProjectsThree"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <MainPageProjectsSectionThreeInner />
    </Three>
  );
};
