import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { SxProps, Box, Theme } from '@mui/material';

import './NoWrap.scss';

export const NoWrap: FC<
  PropsWithChildren<{ sx?: SxProps<Theme>; className?: string }>
> = (props) => {
  return (
    <Box
      component="span"
      sx={[
        {
          display: 'inline-block',
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      className={classNames('NoWrap-root', props.className)}
    >
      {props.children}
    </Box>
  );
};
