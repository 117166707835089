import { Color } from 'three';

import {
  BoxColorCoercible,
  BoxCornerColors,
  RectCornerColors,
} from '../../types';
import {
  colorToRectCornerColors,
  getBoxCornerColorsFromFrontAndBackRects,
  getGradientRectColors,
  rectToBoxCornerColors,
  toColor,
} from '.';
import { tokenizeCssvalue } from '..';

const isGradient = (value: string) => {
  return (
    value.startsWith('linear-gradient(') || value.startsWith('radial-gradient(')
  );
};

export const toBoxCornerColors = (
  meshColor: BoxColorCoercible,
  aspectRatio: number = 1
) => {
  if (!meshColor) {
    meshColor = new Color(1, 1, 1);
  } else if (Array.isArray(meshColor) && meshColor.length === 3) {
    meshColor = new Color(meshColor[0], meshColor[1], meshColor[2]);
  }

  if (meshColor instanceof Color) {
    meshColor = colorToRectCornerColors(meshColor.clone());
  }

  if (Array.isArray(meshColor) && meshColor.length === 2) {
    meshColor = getBoxCornerColorsFromFrontAndBackRects(
      meshColor[0],
      meshColor[1]
    );
  } else if (typeof meshColor === 'string') {
    const meshColorString = meshColor.replace(
      /( repeat scroll 0% 0% \/ auto padding-box border-box)$/,
      ''
    );
    const colorTokens = tokenizeCssvalue(meshColorString);

    if (!colorTokens) {
      throw TypeError('bad color string, must be linear-gradient');
    }

    let rectCornerColors: RectCornerColors;

    if (colorTokens.length === 1) {
      const colorToken = colorTokens[0];

      if (isGradient(colorToken)) {
        rectCornerColors = getGradientRectColors(colorToken, aspectRatio);
      } else {
        rectCornerColors = colorToRectCornerColors(toColor(colorToken));
      }
    } else if (colorTokens.length === 2) {
      const [backgroundColorString, backgroundImageString] = colorTokens;
      const backgroundColor = toColor(
        backgroundColorString
      ).convertLinearToSRGB();

      if (isGradient(backgroundImageString)) {
        rectCornerColors = getGradientRectColors(
          backgroundImageString,
          aspectRatio,
          backgroundColor
        );
      } else {
        rectCornerColors = colorToRectCornerColors(backgroundColor);
      }
    }

    return rectToBoxCornerColors(rectCornerColors!);
  }

  if (meshColor.hasOwnProperty('topRight')) {
    meshColor = rectToBoxCornerColors(meshColor as RectCornerColors);
  }

  return meshColor as BoxCornerColors;
};
