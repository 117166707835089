// Constants
import { PERSISTENCE_KEY_DEBUG_MODE } from '../../constants';

export const getPersistedDebugMode = (): boolean => {
  const debugModeRaw = localStorage.getItem(PERSISTENCE_KEY_DEBUG_MODE) || '';

  try {
    const debugMode = JSON.parse(debugModeRaw);

    return debugMode;
  } catch (error) {
    console.log(
      'Debug mode localStorage value could not be parsed, not enabling.'
    );
  }

  return false;
};
