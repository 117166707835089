import { FC } from 'react';
import { Link } from 'react-router-dom';
import { Box, Container, Grid, Typography, useTheme } from '@mui/material';
import { Breadcrumbs, Section, TransdimensionalRect } from '../../..';
import { ReferenceTermsPageThree } from '.';

export const ReferenceTermsPage: FC = () => {
  const theme = useTheme();

  return (
    <Section
      id="referenceTermsPage_indexSection"
      three={<ReferenceTermsPageThree />}
    >
      <Container maxWidth="lg">
        <Breadcrumbs
          variant="pageHeader"
          prefixBrandCircle
          items={[
            {
              to: '/reference',
              text: 'Reference',
            },
            {
              to: '/reference/terms',
              text: 'Terms',
            },
          ]}
        />
      </Container>
    </Section>
  );
};
