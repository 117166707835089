import {
  Children,
  FC,
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { useDispatch } from 'react-redux';
import { Grid, useTheme } from '@mui/material';
import { BreakpointsMap, GridItemConfiguration } from '../../types';
import {
  setTransdimensionalGridContainerConfiguration,
  setTransdimensionalGridItemSize,
} from '../../actions';
import { useBreakpointsMap, useSpacing } from '../../hooks';
import { getGridItemSize } from '../../utilities';
import { TransdimensionalRect } from '..';

interface TransdimensionalGridItemDelegate {
  gridId: string;
  childIndex: number;
  breakpoints: BreakpointsMap;
  log: boolean;
}

const DEFAULT_GRID_ITEM_DELEGATE: TransdimensionalGridItemDelegate = {
  gridId: '',
  childIndex: -1,
  breakpoints: new Map(),
  log: false,
};

const TransdimensionalGridItemContext =
  createContext<TransdimensionalGridItemDelegate>(DEFAULT_GRID_ITEM_DELEGATE);

export const TransdimensionalGridContainer: FC<
  PropsWithChildren<{
    id: string;
    spacing: number;
    log?: boolean;
  }>
> = (props) => {
  const gridId = props.id;
  const dispatch = useDispatch();
  const offsetTop = useSpacing(props.spacing);
  const breakpoints = useBreakpointsMap();
  const gridContainerConfiguration = useMemo(
    () => ({ spacing: props.spacing }),
    [props.spacing]
  );

  useEffect(() => {
    dispatch(
      setTransdimensionalGridContainerConfiguration({
        gridId,
        gridContainerConfiguration,
      })
    );
  }, [dispatch, gridId, gridContainerConfiguration]);

  return (
    <TransdimensionalRect id={gridId} display="block" offsetTop={offsetTop}>
      <Grid
        container
        spacing={props.spacing}
        ref={(containerElement) => {
          console.log('got container element', containerElement);
        }}
      >
        {Children.map(props.children, (child, childIndex) => {
          return (
            <TransdimensionalGridItemContext.Provider
              value={{
                gridId,
                childIndex,
                breakpoints,
                log: !!props.log,
              }}
            >
              {child}
            </TransdimensionalGridItemContext.Provider>
          );
        })}
      </Grid>
    </TransdimensionalRect>
  );
};

export const TransdimensionalGridItem: FC<
  PropsWithChildren<{} & GridItemConfiguration>
> = (props) => {
  const dispatch = useDispatch();
  const { breakpoints, gridId, childIndex } = useContext(
    TransdimensionalGridItemContext
  );
  const gridItemSize = getGridItemSize(breakpoints, props);

  useEffect(() => {
    dispatch(
      setTransdimensionalGridItemSize({
        gridId,
        childIndex,
        gridItemSize,
      })
    );
  }, [dispatch, gridId, childIndex, gridItemSize]);

  return (
    <Grid
      item
      xs={props.xs}
      sm={props.sm}
      md={props.md}
      lg={props.lg}
      xl={props.xl}
      ref={(itemElement) => {
        console.log('got item element', childIndex, itemElement);
      }}
      sx={{
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      {props.children}
    </Grid>
  );
};
