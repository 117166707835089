// Redux
import { createSelector } from '@reduxjs/toolkit';

// Types
import { RootState } from '../store';

export const selectThreeModels = (state: RootState) => state.threeModels;

const createThreeModelSelector = (id: string) =>
  createSelector(
    selectThreeModels,
    (threeModelsState) => threeModelsState.threeModelsById[id]
  );

const threeModelSelectorsById = new Map<
  string /* model ID */,
  ReturnType<typeof createThreeModelSelector>
>();

export const getOrCreateThreeModelSelector = (id: string) => {
  let selector = threeModelSelectorsById.get(id);

  if (!selector) {
    selector = createThreeModelSelector(id);

    threeModelSelectorsById.set(id, selector);
  }

  return selector;
};
