import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { getPersistedDebugMode } from '../utilities';

export type DebugState = {
  debugMode: boolean;
};

const INITIAL_STATE: DebugState = {
  debugMode: getPersistedDebugMode(),
};

export const debugSlice = createSlice({
  name: 'debug',
  initialState: INITIAL_STATE,
  reducers: {
    setDebugMode: (state, action: PayloadAction<boolean>) => {
      state.debugMode = action.payload;
    },
  },
});
