import { FC } from 'react';
import classNames from 'classnames';
import CSS from 'csstype';
import { getColorFilter } from '../../utilities';

import './Icon.scss';

export const Icon: FC<{
  className?: string;
  style?: CSS.StandardProperties;
  color?: string;
  url: String;
}> = ({ className, style, url, ...props }) => {
  const colorFilter = props.color ? getColorFilter(props.color) : null;

  return (
    <i
      className={classNames('Icon', className)}
      style={{
        ...style,
        filter: `${colorFilter ?? ''} ${style?.filter ?? ''}`,
        backgroundImage: `url(${url})`,
      }}
    />
  );
};
