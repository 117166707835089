import { Vector3 } from 'three';

import { Vector3Coercible } from '../../types';

export const toVector3 = (value: Vector3Coercible): Vector3 => {
  if (typeof value === 'number') {
    return new Vector3(value, value, value);
  } else if (Array.isArray(value)) {
    return new Vector3(value[0], value[1], value[2]);
  } else if (value instanceof Vector3) {
    return value;
  } else {
    return new Vector3();
  }
};
