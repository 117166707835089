import { FC } from 'react';
import { Euler, Quaternion, Vector3 } from 'three';
import { Canvas, useFrame } from '@react-three/fiber';
import { Container, Typography, useTheme, Grid, Box } from '@mui/material';
import {
  Heading,
  DocumentContainer,
  ResumeDocument,
  Section,
  Three,
  ThreeBox,
  ThreeLighting,
  TransdimensionalRect,
} from '../../../..';
import { ReferenceBrandPageAssetsSectionThree } from '.';
import { OrthographicCamera } from '@react-three/drei';

export const ReferenceBrandPageAssetsSection: FC = () => {
  const theme = useTheme();
  const quaternion = new Quaternion();
  quaternion.multiply(
    new Quaternion().setFromAxisAngle(new Vector3(0, 1, 0), Math.PI / 4)
  );
  quaternion.multiply(
    new Quaternion().setFromAxisAngle(new Vector3(1, 0, 0), Math.PI / 8)
  );

  return (
    <Section
      id="assets-section"
      three={<ReferenceBrandPageAssetsSectionThree />}
    >
      <Heading variant="h2" id="assets">
        Assets
      </Heading>
      <Typography variant="body2">Logo</Typography>
      <br />
      <Typography variant="body2">Identity Circle</Typography>
      <br />
      <Typography variant="body2">Enforcer Cube</Typography>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Box
            component="div"
            sx={{
              position: 'relative',
              paddingTop: '100%',
              background:
                'repeating-conic-gradient(rgb(128, 128, 128) 0% 25%, rgb(192, 192, 192) 0% 50%) 50% / 24px 24px',
            }}
          >
            <Canvas
              frameloop="demand"
              style={{
                position: 'absolute',
                left: 0,
                top: 0,
              }}
            >
              <ambientLight />
              <directionalLight position={[3, 1, 2]} />
              <mesh position={[0, 0, 0]} scale={100}>
                <boxGeometry args={[1, 1, 1]} />
                <meshStandardMaterial />
              </mesh>
              <OrthographicCamera
                makeDefault
                zoom={1}
                top={200}
                bottom={-200}
                left={200}
                right={-200}
                near={1}
                far={2000}
                position={[50, 0, 50]}
                rotation={new Euler().setFromQuaternion(quaternion)}
                matrixAutoUpdate
              />
            </Canvas>
          </Box>
        </Grid>
      </Grid>
      <br />
      <Typography variant="body2">Resumé</Typography>
      <Box
        component="div"
        sx={{
          position: 'relative',
        }}
      >
        <ResumeDocument />
      </Box>
    </Section>
  );
};
