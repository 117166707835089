// THREE.js
import { Color } from 'three';

// Color utilities
import color from 'color';
import gradientParser, {
  ColorStop,
  AngularNode,
  DirectionalNode,
} from 'gradient-parser';

// Types
import { RectCornerColors } from '../../types';

// import { radToDeg } from "three/src/math/MathUtils";

const normalizeColorStopRatio = (colorStop: ColorStop) =>
  parseFloat(colorStop.length?.value || '') / 100;

const getColorStopColor = (colorStop: ColorStop) => {
  switch (colorStop.type) {
    case 'rgb':
      return (color as any).rgb(
        colorStop.value.map((component) => parseFloat(component))
      );
    case 'rgba':
      return (color as any).rgba(
        colorStop.value.map((component, index) => {
          if (index === 3) {
            return component !== undefined ? parseFloat(component) : 1;
          } else {
            return component !== undefined ? parseFloat(component) : 0;
          }
        })
      );
    case 'hex':
      return color('#' + colorStop.value);
    default:
      return color(colorStop.value);
  }
};

export const getGradientRectColors = (
  gradientString: string,
  aspect = 1,
  baseColor = new Color(0, 0, 0)
): RectCornerColors => {
  // gradientString =
  //   "linear-gradient(0deg, rgb(255, 0, 0) 0%, orange 50%, #FFFF00 100%)";

  // aspect = 1;

  const gradients = gradientParser.parse(gradientString);

  if (gradients.length === 0) {
    throw new Error(`Could not parse gradient string: '${gradientString}'`);
  } else if (gradients.length > 1) {
    console.warn(
      `Multiple gradients in string, only using first: '${gradientString}'`
    );
  }

  const gradient = gradients[0];
  const angle = (() => {
    if (!gradient.orientation) return 0;

    switch ((gradient.orientation as any).type) {
      case 'angular':
        const angularOrientation = gradient.orientation as AngularNode;
        const angleDegrees = parseFloat(
          angularOrientation.value.replace(/deg$/i, '')
        );

        return (angleDegrees / 360) * (2 * Math.PI);
      case 'directional':
        const directionalOrientation = gradient.orientation as DirectionalNode;

        switch (directionalOrientation.value) {
          case 'top':
            return 0;
          case 'right':
            return Math.PI / 2;
          case 'bottom':
            return Math.PI;
          case 'left':
            return Math.PI / 2;
        }
    }

    return 0;
  })();

  const aspectAngle = Math.atan(1 / aspect);
  // const offsetAngle = Math.PI / 2 - angle - aspectAngle;
  const rightAngle = angle - Math.PI / 2;
  const rightAspectAngle = -Math.PI / 2 + aspectAngle;
  const rightOffsetAngle = rightAngle - rightAspectAngle;
  const rectangleHypotenuse = Math.sqrt(Math.pow(1, 2) + Math.pow(aspect, 2));
  // const length = Math.cos(offsetAngle) * rectangleHypotenuse;
  const alternateCornerOffset =
    Math.sin(rightOffsetAngle) * rectangleHypotenuse;

  const topRightRatio = 1;
  const topLeftRatio = (1 - alternateCornerOffset) / 2;
  const bottomLeftRatio = 0;
  const bottomRightRatio = (1 + alternateCornerOffset) / 2;

  // console.log({
  //   length,
  //   angle: radToDeg(angle),
  //   aspectAngle: radToDeg(aspectAngle),
  //   offsetAngle: radToDeg(offsetAngle),
  //   rightAngle: radToDeg(rightAngle),
  //   rightAspectAngle: radToDeg(rightAspectAngle),
  //   rightOffsetAngle: radToDeg(rightOffsetAngle),
  // });

  const getColorAtRatio = (ratio: number) => {
    const { previousColorStop, nextColorStop } = gradient.colorStops.reduce(
      (current, colorStop) => {
        const colorStopRatio = normalizeColorStopRatio(colorStop);
        const colorStopRatioDifference = colorStopRatio - ratio;

        if (
          colorStopRatioDifference >= 0 &&
          colorStopRatioDifference <
            normalizeColorStopRatio(current.nextColorStop) - ratio
        ) {
          current.nextColorStop = colorStop;
        }

        if (
          colorStopRatioDifference <= 0 &&
          -colorStopRatioDifference <
            ratio - normalizeColorStopRatio(current.previousColorStop)
        ) {
          current.previousColorStop = colorStop;
        }

        return current;
      },
      {
        previousColorStop: gradient.colorStops[0],
        nextColorStop: gradient.colorStops[gradient.colorStops.length - 1],
      }
    );

    const colorStopRatio =
      previousColorStop === nextColorStop
        ? 0
        : (ratio - normalizeColorStopRatio(previousColorStop)) /
          (normalizeColorStopRatio(nextColorStop) -
            normalizeColorStopRatio(previousColorStop));

    const ratioColor = getColorStopColor(previousColorStop).mix(
      getColorStopColor(nextColorStop),
      colorStopRatio
    );

    const mixedColor = ratioColor
      .mix(
        (color as any).rgb(
          baseColor.r * 255,
          baseColor.g * 255,
          baseColor.b * 255
        ),
        1 - ratioColor.alpha()
      )
      .rgb()
      .array();

    // console.log({
    //   ratio,
    //   previousColorStop,
    //   nextColorStop,
    //   ratioColor,
    // });

    return new Color(
      mixedColor[0] / 255,
      mixedColor[1] / 255,
      mixedColor[2] / 255
    ).convertSRGBToLinear();
  };

  const result = {
    topLeft: getColorAtRatio(topLeftRatio),
    topRight: getColorAtRatio(topRightRatio),
    bottomLeft: getColorAtRatio(bottomLeftRatio),
    bottomRight: getColorAtRatio(bottomRightRatio),
  };

  // console.log(result);

  return result;
};
