export const COMPUTER_SCIENCE_COURSEWORK_ITEMS = [
  {
    id: 'computer-graphics',
    label: 'Computer Graphics (CS 559)',
  },
  {
    id: 'medical-image-analysis',
    label: 'Medical Image Analysis (BMI/CS 567)',
  },
  {
    id: 'operating-systems',
    label: 'Operating Systems (CS 537)',
  },
  {
    id: 'computer-networking',
    label: 'Computer Networking (CS 740)',
  },
  {
    id: 'database-management-systems',
    label: 'Database Management Systems (CS 564)',
  },
  {
    id: 'algorithms',
    label: 'Algorithms (CS 577)',
  },
];

export const PARAMEDIC_SKILL_ITEMS = [
  {
    id: 'airway-management',
    label: 'Airway Management',
  },
  {
    id: 'vascular-access-and-medications',
    label: 'IV & IO Vascular Access and Medications',
  },
  {
    id: 'patient-assessment',
    label: 'Patient Assessment',
  },
];
