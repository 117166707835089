import { FC } from 'react';
import { useTheme } from '@mui/material';
import {
  Three,
  ThreeBox,
  ThreeLighting,
  ThreeScrollOffsetContainer,
  ThreeTransdimensionalRect,
} from '../../..';

export const ReferenceInfrastructurePageThree: FC = () => {
  const theme = useTheme();

  return (
    <Three
      id="referenceInfrastructurePage-three"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeLighting />
      <ThreeScrollOffsetContainer>
        <ThreeTransdimensionalRect id="referencePage_indexSection_sidebar">
          <ThreeBox position={[0, 0, -0.1]} scale={[1, 1, 0.2]} />
        </ThreeTransdimensionalRect>
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
