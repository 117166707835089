import { PointColorCoercible } from '../../types';
import { toColor } from '..';

export const colorToCalendlyColor = (
  colorCoercible: PointColorCoercible
): string => {
  const threeColor = toColor(colorCoercible);
  const colorHex = threeColor.getHex().toString(16);

  return colorHex;
};
