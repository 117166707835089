import { FC } from 'react';
import { TransdimensionalUnitConversionServiceContext } from '../../../contexts';
import { useWindowSize } from '../../../hooks';
import {
  MeetingCreationThree,
  PageHeaderThree,
  Three,
  ThreeLighting,
  ThreeScrollOffsetContainer,
} from '../..';

export const MeetPageThree: FC = () => {
  const windowSize = useWindowSize();
  const width = window.innerWidth;
  const height = window.innerHeight;

  return (
    <Three
      id="reference_page_three"
      inset
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeScrollOffsetContainer>
        <ThreeLighting />
        <TransdimensionalUnitConversionServiceContext.Consumer>
          {(transdimensionalUnitConversionService) => {
            if (!transdimensionalUnitConversionService) return null;

            const sceneWidth =
              transdimensionalUnitConversionService.getVisibleWidthAtDepth(0);
            const sceneHeight =
              transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);
            const sceneDepth = 0.5;

            return (
              <>
                <PageHeaderThree />
                <MeetingCreationThree id="" />
              </>
            );
          }}
        </TransdimensionalUnitConversionServiceContext.Consumer>
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
