// React
import { createContext } from 'react';

// Statistics
import Stats from 'stats.js';

const ThreeStatisticsContext = createContext<Stats>(new Stats());
ThreeStatisticsContext.displayName = 'ThreeStatisticsContext';

export { ThreeStatisticsContext };
