import { FC, Fragment, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Button,
  ButtonGroup,
  Container,
  Grid,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import NewsFeedSharpIcon from '../../../@mui/icons-material/NewsFeedSharp';
import RowsSharpIcon from '../../../@mui/icons-material/RowsSharp';
import GridSharpIcon from '../../../@mui/icons-material/GridSharp';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import TodayOutlinedIcon from '@mui/icons-material/TodayOutlined';
import MedicalInformationOutlinedIcon from '@mui/icons-material/MedicalInformationOutlined';
import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined';
import VerifiedOutlinedIcon from '@mui/icons-material/VerifiedOutlined';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import {
  Breadcrumbs,
  Section,
  PageTitle,
  PageHeader,
  TransdimensionalButton,
  Heading,
} from '../..';
import { BookmarksPageThree } from '.';
import LegalOutlinedIcon from '../../../@mui/icons-material/LegalOutlined';
import CourtOutlinedIcon from '../../../@mui/icons-material/CourtOutlined';
import ParkingTicketOutlinedIcon from '../../../@mui/icons-material/ParkingTicketOutlined';
import PrisonOutlinedIcon from '../../../@mui/icons-material/PrisonOutlined';
import PoliceCarOutlinedIcon from '../../../@mui/icons-material/PoliceCarOutlined';
import PlaceMarkerOutlinedIcon from '../../../@mui/icons-material/PlaceMarkerOutlined';
import TreatmentListOutlinedIcon from '../../../@mui/icons-material/TreatmentListOutlined';
import PayCheckOutlinedIcon from '../../../@mui/icons-material/PayCheckOutlined';
import StethoscopeOutlinedIcon from '../../../@mui/icons-material/StethoscopeOutlined';
import BlackboardOutlinedIcon from '../../../@mui/icons-material/BlackboardOutlined';
import HeartWithPulseOutlinedIcon from '../../../@mui/icons-material/HeartWithPulseOutlined';
import GrapesOutlinedIcon from '../../../@mui/icons-material/GrapesOutlined';
import AuctionOutlinedIcon from '../../../@mui/icons-material/AuctionOutlined';
import IngredientsOutlinedIcon from '../../../@mui/icons-material/IngredientsOutlined';
import TShirtOutlinedIcon from '../../../@mui/icons-material/TShirtOutlined';
import BinaryCodeOutlinedIcon from '../../../@mui/icons-material/BinaryCodeOutlined';
import SphereOutlinedIcon from '../../../@mui/icons-material/SphereOutlined';
import Icons8OutlinedIcon from '../../../@mui/icons-material/Icons8Outlined';
import BankOutlinedIcon from '../../../@mui/icons-material/BankOutlined';
import YCoordinateOutlinedIcon from '../../../@mui/icons-material/YCoordinateOutlined';
import RedditOutlinedIcon from '../../../@mui/icons-material/RedditOutlined';
import YoutubeOutlinedIcon from '../../../@mui/icons-material/YoutubeOutlined';
import NetflixOutlinedIcon from '../../../@mui/icons-material/NetflixOutlined';
import VideoOutlinedIcon from '../../../@mui/icons-material/VideoOutlined';
import AppleOutlinedIcon from '../../../@mui/icons-material/AppleOutlined';
import TwitchOutlinedIcon from '../../../@mui/icons-material/TwitchOutlined';

export interface BookmarkItem {
  id: string;
  icon?: ReactNode;
  name: string;
  description: string;
  to?: string;
  background?: string;
  children?: BookmarkItem[];
}

export const BOOKMARK_ITEMS: BookmarkItem[] = [
  {
    id: 'entertainment',
    name: 'Entertainment',
    description: '',
    children: [
      {
        id: 'hacker-news',
        icon: <YCoordinateOutlinedIcon />,
        name: 'Hacker News',
        description: '',
        to: 'https://news.ycombinator.com',
        background:
          'linear-gradient(135deg, rgb(227, 91, 0) 0%, rgb(255, 109, 11) 100%)',
      },
      {
        id: 'reddit',
        icon: <RedditOutlinedIcon />,
        name: 'Reddit',
        description: '',
        to: 'http://old.reddit.com',
        background:
          'linear-gradient(135deg, rgb(254, 60, 38) 0%, rgb(254, 125, 49) 100%)',
      },
    ],
  },
  {
    id: 'media',
    name: 'Media',
    description: '',
    children: [
      {
        id: 'apple-tv-plus',
        icon: <AppleOutlinedIcon />,
        name: 'Apple TV+',
        description: '',
        to: 'https://tv.apple.com/',
      },
      {
        id: 'youtube',
        icon: <YoutubeOutlinedIcon />,
        name: 'YouTube',
        description: '',
        to: 'https://www.youtube.com/feed/subscriptions',
        background:
          'linear-gradient(135deg, rgb(196, 40, 47) 0%, rgb(227, 44, 45) 100%)',
      },
      {
        id: 'netflix',
        icon: <NetflixOutlinedIcon />,
        name: 'Netflix',
        description: '',
        to: 'https://netflix.com',
        background:
          'linear-gradient(135deg, rgb(185, 9, 11) 0%, rgb(229, 9, 20) 100%)',
      },
      {
        id: 'hulu',
        icon: <VideoOutlinedIcon />,
        name: 'Hulu',
        description: '',
        to: 'https://hulu.com',
        background:
          'linear-gradient(135deg, rgb(10, 223, 9) 0%, rgb(21, 191, 108) 100%)',
      },
      {
        id: 'twitch',
        icon: <TwitchOutlinedIcon />,
        name: 'Twitch',
        description: '',
        to: 'https://www.twitch.tv',
        background:
          'linear-gradient(135deg, rgb(224, 48, 223) 0%, rgb(162, 71, 249) 100%)',
      },
    ],
  },
  {
    id: 'shopping',
    name: 'Shopping',
    description: '',
    children: [
      {
        id: 'amazon',
        icon: <ShoppingCartOutlinedIcon />,
        name: 'Amazon',
        description: '',
        to: 'https://www.amazon.com/gp/css/order-history',
        background:
          'linear-gradient(135deg, rgb(240, 136, 4) 0%, rgb(255, 216, 20) 100%)',
      },
      {
        id: 'whole-foods',
        icon: <IngredientsOutlinedIcon />,
        name: 'Whole Foods',
        description: '',
        to: 'https://www.amazon.com/alm/storefront?almBrandId=VUZHIFdob2xlIEZvb2Rz',
        background:
          'linear-gradient(135deg, rgb(0, 78, 54) 0%, rgb(7, 111, 70) 100%)',
      },
      {
        id: 'ebay',
        icon: <AuctionOutlinedIcon />,
        name: 'eBay',
        description: '',
        to: 'https://www.ebay.com/mye/myebay/v2/purchase',
      },
      {
        id: 'nike-acg',
        icon: <TShirtOutlinedIcon />,
        name: 'Nike ACG',
        description: '',
        to: 'https://www.nike.com/acg',
      },
    ],
  },
  {
    id: 'finance',
    name: 'Finance',
    description: '',
    children: [
      {
        id: 'union-savings-bank',
        icon: <BankOutlinedIcon />,
        name: 'Union Savings Bank',
        description: '',
        to: 'https://www.unionsavings.com',
        background:
          'linear-gradient(135deg, rgb(0, 106, 114) 0%, rgb(120, 162, 47) 100%)',
      },
    ],
  },
  {
    id: 'utilities',
    name: 'Utilities',
    description: '',
    children: [
      {
        id: 'random-strings',
        icon: <BinaryCodeOutlinedIcon />,
        name: 'Random Strings',
        description: '',
        to: 'https://www.random.org/strings/?num=4&len=16&digits=on&upperalpha=on&loweralpha=on&unique=on&format=html&rnd=new',
      },
    ],
  },
  {
    id: 'design',
    name: 'Design',
    description: '',
    children: [
      {
        id: 'dribbble',
        icon: <SphereOutlinedIcon />,
        name: 'Dribbble',
        description: '',
        to: 'https://dribbble.com/shots/popular',
        background:
          'linear-gradient(135deg, rgb(195, 35, 97) 0%, rgb(234, 76, 137) 100%)',
      },
      {
        id: 'icons8',
        icon: <Icons8OutlinedIcon />,
        name: 'Icons8',
        description: '',
        to: 'https://icons8.com/',
        background:
          'linear-gradient(135deg, rgb(7, 145, 39) 0%, rgb(31, 177, 65) 100%)',
      },
    ],
  },
  {
    id: 'madison-college',
    name: 'Madison College',
    description: '',
    to: 'https://madisoncollege.edu',
    background:
      'linear-gradient(135deg, rgb(8, 48, 96) 0%, rgb(64, 160, 208) 100%)',
    children: [
      {
        id: 'madison-college-student-center',
        icon: <SchoolOutlinedIcon />,
        name: 'Student Center',
        description: '',
        to: 'https://students.madisoncollege.edu',
        background:
          'linear-gradient(135deg, rgb(8, 48, 96) 0%, rgb(64, 160, 208) 100%)',
      },
      {
        id: 'madison-college-blackboard',
        icon: <BlackboardOutlinedIcon />,
        name: 'Blackboard',
        description: '',
        to: 'https://blackboard.madisoncollege.edu/ultra/course',
      },
      {
        id: 'fisdap',
        icon: <StethoscopeOutlinedIcon />,
        name: 'FISDAP Login',
        description: '',
        to: 'https://members.fisdap.net/login',
      },
    ],
  },
  {
    id: 'cross-plains-ems',
    name: 'Cross Plains EMS',
    description: '',
    to: 'https://crossplainsems.com',
    background: '',
    children: [
      {
        id: 'cross-plains-ems-manager',
        icon: <TodayOutlinedIcon />,
        name: 'EMS Manager',
        description: '',
        to: 'https://secure4.aladtec.com/crossplains/',
        background:
          'linear-gradient(135deg, rgb(157, 119, 8) 0%, rgb(191, 179, 0) 100%)',
      },
      {
        id: 'dane-county-imagetrend-elite',
        icon: <TreatmentListOutlinedIcon />,
        name: 'ImageTrend Elite',
        description: '',
        to: 'https://danecounty.imagetrendelite.com/Elite/Organizationdanecounty/',
        background:
          'linear-gradient(135deg, rgb(148, 3, 5) 0%, rgb(240, 60, 41) 100%)',
      },
      {
        id: 'isolved-people-cloud',
        icon: <PayCheckOutlinedIcon />,
        name: 'isolved People Cloud',
        description: '',
        to: 'https://payrollcompany.myisolved.com/default.aspx',
        background:
          'linear-gradient(135deg, rgb(163, 14, 98) 0%, rgb(255, 56, 163) 100%)',
      },
    ],
  },
  {
    id: 'emergency-resources',
    name: 'Emergency Resources',
    description: '',
    background: '',
    children: [
      {
        id: 'dane-county-cad',
        icon: <PlaceMarkerOutlinedIcon />,
        name: 'Dane County CAD',
        description: '',
        to: 'http://psc911cad.countyofdane.com/VisiNetBrowser/Queues/ActiveQueue.aspx',
        background:
          'linear-gradient(135deg, rgb(0, 100, 206) 0%, rgb(113, 189, 255) 100%)',
      },
      {
        id: 'wisconsin-ems-elicensing',
        icon: <MedicalInformationOutlinedIcon />,
        name: 'WI EMS E-Licensing',
        description: '',
        to: 'https://www.wi-emss.org/lms/public/portal#/login',
      },
      {
        id: 'wisconsin-fire-certification-lookup',
        icon: <BadgeOutlinedIcon />,
        name: 'WI Fire Certification Lookup',
        description: '',
        to: 'https://apps.wtcsystem.edu/wtcs-web/audit/fireCertLookup.do',
      },
    ],
  },
  {
    id: 'legal-resources',
    name: 'Legal Resources',
    description: '',
    background: '',
    children: [
      {
        id: 'wcca-case-search',
        icon: <CourtOutlinedIcon />,
        name: 'WCCA Case Search',
        description: '',
        to: 'https://wcca.wicourts.gov/case.html',
        background:
          'linear-gradient(135deg, rgb(109, 89, 63) 0%, rgb(189, 133, 89) 100%)',
      },
      {
        id: 'wisconsin-court-livestream',
        icon: <LegalOutlinedIcon />,
        name: 'WI Court Livestream',
        description: '',
        to: 'https://www.wicourts.gov/courts/livestream.htm',
        background:
          'linear-gradient(135deg, rgb(109, 46, 77) 0%, rgb(169, 58, 108) 100%)',
      },
      {
        id: 'wisconsin-doc-offender-search',
        icon: <PrisonOutlinedIcon />,
        name: 'WI DOC Offender Search',
        description: '',
        to: 'https://appsdoc.wi.gov/lop/home/home',
        background:
          'linear-gradient(135deg, rgb(155, 44, 51) 0%, rgb(224, 75, 52) 100%)',
      },
      {
        id: 'wisconsin-license-search',
        icon: <VerifiedOutlinedIcon />,
        name: 'WI License Search',
        description: '',
        to: 'https://licensesearch.wi.gov',
      },
      {
        id: 'madison-parking-tickets',
        icon: <ParkingTicketOutlinedIcon />,
        name: 'Madison Parking Tickets',
        description: '',
        to: 'https://parkingtickets.cityofmadison.com/tickets/',
        background:
          'linear-gradient(135deg, rgb(3, 98, 107) 0%, rgb(91, 153, 159) 100%)',
      },
      {
        id: 'dane-county-jail-residents',
        icon: <PoliceCarOutlinedIcon />,
        name: 'Dane County Jail Residents',
        description: '',
        to: 'https://danesheriff.com/Residents',
        background:
          'linear-gradient(135deg, rgb(163, 94, 0) 0%, rgb(237, 153, 7) 100%)',
      },
    ],
  },
  {
    id: 'health',
    name: 'Health',
    description: '',
    children: [
      {
        id: 'uwhealth-mychart',
        icon: <HeartWithPulseOutlinedIcon />,
        name: 'UWHealth MyChart',
        description: '',
        to: 'https://mychart.uwhealth.org/',
        background:
          'linear-gradient(135deg, rgb(197, 5, 12) 0%, rgb(238, 40, 66) 100%)',
      },
    ],
  },
];

export const BookmarkItemLink: FC<{
  bookmarkItem: BookmarkItem;
}> = ({ bookmarkItem }) => {
  const theme = useTheme();
  const button = (
    <TransdimensionalButton
      id={bookmarkItem.id}
      sx={{
        width: '100%',
        textAlign: 'left',
        padding: 2,
      }}
    >
      <Stack direction="row" spacing={1} alignItems="start">
        <Box component="div" sx={{ margin: 0.5 }}>
          {bookmarkItem.icon}
        </Box>
        <Typography variant="body1" color="inherit" fontWeight="bold">
          {bookmarkItem.name}
        </Typography>
      </Stack>
    </TransdimensionalButton>
  );

  return (
    <Box
      component="div"
      sx={{
        color: theme.palette.text.primary,
        '&:hover': {
          color: 'white',
        },
      }}
    >
      {bookmarkItem.to ? <Link to={bookmarkItem.to}>{button}</Link> : button}
    </Box>
  );
};

export const BookmarksPage: FC = () => {
  const theme = useTheme();

  return (
    <>
      <PageTitle>Bookmarks</PageTitle>
      <Section
        id="bookmarksPage_introductionSection"
        three={<BookmarksPageThree />}
      >
        <Container maxWidth="lg">
          <PageHeader variant="search"></PageHeader>
          <Stack direction="row" spacing={2} alignItems="center">
            <Breadcrumbs
              variant="pageHeader"
              prefixBrandCircle
              items={[
                {
                  to: '/bookmarks',
                  text: 'Bookmarks',
                },
              ]}
            />
            <Box
              component="div"
              sx={{
                flexGrow: 1,
                textAlign: 'right',
              }}
            >
              <Tooltip placement="bottom-end" title="List View">
                <IconButton>
                  <NewsFeedSharpIcon />
                </IconButton>
              </Tooltip>
              <Tooltip placement="bottom-end" title="Grid View">
                <IconButton>
                  <GridSharpIcon />
                </IconButton>
              </Tooltip>
            </Box>
          </Stack>
          {BOOKMARK_ITEMS.map((rootBookmarkItem) => (
            <Fragment key={rootBookmarkItem.id}>
              <Heading
                variant="h4"
                id={rootBookmarkItem.id}
                sx={{ marginBottom: 2 }}
              >
                {rootBookmarkItem.name}
              </Heading>
              <Grid container spacing={2}>
                {rootBookmarkItem.children?.map((bookmarkItem) => (
                  <Grid key={bookmarkItem.id} item xs={12} sm={6} md={4} lg={3}>
                    <BookmarkItemLink bookmarkItem={bookmarkItem} />
                  </Grid>
                ))}
              </Grid>
              <br />
            </Fragment>
          ))}
        </Container>
        <br />
      </Section>
    </>
  );
};
