import { FC } from 'react';
import classNames from 'classnames';
import { DebugModeMask } from '..';

import './DebugLayoutAnchor.scss';

export const DebugLayoutAnchor: FC<{ id: string; x?: boolean; y?: boolean }> =
  ({ id, x, y }) => {
    return (
      <DebugModeMask>
        <div
          className={classNames('DebugLayoutAnchor', {
            'DebugLayoutAnchor-x': x,
            'DebugLayoutAnchor-y': y,
          })}
        >
          <span>{id}</span>
        </div>
      </DebugModeMask>
    );
  };
