import {
  FC,
  PropsWithChildren,
  MouseEventHandler,
  LegacyRef,
  useContext,
  MouseEvent,
  forwardRef,
  useEffect,
} from 'react';
import { SxProps, Theme } from '@mui/material';
import { TransdimensionalInteractionServiceContext } from '../../contexts';
import { ButtonBoxColorProfile, TransdimensionalRect } from '..';
import './TransdimensionalButton.scss';

export const TransdimensionalButton = forwardRef<
  HTMLElement,
  PropsWithChildren<{
    id: string;
    className?: string;
    disabled?: boolean;
    component?: 'button' | 'tr';
    color?: 'primary' | 'secondary';
    sx?: SxProps<Theme>;
    boxColorProfile?: ButtonBoxColorProfile;
    onClick?: MouseEventHandler<HTMLElement>;
  }>
>(
  (
    { id, component, color, sx, boxColorProfile, children, ...props },
    forwardedRef
  ) => {
    const disabled = props.disabled === true;
    const transdimensionalInteractionService = useContext(
      TransdimensionalInteractionServiceContext
    );

    const handleMouseEnter = (event: MouseEvent) => {
      if (!transdimensionalInteractionService) return;

      transdimensionalInteractionService.startInteractionHover(id);
    };
    const handleMouseLeave = (event: MouseEvent) => {
      if (!transdimensionalInteractionService) return;

      transdimensionalInteractionService.endInteractionHover(id);
    };
    const handleMouseMove = () => {};

    useEffect(() => {
      if (!transdimensionalInteractionService) return;

      transdimensionalInteractionService.setDisabled(id, disabled);
    }, [disabled]);

    return (
      <TransdimensionalRect
        ref={(element) => {
          if (forwardedRef instanceof Function) {
            forwardedRef(element as HTMLButtonElement);
          }
        }}
        id={id}
        component={component ?? 'button'}
        disabled={disabled}
        sx={[
          {
            width: '100%',
            border: 'none',
            background: 'transparent',
            '&:not(:disabled)': {
              cursor: 'pointer',
            },
          },
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
        onClick={props.onClick}
      >
        {children}
      </TransdimensionalRect>
    );
  }
);
