import { BREAKPOINTS } from '../../constants';
import { BreakpointsMap, GridItemConfiguration } from '../../types';

export const getGridItemSize = (
  breakpoints: BreakpointsMap,
  gridItemConfiguration: GridItemConfiguration
) => {
  return BREAKPOINTS.reduce((gridItemSize: number, breakpoint) => {
    if (breakpoints.get(breakpoint) && gridItemConfiguration[breakpoint]) {
      return gridItemConfiguration[breakpoint] as number;
    }

    return gridItemSize;
  }, 12);
};
