import { FC, PropsWithChildren } from 'react';
import { Box, IconButton, Typography } from '@mui/material';
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { MONTH_NAMES } from '../../constants';

export const MeetingCreationCalendar: FC<
  PropsWithChildren<{
    year: number;
    month: number;
    canNavigatePreviousMonth: boolean;
    canNavigateNextMonth: boolean;
    onNavigatePreviousMonth: () => void;
    onNavigateNextMonth: () => void;
  }>
> = (props) => {
  return (
    <Box
      component="div"
      sx={{
        position: 'relative',
      }}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: 1.5,
          paddingBottom: 1.5,
        }}
      >
        <IconButton
          edge="start"
          disabled={!props.canNavigatePreviousMonth}
          onClick={props.onNavigatePreviousMonth}
        >
          <ArrowBackOutlinedIcon fontSize="large" />
        </IconButton>
        <Box
          component="div"
          sx={{
            marginLeft: 2,
            marginRight: 2,
            flexGrow: 1,
            textAlign: 'center',
          }}
        >
          <Typography
            variant="body1"
            lineHeight="60px"
            fontSize="1.5rem"
            fontWeight="bold"
            sx={{
              display: 'inline-block',
            }}
          >
            {MONTH_NAMES[props.month]} {props.year}
          </Typography>
        </Box>
        <IconButton
          edge="end"
          disabled={!props.canNavigateNextMonth}
          onClick={props.onNavigateNextMonth}
        >
          <ArrowForwardOutlinedIcon fontSize="large" />
        </IconButton>
      </Box>
      {props.children}
    </Box>
  );
};
