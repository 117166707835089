import {
  FC,
  PropsWithChildren,
  ReactNode,
  useRef,
  useEffect,
  useLayoutEffect,
  useMemo,
} from 'react';
import { createMemoryHistory } from 'history';
import { useInView } from 'react-intersection-observer';
import { unstable_HistoryRouter as HistoryRouter } from 'react-router-dom';
import classNames from 'classnames';
import { Provider as ReduxProvider } from 'react-redux';
import * as THREE from 'three';
import { Vector2, Color, Fog, PerspectiveCamera } from 'three';
import { RootState as ThreeRootState, Canvas } from '@react-three/fiber';
import { useContextBridge } from '@react-three/drei';
import Stats from 'stats.js';
import { Box, SxProps, Theme, ThemeProvider, useTheme } from '@mui/material';
import {
  THREE_CAMERA_DEPTH,
  THREE_DEFAULT_FOV,
  THREE_DEFAULT_NEAR,
  THREE_DEFAULT_FAR,
  THREE_DEFAULT_FOG_NEAR,
  THREE_DEFAULT_FOG_FAR,
} from '../../constants';
import store from '../../store';
import { useWindowSize, useLayoutAnchor } from '../../hooks';
import { roundScalar, toColor } from '../../utilities';
import { TransdimensionalUnitConversionService } from '../../services';
import {
  TransdimensionalUnitConversionServiceContext,
  TransdimensionalInteractionServiceContext,
  ThreeStatisticsContext,
} from '../../contexts';
import {
  DebugThreeStatisticsUpdater,
  DebugThreeStatistics,
  LayoutAnchor,
} from '..';

import './Three.scss';

export interface ThreeLayer {
  zIndex: number;
  near: number;
  far: number;
}

const DEFAULT_LAYERS: ThreeLayer[] = [
  {
    zIndex: 0,
    near: THREE_DEFAULT_NEAR,
    far: THREE_DEFAULT_FAR,
  },
];

export const Three: FC<
  PropsWithChildren<{
    id: string;
    order?: number;
    pointerEvents?: boolean;
    frameloop?: 'always' | 'demand' | 'never';
    layers?: ThreeLayer[];
    near?: number;
    far?: number;
    fogNear?: number;
    fogFar?: number;
    inset?: boolean;
    interactive?: boolean;
    className?: string;
    sx?: SxProps<Theme>;
    fov?: number;
    fovScaling?: boolean;
    domChildren?: ReactNode;
  }>
> = ({
  id,
  order,
  pointerEvents,
  frameloop,
  near,
  far,
  fogNear,
  fogFar,
  inset,
  interactive,
  className,
  sx,
  children,
  ...props
}) => {
  const theme = useTheme();
  const windowSize = useWindowSize();
  const memoryHistory = useMemo(() => createMemoryHistory(), []);

  useLayoutEffect(() => {
    const handleLocationChange = () => {
      memoryHistory.replace(window.location);
    };

    window.addEventListener('locationchange', handleLocationChange);

    return () =>
      window.removeEventListener('locationchange', handleLocationChange);
  }, [memoryHistory]);

  // const { theme } = context;
  // const visibleHeight = transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);

  const contextsRef = useRef(new Set<ThreeRootState>());

  const updateFogColor = () => {
    const fogColor = toColor(
      theme.palette.background.clear
    ).convertLinearToSRGB();

    contextsRef.current.forEach((context) => {
      context.scene.fog = new Fog(
        fogColor,
        fogNear || THREE_DEFAULT_FOG_NEAR,
        fogFar || THREE_DEFAULT_FOG_FAR
      );
    });
  };

  useEffect(() => {
    updateFogColor();
  }, [theme.palette.background.clear]);

  // const canvasHeight = roundScalar(height || 0);
  // const fovCoefficient = height ? canvasHeight / height : originalFov;
  // const canvasWidth = roundScalar((width || 0) * fovCoefficient);
  // const scaledFov = TransdimensionalUnitConversionService.multiplyFieldOfView(
  //   originalFov,
  //   fovCoefficient
  // );

  const ContextBridge = useContextBridge(
    TransdimensionalInteractionServiceContext,
    ThreeStatisticsContext
  );

  const statisticsRef = useRef(new Stats());
  const statistics = statisticsRef.current;

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: `${windowSize.y / 4}px`,
  });

  const handleResize = () => {
    contextsRef.current.forEach((context) => {
      // context.gl.setSize(canvasWidth, canvasHeight);
      (context.camera as PerspectiveCamera).fov = scaledFov;
    });

    // if (canvasRef.current) {
    //   const canvas = canvasRef.current;
    //   canvas.style.width = canvasWidth + 'px';
    //   canvas.style.height = canvasHeight + 'px';
    // }
  };

  const topLeftAnchorId = id + '-topLeft';
  const topLeftAnchor = useLayoutAnchor(topLeftAnchorId);
  const bottomRightAnchorId = id + '-bottomRight';
  const bottomRightAnchor = useLayoutAnchor(bottomRightAnchorId);
  const canvasWidth = roundScalar(
    (bottomRightAnchor?.x || 0) - (topLeftAnchor?.x || 0)
  );
  const canvasHeight = roundScalar(
    (bottomRightAnchor?.y || 0) - (topLeftAnchor?.y || 0)
  );
  const heightRatio = (canvasHeight || 0) / windowSize.y;
  let scaledFov = props.fov || THREE_DEFAULT_FOV;

  if (props.fovScaling !== false) {
    scaledFov = TransdimensionalUnitConversionService.multiplyFieldOfView(
      scaledFov,
      heightRatio
    );
  }

  const shouldRender =
    canvasWidth && canvasHeight && topLeftAnchor && bottomRightAnchor;
  const transdimensionalUnitConversionService = shouldRender
    ? new TransdimensionalUnitConversionService(
        id,
        topLeftAnchor,
        windowSize,
        // new Vector2(width, height),
        new Vector2(canvasWidth, canvasHeight),
        new Vector2(canvasWidth, canvasHeight),
        scaledFov,
        1,
        THREE_CAMERA_DEPTH
      )
    : null;

  const layers = props.layers ?? DEFAULT_LAYERS;

  useEffect(() => {
    handleResize();
  }, [canvasWidth, canvasHeight, windowSize.x, windowSize.y, scaledFov]);

  return (
    <Box
      ref={ref}
      component="div"
      className={classNames('ThreeContainer', className, {
        'ThreeContainer-inset': inset,
      })}
      sx={[
        {
          position: 'relative',
          pointerEvents: interactive ? 'initial' : 'none',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <LayoutAnchor id={topLeftAnchorId} x y order={order} />
      <LayoutAnchor
        id={bottomRightAnchorId}
        x
        y
        order={order}
        style={{
          position: 'absolute',
          bottom: '0px',
          right: '0px',
        }}
      />
      {!!shouldRender && (
        <ThreeStatisticsContext.Provider value={statistics}>
          {layers.map((layer) => (
            <Canvas
              key={layer.zIndex}
              // ref={canvasRef}
              frameloop={frameloop ?? (inView ? 'always' : 'demand')}
              className={classNames('Three', {
                'Three-pointerEvents': !!pointerEvents,
              })}
              onCreated={(context) => {
                // context.gl.toneMapping = THREE.NoToneMapping;
                // context.gl.outputEncoding = THREE.sRGBEncoding;
                // context.gl.shadowMap.enabled = true;

                contextsRef.current.add(context);

                updateFogColor();

                context.gl.domElement.style.opacity = '1';

                handleResize();
              }}
              gl={{
                toneMapping: THREE.NoToneMapping,
                outputEncoding: THREE.sRGBEncoding,
              }}
              camera={{
                fov: scaledFov,
                position: [0, 0, THREE_CAMERA_DEPTH],
                rotation: [0, 0, 0],
                near: layer.near,
                far: layer.far,
              }}
              style={{
                position: 'absolute',
                zIndex: layer.zIndex,
                overflow: inset ? 'hidden' : 'visible',
                width: canvasWidth + 'px',
                height: canvasHeight + 'px',
                pointerEvents: 'inherit',
              }}
            >
              <ContextBridge>
                <ThemeProvider theme={theme}>
                  <ReduxProvider store={store}>
                    <ThreeStatisticsContext.Provider value={statistics}>
                      <TransdimensionalUnitConversionServiceContext.Provider
                        value={transdimensionalUnitConversionService}
                      >
                        <DebugThreeStatisticsUpdater />
                        {children}
                      </TransdimensionalUnitConversionServiceContext.Provider>
                    </ThreeStatisticsContext.Provider>
                  </ReduxProvider>
                </ThemeProvider>
              </ContextBridge>
            </Canvas>
          ))}
          {props.domChildren}
          <DebugThreeStatistics />
        </ThreeStatisticsContext.Provider>
      )}
    </Box>
  );
};
