import { FC } from 'react';
import { Typography } from '@mui/material';
import { ProjectCard, ProjectCardVariant } from '../..';

export const ProjectForeverdaunt: FC = () => {
  return (
    <ProjectCard variant={ProjectCardVariant.Large} color="rgb(32, 64, 84)">
      <Typography variant="body1">
        <b>Foreverdaunt:8Bit</b> is a retro-themed online multiplayer sandbox
        game.
      </Typography>
    </ProjectCard>
  );
};
