import { FC } from 'react';
import classNames from 'classnames';
import { Link, LinkProps } from 'react-router-dom';
import { Link as MuiLink, SxProps, Theme } from '@mui/material';

export interface InlineLinkProps extends LinkProps {
  sx?: SxProps<Theme>;
}

export const InlineLink: FC<InlineLinkProps> = (props) => {
  return (
    <MuiLink
      {...props}
      component={Link}
      className={classNames('InlineLink-root', props.className)}
      sx={[
        {
          textDecoration: 'underline',
          textDecorationStyle: 'dashed',
          '&:hover': {
            textDecorationStyle: 'solid',
          },
        },
      ]}
    />
  );
};
