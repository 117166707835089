import { FC, PropsWithChildren, useEffect, useRef } from 'react';
import { useTransdimensionalOffsetLink } from '../../hooks';
import { TransdimensionalOffsetOptions } from '../../types';

export const TransdimensionalOffset: FC<
  PropsWithChildren<
    TransdimensionalOffsetOptions & {
      display?: 'block' | 'inline-block';
    }
  >
> = (props) => {
  const { link, unlink } = useTransdimensionalOffsetLink({ z: props.z });

  useEffect(() => {
    return unlink;
  }, [unlink]);

  return (
    <div
      ref={(element) => link(element)}
      style={{
        display: props.display || 'inline-block',
      }}
    >
      {props.children}
    </div>
  );
};
