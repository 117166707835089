import { FC, PropsWithChildren, useState } from 'react';
import Measure from 'react-measure';
import { Box, SxProps, Theme } from '@mui/material';
import { DocumentSize } from '../../types';
import './DocumentContainer.scss';

export const DocumentContainer: FC<
  PropsWithChildren<{
    documentSize: DocumentSize;
    sx?: SxProps<Theme>;
  }>
> = ({ documentSize, sx, children }) => {
  const [containerMeasured, setContainerMeasured] = useState(false);
  const [containerWidth, setContainerWidth] = useState(0);
  const [containerHeight, setContainerHeight] = useState(0);

  const [documentMeasured, setDocumentMeasured] = useState(false);
  const [documentWidth, setDocumentWidth] = useState(0);
  const [documentHeight, setDocumentHeight] = useState(0);

  const measured = containerMeasured && documentMeasured;

  return (
    <Box
      component="div"
      className="DocumentContainer-root"
      sx={[
        {
          position: 'relative',
          width: '100%',
          aspectRatio: documentSize.aspectRatio,
          overflow: 'hidden',
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Measure
        bounds
        onResize={({ bounds }) => {
          if (!bounds) return;

          setContainerWidth(bounds.width);
          setContainerHeight(bounds.height);
          setContainerMeasured(true);
        }}
      >
        {({ measureRef: containerMeasureRef }) => (
          <Box
            ref={containerMeasureRef}
            component="div"
            className="DocumentContainer-transform"
            sx={{
              position: 'absolute',
              width: '100%',
              height: '100%',
              transformOrigin: 'top left',
              ...(measured && {
                transform: `scale(${containerWidth / documentWidth}, ${
                  containerHeight / documentHeight
                })`,
                '& .Three': {
                  transformOrigin: 'top left',
                  transform: `scale(${documentWidth / containerWidth}, ${
                    documentHeight / containerHeight
                  })`,
                },
              }),
            }}
          >
            <Measure
              bounds
              onResize={({ bounds }) => {
                if (!bounds) return;

                setDocumentWidth(bounds.width);
                setDocumentHeight(bounds.height);
                setDocumentMeasured(true);
              }}
            >
              {({ measureRef: documentMeasureRef }) => (
                <Box
                  ref={documentMeasureRef}
                  component="div"
                  sx={{
                    position: 'relative',
                    width: documentSize.width,
                    height: documentSize.height,
                    overflow: 'hidden',
                  }}
                >
                  {children}
                </Box>
              )}
            </Measure>
          </Box>
        )}
      </Measure>
    </Box>
  );
};
