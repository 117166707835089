import {
  FC,
  PropsWithChildren,
  MutableRefObject,
  useEffect,
  useContext,
  useRef,
} from 'react';
import { useSelector } from 'react-redux';
import { Group } from 'three';
import { TransdimensionalUnitConversionServiceContext } from '../../contexts';
import { getOrCreateTransdimensionalRectSelector } from '../../selectors';

export const ThreeTransdimensionalRect: FC<
  PropsWithChildren<{
    id: string;
    relative?: 'left' | 'right' | 'top' | 'bottom';
    width?: number;
    screenWidth?: number;
    screenHeight?: number;
    log?: boolean;
  }>
> = ({ id, children, log, ...props }) => {
  if (log) console.log('getting state for rect with id ', id);

  const groupRef = useRef<Group>();
  const rect = useSelector(getOrCreateTransdimensionalRectSelector(id));
  const transdimensionalUnitConversionService = useContext(
    TransdimensionalUnitConversionServiceContext
  );

  if (log) console.log('got rect for id ', id, rect);

  useEffect(() => {
    if (!transdimensionalUnitConversionService || !groupRef.current || !rect)
      return;

    const anchorPosition =
      transdimensionalUnitConversionService.getAnchorVector2();

    const relativeRect = {
      ...rect,
    };

    const setRelativeRectScreenWidth = (screenWidth: number) => {
      const originalWidth = rect.width;
      const relativeWidthChange = screenWidth - originalWidth;

      relativeRect.width = screenWidth;
      relativeRect.x = rect.x - relativeWidthChange / 2;
      relativeRect.left = rect.left - relativeWidthChange / 2;
      relativeRect.right = rect.right + relativeWidthChange / 2;
    };

    if (typeof props.width === 'number') {
      setRelativeRectScreenWidth(
        transdimensionalUnitConversionService.threeToClientX(props.width)
      );
    } else if (typeof props.screenWidth === 'number') {
      setRelativeRectScreenWidth(props.screenWidth);
    }

    if (typeof props.screenHeight === 'number') {
      const originalHeight = rect.height;
      const relativeHeightChange = props.screenHeight - originalHeight;

      relativeRect.height = props.screenHeight;
      relativeRect.y = rect.y - relativeHeightChange / 2;
      relativeRect.top = rect.top - relativeHeightChange / 2;
      relativeRect.bottom = rect.bottom + relativeHeightChange / 2;
    }

    if (props.relative === 'left') {
      const relativeHorizontalPositionChange = rect.left - relativeRect.left;

      relativeRect.left = rect.left;
      relativeRect.x = rect.x;
      relativeRect.right =
        relativeRect.right - relativeHorizontalPositionChange;
    } else if (props.relative === 'right') {
      const relativeHorizontalPositionChange = rect.right - relativeRect.right;

      relativeRect.right = rect.right;
      relativeRect.x = relativeRect.x + relativeHorizontalPositionChange;
      relativeRect.left = relativeRect.left + relativeHorizontalPositionChange;
    } else if (props.relative === 'bottom') {
      const relativeVerticalPositionChange = rect.bottom - relativeRect.bottom;

      relativeRect.bottom = rect.bottom;
      relativeRect.y = relativeRect.y + relativeVerticalPositionChange;
      relativeRect.top = relativeRect.top + relativeVerticalPositionChange;
    }

    groupRef.current.scale.set(
      transdimensionalUnitConversionService.clientToThreeX(relativeRect.width),
      transdimensionalUnitConversionService.clientToThreeY(relativeRect.height),
      1
    );
    groupRef.current.position.set(
      -transdimensionalUnitConversionService.clientToThreeX(
        transdimensionalUnitConversionService.getContainerWidth() / 2 -
          relativeRect.x -
          relativeRect.width / 2 +
          anchorPosition.x
      ),
      -transdimensionalUnitConversionService.clientToThreeY(
        relativeRect.y + relativeRect.height / 2 - anchorPosition.y
      ),
      0
    );

    if (log) {
      console.log('set group ref position x', groupRef.current.position.x);
    }
  }, [
    transdimensionalUnitConversionService,
    groupRef.current,
    rect,
    props.relative,
    props.screenWidth,
  ]);

  return (
    <group ref={groupRef as MutableRefObject<any>} scale={[0, 0, 0]}>
      {children}
    </group>
  );
};
