import { useEffect, useMemo, useRef } from 'react';
import { TransdimensionalRectOptions } from '../../types';
import { useTransdimensionalRect } from '.';

export const useTransdimensionalRectLink = (
  options: TransdimensionalRectOptions
): {
  link: (element: Element | null) => void;
  unlink: () => void;
} => {
  const linkedRef = useRef<boolean>(false);
  const elementRef = useRef<Element | null>(null);
  const { handleRect } = useTransdimensionalRect(options);
  const resizeObserver = useMemo(
    () =>
      new ResizeObserver((entries) => {
        for (const entry of entries) {
          const { target } = entry;
          const rect = target.getBoundingClientRect();

          handleRect(rect);
        }
      }),
    []
  );
  const windowResizeHandler = (event: any) => {
    const element = elementRef.current;

    if (!element) return;

    const rect = element.getBoundingClientRect();

    handleRect(rect);
  };

  return {
    link: (element: Element | null) => {
      if (!linkedRef.current) {
        linkedRef.current = true;

        window.addEventListener('resize', windowResizeHandler, {
          passive: true,
        });
      }

      if (element === elementRef.current) return;

      if (elementRef.current) {
        resizeObserver.unobserve(elementRef.current);
      }

      elementRef.current = element;

      if (element) {
        setTimeout(() => {
          resizeObserver.observe(element);
        }, 0);
      }
    },
    unlink: () => {
      linkedRef.current = false;

      window.removeEventListener('resize', windowResizeHandler);
      resizeObserver.disconnect();
    },
  };
};
