import {
  FC,
  PropsWithChildren,
  MutableRefObject,
  useRef,
  useEffect,
  useContext,
} from 'react';
import { Group } from 'three';
import { useThree } from '@react-three/fiber';
import { TransdimensionalUnitConversionServiceContext } from '../../contexts';
import { useWindowSize } from '../../hooks';

export const ThreeScrollOffsetContainer: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const transdimensionalUnitConversionService = useContext(
    TransdimensionalUnitConversionServiceContext
  );
  const { gl, camera } = useThree();
  const windowSize = useWindowSize();
  const groupRef = useRef<Group>();

  useEffect(() => {
    if (!transdimensionalUnitConversionService) return;

    const handleScroll = () => {
      if (!groupRef.current) return;

      const sceneCenter =
        transdimensionalUnitConversionService.getWindowCenterThree();
      const relativeScroll =
        transdimensionalUnitConversionService.getRelativeScroll();
      const visibleSize =
        transdimensionalUnitConversionService.getVisibleSizeAtDepth(0);
      const rendererSize =
        transdimensionalUnitConversionService.getRendererSize();

      groupRef.current.position.y = visibleSize.y / 2;

      camera.setViewOffset(
        rendererSize.x,
        rendererSize.y,
        -relativeScroll.x,
        -relativeScroll.y,
        rendererSize.x,
        rendererSize.y
      );
      camera.position.x = sceneCenter.x;
      camera.position.y = sceneCenter.y;
    };

    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [groupRef.current, windowSize.x, windowSize.y]);

  return <group ref={groupRef as MutableRefObject<any>}>{children}</group>;
};
