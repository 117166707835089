import { darken, lighten } from '@mui/material';
import {
  BRAND_COLOR_RED,
  BRAND_COLOR_ORANGE,
  BRAND_COLOR_YELLOW,
} from '../../constants';
import { Project } from '../../types';

export const PROJECT_SAMUELGOODELL_MULTILATERATION_WEB: Project = {
  id: '@samuelgoodell/multilateration-web',
  name: 'Multilateration',
  description:
    'Tool to determine a geographic location from multiple distance measurements',
  status: 'maintenance',
  chip: {
    background: `linear-gradient(135deg, ${BRAND_COLOR_RED} 0%, ${lighten(
      BRAND_COLOR_ORANGE,
      0.15
    )} 100%)`,
    color: 'rgb(255, 255, 255)',
    content: '@samuelgoodell/multilateration-web',
  },
  page: {
    tabs: [
      {
        label: 'Overview',
        page: {
          markdown: `\
_TODO_
`,
        },
      },
    ],
  },
};
