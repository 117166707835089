import { PropsWithChildren, forwardRef, useRef } from 'react';
import { Transition } from 'react-transition-group';
import { illusory } from 'illusory';
import { alpha, Box, SxProps, Theme, useTheme } from '@mui/material';
import { DialogContext } from './DialogContext';
import { delay } from '../../utilities';

type DialogOriginElementProp = HTMLElement | null | (() => HTMLElement | null);

export interface DialogBaseProps {
  open: boolean;
  originElement?: DialogOriginElementProp;
  originElementBackground?: string;
  persistOriginElement?: boolean;
  sx?: SxProps<Theme>;
  onClose?: () => void;
}

const getOriginElementFromProp = (
  originElement: DialogOriginElementProp | undefined
) => {
  if (originElement instanceof Function) {
    return originElement();
  }

  return originElement ?? null;
};

export const DialogBase = forwardRef<
  HTMLDivElement,
  PropsWithChildren<DialogBaseProps>
>((props, ref) => {
  const theme = useTheme();
  const elementRef = useRef<HTMLDivElement | null>(null);
  const persistedOriginElementRef = useRef<HTMLElement | null>(null);

  const getOriginElement = () => {
    const propOriginElement = getOriginElementFromProp(props.originElement);

    if (propOriginElement) {
      if (props.persistOriginElement) {
        persistedOriginElementRef.current = propOriginElement;
      }

      return propOriginElement;
    }

    if (props.persistOriginElement) return persistedOriginElementRef.current;

    return null;
  };

  const getValidOriginElement = () => {
    const originElement = getOriginElement();

    if (!originElement) return null;

    const originElementRect = originElement?.getBoundingClientRect();

    if (
      originElementRect.top > window.innerHeight ||
      originElementRect.bottom > window.innerHeight
    ) {
      return null;
    }

    return originElement;
  };

  return (
    <Transition
      nodeRef={elementRef}
      in={props.open}
      timeout={theme.transitions.duration.standard}
      appear
      mountOnEnter
      unmountOnExit
      onEnter={async () => {
        const dialogElement = elementRef.current;

        if (!dialogElement) return;

        const originElement = getValidOriginElement();

        if (originElement) {
          originElement.classList.add(
            'MorphTransition',
            'MorphTransition-enter'
          );
          dialogElement.style.visibility = 'visible';

          const { finished, cancel } = illusory(originElement, dialogElement, {
            duration: theme.transitions.duration.standard + 'ms',
            easing: theme.transitions.easing.easeOut,

            beforeAnimate: (from, to) => {
              if (props.originElementBackground) {
                from.setStyle('background', props.originElementBackground);
              }

              from.showNatural();
            },
            beforeDetach: (from, to) => {},
          });

          originElement.classList.add('MorphTransition-enter-active');
          dialogElement.style.visibility = 'hidden';

          await finished;

          originElement.classList.remove(
            'MorphTransition',
            'MorphTransition-enter',
            'MorphTransition-enter-active'
          );
          dialogElement.style.visibility = 'visible';
        } else {
          dialogElement.style.opacity = '1';
        }
      }}
      onExit={async () => {
        const dialogElement = elementRef.current;

        if (!dialogElement) return;

        const originElement = getValidOriginElement();

        if (originElement) {
          dialogElement.classList.add(
            'MorphTransition',
            'MorphTransition-exit'
          );
          dialogElement.style.visibility = 'visible';

          const { finished, cancel } = illusory(dialogElement, originElement, {
            duration: theme.transitions.duration.standard + 'ms',
            easing: theme.transitions.easing.easeOut,
            beforeAnimate: (from, to) => {
              if (props.originElementBackground) {
                to.setStyle('backgroundColor', props.originElementBackground);
              }

              to.showNatural();
            },
            beforeDetach: async (from, to) => {
              if (props.originElementBackground) {
                const duration = theme.transitions.duration.leavingScreen;

                to.setStyle(
                  'transition',
                  theme.transitions.create('background', { duration })
                );
                to.setStyle('background', to.natural.style.background);

                await delay(duration);
              }
            },
          });

          dialogElement.classList.add('MorphTransition-exit-active');
          dialogElement.style.visibility = 'hidden';

          await finished;

          dialogElement.classList.remove(
            'MorphTransition',
            'MorphTransition-exit',
            'MorphTransition-exit-active'
          );
          dialogElement.style.visibility = 'visible';
        } else {
          dialogElement.style.opacity = '0';
        }
      }}
    >
      <DialogContext.Provider
        value={{
          open: props.open,
          elementRef,
          onClose: () => props.onClose?.(),
        }}
      >
        <Box
          ref={(element: HTMLDivElement | null) => {
            if (elementRef instanceof Function) {
              elementRef(element);
            } else if (elementRef) {
              elementRef.current = element;
            }

            if (ref instanceof Function) {
              ref(element);
            } else if (ref) {
              ref.current = element;
            }
          }}
          component="div"
          sx={[
            {
              position: 'fixed',
              top: 0,
              height: '100dvh',
              overflowY: 'auto',
              backgroundColor: alpha(theme.palette.background.default, 0.875),
              backdropFilter: 'blur(5px)',
              visibility: 'hidden',
              zIndex: theme.zIndex.modal,
              transition: theme.transitions.create('opacity'),
            },
            ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
          ]}
        >
          {props.children}
        </Box>
      </DialogContext.Provider>
    </Transition>
  );
});
