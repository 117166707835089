import { FC, PropsWithChildren, ReactElement } from 'react';
import { useSelector } from 'react-redux';
import { selectDebugMode } from '../../../selectors';

export const DebugModeMask: FC<PropsWithChildren<{}>> = ({ children }) => {
  const debugMode = useSelector(selectDebugMode);

  if (!debugMode) return null;

  return children as ReactElement;
};
