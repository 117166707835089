import { FC } from 'react';
import { Container, Typography } from '@mui/material';
import { Heading, MeetingCreation, Section } from '../../..';
import { MainPageContactSectionThree } from '.';

export const MainPageContactSection: FC = () => {
  return (
    <Section id="section-contact" three={<MainPageContactSectionThree />}>
      <Container maxWidth="md">
        <Heading variant="h2" id="lets-chat">
          Let's Chat
        </Heading>
        <MeetingCreation />
      </Container>
      <br />
    </Section>
  );
};
