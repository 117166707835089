import { FC, useContext, useEffect, useState } from 'react';
import classNames from 'classnames';
import { MathUtils, Vector2 } from 'three';
import { useTheme } from '@mui/material';

import { LOGO_SVG_WIDTH, LOGO_SVG_HEIGHT } from '../../constants';
import { pointsToString, roundScalar } from '../../utilities';

import './Logo.scss';

export enum LogoMaskVariant {
  Contained = 'contained',
  Outlined = 'outlined',
}

export enum LogoFillVariant {
  Block = 'block',
  Gradient = 'gradient',
}

export const DEFAULT_LOGO_MASK_VARIANT = LogoMaskVariant.Contained;

// Component constants
const FILL_ANGLE_DEGREES = 15;
const FILL_ANGLE = MathUtils.degToRad(FILL_ANGLE_DEGREES);
const FILL_PADDING = LOGO_SVG_HEIGHT * Math.tan(FILL_ANGLE);
const FILL_WIDTH = LOGO_SVG_WIDTH + FILL_PADDING;

const FILL_COLOR_RATIO_STEP = 0.25;
const FILL_COLOR_COUNT = 3;
const FILL_COLOR_RATIO_PADDING = (FILL_COLOR_COUNT - 1) * FILL_COLOR_RATIO_STEP;
const FILL_COLOR_RATIO_RANGE = 1 + FILL_COLOR_RATIO_PADDING;

export interface LogoLine {
  from: Vector2;
  to: Vector2;
}

export const LOGO_LINE_STROKE_WIDTH = 6;
export const LOGO_LINES = new Set<LogoLine>([
  // Line across middle
  {
    from: new Vector2(3, 48),
    to: new Vector2(149, 48),
  },

  // S
  {
    from: new Vector2(32, 3),
    to: new Vector2(44, 3),
  },
  {
    from: new Vector2(14, 12),
    to: new Vector2(62, 12),
  },
  {
    from: new Vector2(8, 21),
    to: new Vector2(32, 21),
  },
  {
    from: new Vector2(44, 21),
    to: new Vector2(68, 21),
  },
  {
    from: new Vector2(8, 30),
    to: new Vector2(32, 30),
  },
  {
    from: new Vector2(52, 30),
    to: new Vector2(60, 30),
  },
  {
    from: new Vector2(17, 39),
    to: new Vector2(41, 39),
  },
  {
    from: new Vector2(35, 57),
    to: new Vector2(59, 57),
  },
  {
    from: new Vector2(16, 66),
    to: new Vector2(24, 66),
  },
  {
    from: new Vector2(44, 66),
    to: new Vector2(68, 66),
  },
  {
    from: new Vector2(8, 75),
    to: new Vector2(32, 75),
  },
  {
    from: new Vector2(44, 75),
    to: new Vector2(68, 75),
  },
  {
    from: new Vector2(14, 84),
    to: new Vector2(62, 84),
  },
  {
    from: new Vector2(32, 93),
    to: new Vector2(44, 93),
  },

  // G
  {
    from: new Vector2(104, 3),
    to: new Vector2(124, 3),
  },
  {
    from: new Vector2(90, 12),
    to: new Vector2(138, 12),
  },
  {
    from: new Vector2(84, 21),
    to: new Vector2(108, 21),
  },
  {
    from: new Vector2(120, 21),
    to: new Vector2(144, 21),
  },
  {
    from: new Vector2(84, 30),
    to: new Vector2(108, 30),
  },
  {
    from: new Vector2(128, 30),
    to: new Vector2(136, 30),
  },
  {
    from: new Vector2(84, 39),
    to: new Vector2(108, 39),
  },
  {
    from: new Vector2(84, 57),
    to: new Vector2(108, 57),
  },
  {
    from: new Vector2(120, 57),
    to: new Vector2(144, 57),
  },
  {
    from: new Vector2(84, 66),
    to: new Vector2(108, 66),
  },
  {
    from: new Vector2(120, 66),
    to: new Vector2(144, 66),
  },
  {
    from: new Vector2(84, 75),
    to: new Vector2(108, 75),
  },
  {
    from: new Vector2(120, 75),
    to: new Vector2(144, 75),
  },
  {
    from: new Vector2(90, 84),
    to: new Vector2(138, 84),
  },
  {
    from: new Vector2(104, 93),
    to: new Vector2(124, 93),
  },
]);

export const Logo: FC<{
  maskVariant?: LogoMaskVariant;
  fillVariant?: LogoFillVariant;
  height: number;
  className?: string;
}> = (props) => {
  const maskVariant = props.maskVariant ?? DEFAULT_LOGO_MASK_VARIANT;
  const defaultMaskId = `default-mask-${maskVariant}`;
  const theme = useTheme();
  const scale = props.height / LOGO_SVG_HEIGHT;

  const [fillRatio, setFillRatio] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setFillRatio(window.scrollY / LOGO_SVG_HEIGHT);
    };

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => window.removeEventListener('scroll', handleScroll);
  }, [setFillRatio]);

  const renderFillColorPolygon = (color: string, colorIndex: number) => {
    const colorFillRatio =
      (-FILL_COLOR_RATIO_PADDING +
        colorIndex * FILL_COLOR_RATIO_STEP +
        fillRatio * (FILL_COLOR_RATIO_RANGE + FILL_COLOR_RATIO_PADDING)) /
      FILL_COLOR_RATIO_RANGE;
    const fillOffset = colorFillRatio * FILL_WIDTH;

    return (
      <polygon
        points={pointsToString([
          new Vector2(-FILL_PADDING, LOGO_SVG_HEIGHT),
          new Vector2(0, 0),
          new Vector2(fillOffset, 0),
          new Vector2(fillOffset - FILL_PADDING, LOGO_SVG_HEIGHT),
        ])}
        fill={color}
        mask={`url(#${defaultMaskId})`}
      />
    );
  };

  return (
    <div
      className={classNames('Logo-container', props.className)}
      style={{
        transform: `scale(${scale})`,
        // transformOrigin: 'top left',
        // transformBox: 'fill-box',
        width: roundScalar(LOGO_SVG_WIDTH * scale),
        height: roundScalar(LOGO_SVG_HEIGHT * scale),
      }}
    >
      <svg
        width={LOGO_SVG_WIDTH}
        height={LOGO_SVG_HEIGHT}
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
      >
        <mask id={defaultMaskId}>
          {(() => {
            switch (maskVariant) {
              case LogoMaskVariant.Contained:
                return (
                  <g
                    stroke="white"
                    strokeLinecap="round"
                    strokeWidth={LOGO_LINE_STROKE_WIDTH}
                  >
                    {Array.from(LOGO_LINES).map((line, index) => (
                      <line
                        key={index}
                        x1={line.from.x}
                        y1={line.from.y}
                        x2={line.to.x}
                        y2={line.to.y}
                      />
                    ))}
                  </g>
                );
              case LogoMaskVariant.Outlined:
                return (
                  <g stroke="white" strokeLinecap="round" strokeWidth="2">
                    {Array.from(LOGO_LINES).map((line, index) => {
                      const lineWidth = line.to.x - line.from.x;

                      return (
                        <rect
                          key={index}
                          x={line.from.x - LOGO_LINE_STROKE_WIDTH / 2}
                          y={line.from.y - (LOGO_LINE_STROKE_WIDTH - 2) / 2}
                          width={lineWidth + LOGO_LINE_STROKE_WIDTH}
                          height={LOGO_LINE_STROKE_WIDTH - 2}
                          rx={LOGO_LINE_STROKE_WIDTH / 2}
                        />
                      );
                    })}
                  </g>
                );
            }
          })()}
        </mask>
        <rect
          x="0"
          y="0"
          width={LOGO_SVG_WIDTH}
          height={LOGO_SVG_HEIGHT}
          fill={theme.palette.text.disabled}
          mask={`url(#${defaultMaskId})`}
        />
        {(() => {
          switch (props.fillVariant) {
            case LogoFillVariant.Block:
              return (
                <>
                  {renderFillColorPolygon(theme.palette.brandYellow.main, 2)}
                  {renderFillColorPolygon(theme.palette.brandOrange.main, 1)}
                  {renderFillColorPolygon(theme.palette.brandRed.main, 0)}
                </>
              );

            case LogoFillVariant.Gradient:
              return (
                <>
                  <defs>
                    <linearGradient
                      id="fillGradient"
                      x1="0%"
                      y1="0%"
                      x2="100%"
                      y2="0%"
                      gradientTransform={`rotate(${FILL_ANGLE_DEGREES})`}
                    >
                      <stop
                        offset="0%"
                        style={{
                          stopColor: theme.palette.brandRed.main,
                          stopOpacity: 1,
                        }}
                      />
                      <stop
                        offset="50%"
                        style={{
                          stopColor: theme.palette.brandOrange.main,
                          stopOpacity: 1,
                        }}
                      />
                      <stop
                        offset="100%"
                        style={{
                          stopColor: theme.palette.brandYellow.main,
                          stopOpacity: 1,
                        }}
                      />
                    </linearGradient>
                  </defs>
                  <rect
                    x={0}
                    y={0}
                    width={LOGO_SVG_WIDTH}
                    height={LOGO_SVG_HEIGHT}
                    fill="url(#fillGradient)"
                    mask={`url(#${defaultMaskId})`}
                  />
                </>
              );

            default:
              return null;
          }
        })()}
      </svg>
    </div>
  );
};
