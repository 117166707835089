import { Project } from '../../types';

export const PROJECT_AIRBLOCK_BRAND_COLOR_PRIMARY = 'rgb(45, 104, 197)';
export const PROJECT_AIRBLOCK_BRAND_COLOR_SECONDARY = 'rgb(7, 244, 168) ';
export const PROJECT_AIRBLOCK: Project = {
  id: '@airblock',
  name: 'AirBlock',
  description:
    'Smarter white noise system for HomeKit that uses AirPlay-enabled speakers',
  status: 'maintenance',
  chip: {
    background: `linear-gradient(135deg, ${PROJECT_AIRBLOCK_BRAND_COLOR_PRIMARY} 0%, ${PROJECT_AIRBLOCK_BRAND_COLOR_SECONDARY} 100%)`,
    color: 'rgb(255, 255, 255)',
    content: '@airblock',
  },
  page: {
    tabs: [
      {
        label: 'Overview',
        page: {
          markdown: `\
_TODO_
`,
        },
      },
    ],
  },
};
