import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { Stack, Typography, useTheme } from '@mui/material';
import {
  HASHICORP_CERTIFICATION_TYPE_LABELS,
  HASHICORP_CERTIFICATION_TYPE_ICONS,
} from '../../constants';
import {
  HashicorpCertificationType,
  HashicorpCertificationLevel,
} from '../../types';
import { Icon, TransdimensionalRect } from '..';
import {
  getCertificationHashicorpBodyRectId,
  getCertificationHashicorpHeaderRectId,
} from './utilities';
import './CertificationHashicorp.scss';

export const CertificationHashicorp: FC<
  PropsWithChildren<{
    id: string;
    type: HashicorpCertificationType;
    level: HashicorpCertificationLevel;
    label: ReactNode;
  }>
> = ({ id, type, level, label, children }) => {
  const theme = useTheme();
  const headerRectId = getCertificationHashicorpHeaderRectId(id);
  const bodyRectId = getCertificationHashicorpBodyRectId(id);
  const iconUrl = HASHICORP_CERTIFICATION_TYPE_ICONS.get(type);

  return (
    <TransdimensionalRect
      id={id}
      className={classNames('Certification', 'CertificationHashicorp')}
    >
      <TransdimensionalRect
        id={headerRectId}
        display="block"
        className="CertificationHashicorp-header"
      >
        <Stack
          direction="row"
          spacing={1}
          justifyContent="center"
          alignItems="center"
          sx={{
            position: 'absolute',
            left: '10%',
            top: '20%',
            width: '80%',
          }}
        >
          {iconUrl && <Icon url={iconUrl} color={theme.palette.text.primary} />}
          <Typography variant="body1" fontWeight="bold" lineHeight="1.8rem">
            {HASHICORP_CERTIFICATION_TYPE_LABELS.get(type)}
          </Typography>
        </Stack>
        <Typography
          variant="body2"
          sx={{
            position: 'absolute',
            left: '10%',
            top: '45%',
            width: '80%',
          }}
        >
          <span>Certified</span>
          <br />
          {label}
        </Typography>
      </TransdimensionalRect>
      <TransdimensionalRect
        id={bodyRectId}
        display="block"
        className="Certification-body"
        sx={{
          padding: 1.5,
          paddingTop: 2,
        }}
      >
        {children}
      </TransdimensionalRect>
    </TransdimensionalRect>
  );
};
