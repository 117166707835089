import { takeEvery, call, put } from 'redux-saga/effects';
import { GLTF } from 'three-stdlib';

import { ThreeModelsService } from '../services';
import * as threeModelsActions from '../actions/threeModelsActions';

function* loadModel(
  action: ReturnType<typeof threeModelsActions.requestThreeModel>
) {
  const { id, url, transform } = action.payload;
  const model: GLTF = yield call(ThreeModelsService.fetch, url);

  yield call(
    ThreeModelsService.applyTransformationPipeline as any,
    model,
    transform
  );

  yield put(threeModelsActions.setThreeModel({ id, model }));
}

export function* threeModelsSaga() {
  yield takeEvery(threeModelsActions.requestThreeModel.type, loadModel);
}
