import { FC, PropsWithChildren } from 'react';
import { withErrorBoundary, useErrorBoundary } from 'react-use-error-boundary';

export const ErrorPage: FC<PropsWithChildren<{}>> = (props) => {
  const [error, resetError] = useErrorBoundary((error, errorInfo) =>
    console.log(error, errorInfo)
  );

  if (error) {
    return (
      <div>
        <p>{(error as any)?.message}</p>
        <button onClick={resetError}>Try again</button>
      </div>
    );
  }

  return <h1>Fuck</h1>;
  // return <div>{props.children}</div>;
};
