import { FC } from 'react';
import Color from 'colorjs.io';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Chip, NoWrap } from '../../../..';

export interface PaletteTableColorRow {
  id: string;
  color: string;
  textColor?: string;
}

export const ReferenceBrandPagePaletteTable: FC<{
  colors: PaletteTableColorRow[];
}> = ({ colors }) => {
  return (
    <Table sx={{ minWidth: 650 }} aria-label="simple table">
      <TableHead>
        <TableRow>
          <TableCell sx={{ paddingLeft: 0 }}>Color</TableCell>
          <TableCell align="right">Hue</TableCell>
          <TableCell align="right">Saturation</TableCell>
          <TableCell align="right" sx={{ paddingRight: 0 }}>
            Lightness
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {colors.map(({ id, color, textColor }) => {
          const nativeColor = new Color(Color.parse(color));

          return (
            <TableRow key={id}>
              <TableCell sx={{ paddingLeft: 0 }}>
                <Chip id={id} background={color} textColor={textColor}>
                  <Typography variant="code">{id}</Typography>
                </Chip>
              </TableCell>
              <TableCell align="right">
                {Math.round(nativeColor.hsl.h)}&deg;
              </TableCell>
              <TableCell align="right">
                {Math.round(nativeColor.hsl.s)}%
              </TableCell>
              <TableCell align="right" sx={{ paddingRight: 0 }}>
                {Math.round(nativeColor.hsl.l)}%
              </TableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
