// Redux
import { createAction } from '@reduxjs/toolkit';

// Slice
import { transdimensionalInteractionSlice } from '../slices';

export const {
  setTransdimensionalRect,
  setTransdimensionalGridContainerConfiguration,
  setTransdimensionalGridItemSize,
} = transdimensionalInteractionSlice.actions;
