history.pushState = ((f) =>
  function pushState() {
    // @ts-ignore
    var ret = f.apply(this, arguments);
    window.dispatchEvent(new Event('pushstate'));
    window.dispatchEvent(new Event('locationchange'));
    return ret;
  })(history.pushState);

history.replaceState = ((f) =>
  function replaceState() {
    // @ts-ignore
    var ret = f.apply(this, arguments);
    window.dispatchEvent(new Event('replacestate'));
    window.dispatchEvent(new Event('locationchange'));
    return ret;
  })(history.replaceState);

window.addEventListener('hashchange', () => {
  window.dispatchEvent(new Event('locationchange'));
});

window.addEventListener('popstate', () => {
  window.dispatchEvent(new Event('locationchange'));
});
