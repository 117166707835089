export class TypealongValueService {
  private values: string[];
  private recentValues: string[] = [];
  private recentValuesCount: number;

  constructor(values: string[], recentValuesCount: number) {
    this.values = values;
    this.recentValuesCount = recentValuesCount;
  }

  private isRecentValue(value: string): boolean {
    return this.recentValues.includes(value);
  }

  private addRecentValue(value: string): void {
    this.recentValues.unshift(value);
    this.recentValues.splice(this.recentValuesCount);
  }

  private getRandomValue(): string {
    return this.values[Math.floor(Math.random() * this.values.length)];
  }

  public getNextValue(): string {
    while (true) {
      const value = this.getRandomValue();

      if (this.isRecentValue(value)) {
        continue;
      }

      this.addRecentValue(value);

      return value;
    }
  }
}
