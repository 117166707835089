import { Project } from '../../types';
import {
  PROJECT_MANGANESE_BRAND_COLOR_PRIMARY,
  PROJECT_MANGANESE_BRAND_COLOR_SECONDARY,
} from './manganese';

export const PROJECT_MANGANESE_CORE_KIT: Project = {
  id: '@manganese/core-kit',
  name: 'Manganese CoreKit',
  description: 'Standard library for Manganese projects',
  status: 'maintenance',
  chip: {
    background: `linear-gradient(135deg, ${PROJECT_MANGANESE_BRAND_COLOR_PRIMARY} 0%, ${PROJECT_MANGANESE_BRAND_COLOR_SECONDARY} 100%)`,
    color: 'rgb(255, 255, 255)',
    content: '@manganese/core-kit',
  },
  page: {
    tabs: [
      {
        label: 'Overview',
        page: {
          markdown: `\
_TODO_
`,
        },
      },
    ],
  },
};
