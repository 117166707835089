import { ElementType, FC, ReactNode } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import {
  Breadcrumbs as MuiBreadcrumbs,
  Typography,
  TypographyProps,
  useTheme,
} from '@mui/material';
import { BrandCircle } from '..';

import './Breadcrumbs.scss';

type BreadcrumbsVariant = 'pageHeader' | 'chip';

interface BreadcrumbItemConfiguration {
  to: string;
  text: ReactNode;
  openInNew?: boolean;
}

const BRAND_CIRCLE_SIZES = new Map<BreadcrumbsVariant, number>([
  ['pageHeader', 20],
  ['chip', 13],
]);

const TYPOGRAPHY_PROPS = new Map<BreadcrumbsVariant, Partial<TypographyProps>>([
  [
    'pageHeader',
    {
      variant: 'h3',
      fontWeight: 'bold',
      lineHeight: 'inherit',
      sx: { display: 'flex', alignItems: 'center' },
    },
  ],
  ['chip', { variant: 'body1', lineHeight: '28px' }],
]);

export const Breadcrumbs: FC<{
  maxItems?: number;
  variant: BreadcrumbsVariant;
  prefixBrandCircle?: boolean;
  items: BreadcrumbItemConfiguration[];
}> = (props) => {
  const theme = useTheme();
  const paletteMode = theme.palette.mode;
  const separator = <div className="Breadcrumbs-separator" />;

  return (
    <MuiBreadcrumbs
      maxItems={props.maxItems}
      className={classNames(
        'Breadcrumbs-root',
        `Breadcrumbs-root-${props.variant}`,
        `Breadcrumbs-root-${paletteMode}`
      )}
      separator={separator}
      sx={{
        ...(props.variant === 'pageHeader' && {
          lineHeight: '3',
        }),
        ...(paletteMode === 'dark'
          ? { color: 'white' }
          : { color: 'rgb(64, 64, 64)' }),
      }}
    >
      {props.prefixBrandCircle && (
        <Link to="/">
          <BrandCircle size={BRAND_CIRCLE_SIZES.get(props.variant) as number} />
        </Link>
      )}
      {props.items.map((item, index) => {
        return (
          <Link
            key={index}
            to={item.to}
            target={item.openInNew ? '_blank' : undefined}
          >
            <Typography {...TYPOGRAPHY_PROPS.get(props.variant)}>
              {item.text}
            </Typography>
          </Link>
        );
      })}
    </MuiBreadcrumbs>
  );
};
