import { FC, PropsWithChildren } from 'react';
import { Box, Typography, alpha, useTheme } from '@mui/material';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import {
  BrandCircle,
  Chip,
  TransdimensionalOffset,
  TransdimensionalRect,
} from '..';

export const MeetingTypeDetails: FC<PropsWithChildren<{}>> = (props) => {
  const theme = useTheme();

  return (
    <TransdimensionalRect
      id="meetingCreation_meetingTypeDetails"
      sx={{
        minHeight: '100%',
        padding: 2,
        paddingTop: 1.5,
        textAlign: 'center',
      }}
    >
      <Box
        component="div"
        sx={{
          width: 60,
          height: 60,
          marginLeft: 'auto',
          marginRight: 'auto',
        }}
      >
        <BrandCircle size={60} />
      </Box>
      <Typography
        variant="h3"
        fontWeight={900}
        lineHeight="1.5"
        sx={{ padding: theme.spacing(2, 0) }}
      >
        Intro Meeting{' '}
        <span style={{ color: alpha(theme.palette.text.primary, 0.75) }}>
          with
        </span>{' '}
        Sam
      </Typography>
      <TransdimensionalRect
        id="meetingCreation_meetingDurationChip"
        display="inline-block"
      >
        <TransdimensionalOffset z={theme.depth.chip} display="inline-block">
          <Chip startAdornment={<TimerOutlinedIcon />}>15 Minutes</Chip>
        </TransdimensionalOffset>
      </TransdimensionalRect>
      <br />
      {props.children}
    </TransdimensionalRect>
  );
};
