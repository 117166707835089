import { useDispatch } from 'react-redux';
import { setTransdimensionalRect } from '../../actions';
import { TransdimensionalRectOptions } from '../../types';

export const useTransdimensionalRect = (
  options: TransdimensionalRectOptions
) => {
  const dispatch = useDispatch();
  const handleRect = (rect: DOMRect) => {
    const newRect = rect.toJSON();

    if (typeof options.offsetTop === 'number') {
      newRect.top += options.offsetTop;
      newRect.y += options.offsetTop;
      newRect.height -= options.offsetTop;
    }

    if (typeof options.offsetLeft === 'number') {
      newRect.left += options.offsetLeft;
      newRect.x += options.offsetLeft;
      newRect.width -= options.offsetLeft;
    }

    if (options.log) console.log('setting new rect', options.id, newRect);

    dispatch(
      setTransdimensionalRect({
        id: options.id,
        rect: newRect,
      })
    );
  };

  return {
    handleRect,
  };
};
