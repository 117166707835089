import { FC } from 'react';
import { Link, useMatch, useParams } from 'react-router-dom';
import {
  Box,
  Container,
  Grid,
  Stack,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import {
  Breadcrumbs,
  Chip,
  PresentationPage,
  Section,
  TransdimensionalRect,
} from '../../..';
import { ReferenceTermPageThree } from '.';
import BudOutlinedIcon from '../../../../@mui/icons-material/BudOutlined';
import NutOutlinedIcon from '../../../../@mui/icons-material/NutOutlined';
import PottedPlantOutlinedIcon from '../../../../@mui/icons-material/PottedPlantOutlined';
import OakTreeOutlinedIcon from '../../../../@mui/icons-material/OakTreeOutlined';
import LabelOutlinedIcon from '@mui/icons-material/Topic';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import ArticleOutlinedIcon from '@mui/icons-material/ArticleOutlined';

const placeholderPage = {
  markdown: `A mathematical description of the quantum state of an isolated quantum system, represented by $\\Psi$.

$$
\\htmlClass{mathematical}{L} = \\frac{1}{2} \\rho v^2 S C_L
$$

`,
};

export const ReferenceTermPage: FC = () => {
  const theme = useTheme();
  const { termId } = useParams();

  return (
    <Section
      id="referenceTermPage_indexSection"
      three={<ReferenceTermPageThree />}
    >
      <Container maxWidth="lg">
        <Breadcrumbs
          variant="pageHeader"
          prefixBrandCircle
          maxItems={3}
          items={[
            {
              to: '/reference',
              text: 'Reference',
            },
            {
              to: '/reference/terms',
              text: 'Terms',
            },
            {
              to: `/reference/terms/${termId}`,
              text: (
                <>
                  Wave Function
                  <Tooltip title="Budding term" arrow placement="top">
                    <PottedPlantOutlinedIcon
                      fontSize="inherit"
                      sx={{
                        marginLeft: 1,
                        marginTop: '2px',
                        color: 'rgb(160, 160, 160)',
                      }}
                    />
                  </Tooltip>
                </>
              ),
            },
          ]}
        />
        <Grid container spacing={4}>
          <Grid item xs={12} md={8} lg={9}>
            <PresentationPage presentationPage={placeholderPage} />
            <br />
            <Chip startAdornment={<LabelOutlinedIcon />}>Quantum Physics</Chip>
          </Grid>
          <Grid item xs={12} md={4} lg={3}>
            <Stack spacing={2}>
              <Box
                component="div"
                sx={{
                  position: 'relative',
                  padding: 1,
                  border: '1px solid',
                  borderColor: theme.palette.divider,
                }}
              >
                <Typography variant="body2">Related Note</Typography>
                <Typography variant="body1">
                  <Chip
                    startAdornment={<ArticleOutlinedIcon />}
                    background="rgb(224, 224, 224)"
                  >
                    Motion of a wave packet
                  </Chip>
                </Typography>
              </Box>
              <Box
                component="div"
                sx={{
                  position: 'relative',
                  padding: 1,
                  border: '1px solid',
                  borderColor: theme.palette.divider,
                }}
              >
                <Typography variant="body1">
                  The{' '}
                  <Chip background="rgb(224, 224, 224)">
                    Schrödinger equation
                  </Chip>{' '}
                  describes the evolution of a wave function over time.
                </Typography>
              </Box>
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </Section>
  );
};
