import { Environment } from '../../types';
import { DEFAULT_CALENDLY_PROXY_URL } from '../../constants';
import { CalendlyProxyClient } from '../../clients';
import { getLocaleTimeZoneName } from '..';

export const getEnvironment = (): Environment => {
  const calendlyProxyUrl =
    process.env.REACT_APP_CALENDLY_PROXY_URL ?? DEFAULT_CALENDLY_PROXY_URL;

  return {
    NODE_ENV: process.env.NODE_ENV as any,
    calendlyProxyClient: new CalendlyProxyClient(calendlyProxyUrl),
    calendlyProxyUrl,
    localeTimeZoneName: getLocaleTimeZoneName(),
  };
};
