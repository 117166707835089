import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { GridContainerConfiguration } from '../types';

export interface TransdimensionalInteractionGridState {
  gridContainerConfiguration: GridContainerConfiguration;
  gridItemSizes: {
    [childIndex: number]: number;
  };
}

export interface TransdimensionalInteractionState {
  rectsById: {
    [rectId: string]: DOMRect;
  };
  gridsById: {
    [gridId: string]: TransdimensionalInteractionGridState;
  };
}

const INITIAL_STATE: TransdimensionalInteractionState = {
  rectsById: {},
  gridsById: {},
};

const DEFAULT_GRID_CONTAINER_CONFIGURATION: GridContainerConfiguration = {
  spacing: 0,
};

const getOrCreateGridState = (
  state: TransdimensionalInteractionState,
  gridId: string
) => {
  let gridState = state.gridsById[gridId];

  if (!gridState) {
    gridState = state.gridsById[gridId] = {
      gridContainerConfiguration: DEFAULT_GRID_CONTAINER_CONFIGURATION,
      gridItemSizes: {},
    };
  }

  return gridState;
};

export const transdimensionalInteractionSlice = createSlice({
  name: 'transdimensionalInteraction',
  initialState: INITIAL_STATE,
  reducers: {
    setTransdimensionalRect: {
      reducer: (
        state,
        action: PayloadAction<{
          id: string;
          rect: DOMRect;
        }>
      ) => {
        const { id, rect } = action.payload;

        state.rectsById[id] = rect;
      },
      prepare: ({ rect, ...payload }) => {
        const { scrollX, scrollY } = window;
        const scrolledRect: DOMRect = {
          ...rect,
          width: rect.width,
          x: rect.x + scrollX,
          left: rect.left + scrollX,
          right: rect.right - scrollX,
          height: rect.height,
          y: rect.y + scrollY,
          top: rect.top + scrollY,
          bottom: rect.bottom - scrollY,
        };

        return {
          payload: {
            ...payload,
            rect: scrolledRect,
          },
        };
      },
    },
    setTransdimensionalGridContainerConfiguration: (
      state,
      action: PayloadAction<{
        gridId: string;
        gridContainerConfiguration: GridContainerConfiguration;
      }>
    ) => {
      const { gridId, gridContainerConfiguration } = action.payload;
      const gridState = getOrCreateGridState(state, gridId);
      gridState.gridContainerConfiguration = gridContainerConfiguration;
    },
    setTransdimensionalGridItemSize: (
      state,
      action: PayloadAction<{
        gridId: string;
        childIndex: number;
        gridItemSize: number;
      }>
    ) => {
      const { gridId, childIndex, gridItemSize } = action.payload;
      const gridState = getOrCreateGridState(state, gridId);
      gridState.gridItemSizes[childIndex] = gridItemSize;
    },
  },
});
