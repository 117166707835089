import { FC } from 'react';
import { useTheme } from '@mui/material';
import {
  PageHeaderThree,
  Three,
  ThreeLighting,
  ThreeScrollOffsetContainer,
  TransdimensionalButtonThree,
  TransdimensionalButtonThreeBox,
} from '../..';
import { BOOKMARK_ITEMS } from '.';
import { enhance } from '../../../utilities';

export const BookmarksPageThree: FC = () => {
  const theme = useTheme();
  const bookmarkItems = BOOKMARK_ITEMS.flatMap(
    (rootBookmarkItem) => rootBookmarkItem.children ?? []
  );

  return (
    <Three
      id="bookmarksPage_three"
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
      }}
    >
      <ThreeLighting />
      <ThreeScrollOffsetContainer>
        <PageHeaderThree variant="search" />
        {bookmarkItems.map((bookmarkItem) => (
          <TransdimensionalButtonThree
            key={bookmarkItem.id}
            id={bookmarkItem.id}
          >
            <TransdimensionalButtonThreeBox
              colorProfile={{
                default: theme.palette.background.paper,
                hover:
                  bookmarkItem.background ??
                  enhance(theme.palette.background.paper, 0.2),
              }}
            />
          </TransdimensionalButtonThree>
        ))}
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
