import { Project } from '../../types';
import {
  PROJECT_HOMEBRIDGE_BRAND_COLOR_PRIMARY,
  PROJECT_HOMEBRIDGE_BRAND_COLOR_SECONDARY,
} from './homebridge';

export const PROJECT_HOMEBRIDGE_LIFX_MULTI: Project = {
  id: 'homebridge-lifx-multi',
  name: 'Homebridge LIFX Multi',
  description:
    'Homebridge plugin for animated effects on LIFX multi-zone lights',
  status: 'maintenance',
  chip: {
    background: `linear-gradient(135deg, ${PROJECT_HOMEBRIDGE_BRAND_COLOR_PRIMARY} 0%, ${PROJECT_HOMEBRIDGE_BRAND_COLOR_SECONDARY} 100%)`,
    color: 'rgb(255, 255, 255)',
    content: 'homebridge-lifx-multi',
  },
  page: {
    tabs: [
      {
        label: 'Overview',
        page: {
          markdown: `\
_TODO_
`,
        },
      },
    ],
  },
};
