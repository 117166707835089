import { Theme, useTheme } from '@mui/material';
import { MaterialOptions, PointColorCoercible } from '../../types';
import { getOrCreateMaterial, toColor } from '../../utilities';

const DEFAULT_MATERIAL_RESOLVER = (theme: Theme) => {
  switch (theme.palette.mode) {
    case 'light':
      return 0xffffff;
    case 'dark':
      return 0x404040;
  }
};

export const useMaterial = (
  value:
    | PointColorCoercible
    | ((theme: Theme) => PointColorCoercible) = DEFAULT_MATERIAL_RESOLVER,
  options?: MaterialOptions
) => {
  const theme = useTheme();
  const colorCoercible = value instanceof Function ? value(theme) : value;
  const color = toColor(colorCoercible);
  const material = getOrCreateMaterial(color, options);

  return material;
};
