import { FC, Fragment, ReactNode, MouseEvent } from 'react';
import { sum } from 'lodash';
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  useTheme,
} from '@mui/material';
import { TransdimensionalButton, TransdimensionalRect } from '..';
import {
  getTransdimensionalTableHeadRectId,
  getTransdimensionalTableRowRectId,
} from './utilities';

export interface TransdimensionalTableHeadCell {
  id: string;
  colSpan?: number;
  width?: number;
  align?: 'left' | 'right' | 'center';
  offsetLeft?: number;
  content: ReactNode;
}

export interface TransdimensionalTableRow {
  id: string;
  button?: boolean;
  cursor?: 'pointer' | 'default';
  offsetLeft?: number;
  content: ReactNode;
}

export interface TransdimensionalTableProps {
  id: string;
  headCells?: TransdimensionalTableHeadCell[];
  rows: TransdimensionalTableRow[];
  refRow?: (rowId: string, rowElement: HTMLTableRowElement | null) => void;
  onClickRow?: (rowId: string, event: MouseEvent<HTMLTableRowElement>) => void;
}

export const TABLE_ROW_DIVIDER_HEIGHT = 16;

export const TransdimensionalTable: FC<TransdimensionalTableProps> = (
  props
) => {
  const tableId = props.id;
  const headRectId = getTransdimensionalTableHeadRectId(tableId);
  const theme = useTheme();
  const totalColSpan = sum(
    props.headCells?.map((headCell) => headCell.colSpan ?? 1)
  );

  return (
    <TableContainer
      sx={
        {
          // only for table-contained variant:
          // width: `calc(100% - (2 * ${theme.spacing(2)}))`,
          // paddingLeft: 2,
          // paddingRight: 2,
        }
      }
    >
      <TransdimensionalRect id={props.id} display="block">
        <Table>
          <TransdimensionalRect
            id={headRectId}
            display="table-row"
            component="thead"
          >
            {props.headCells?.map((headCell) => {
              return (
                <TableCell
                  key={headCell.id}
                  component="th"
                  colSpan={headCell.colSpan ?? 1}
                  align={headCell.align}
                  sx={{
                    width: headCell.width,
                    paddingLeft: `calc(${theme.spacing(2)} + ${
                      headCell.offsetLeft ?? 0
                    }px)`,
                  }}
                >
                  {headCell.content}
                </TableCell>
              );
            })}
          </TransdimensionalRect>
          <TableBody>
            {props.rows.map((row, rowIndex) => {
              const rowRectId = getTransdimensionalTableRowRectId(
                tableId,
                row.id
              );
              const rowProps = {
                id: rowRectId,
                offsetLeft: row.offsetLeft,
                ref: (rowElement: HTMLElement | null) => {
                  if (props.refRow)
                    props.refRow(
                      row.id,
                      rowElement as HTMLTableRowElement | null
                    );
                },
                component: 'tr' as const,
                onClick: (event: MouseEvent<any>) => {
                  if (!props.onClickRow) return;

                  props.onClickRow(row.id, event);
                },
              };

              return (
                <Fragment key={row.id}>
                  {row.button ? (
                    <TransdimensionalButton {...rowProps}>
                      {row.content}
                    </TransdimensionalButton>
                  ) : (
                    <TransdimensionalRect {...rowProps}>
                      {row.content}
                    </TransdimensionalRect>
                  )}
                  <TableRow>
                    <TableCell
                      colSpan={totalColSpan}
                      sx={{
                        height: TABLE_ROW_DIVIDER_HEIGHT,
                        paddingTop: 0,
                        paddingBottom: 0,
                      }}
                    ></TableCell>
                  </TableRow>
                </Fragment>
              );
            })}
          </TableBody>
        </Table>
      </TransdimensionalRect>
    </TableContainer>
  );
};
