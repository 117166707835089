import { FC } from 'react';
import { THREE_FULL_WIDTH } from '../../../../constants';
import { TransdimensionalUnitConversionServiceContext } from '../../../../contexts';
import { useMaterial } from '../../../../hooks';
import {
  Three,
  ThreeBox,
  ThreeLighting,
  ThreeQuad,
  ThreeScrollOffsetContainer,
  ThreeTransdimensionalBorder,
  ThreeTransdimensionalRect,
  ThreeTransdimensionalWalls,
} from '../../..';
import { getEducationItemTitleRectId } from './utilities';

const EducationItemThree: FC<{ id: string }> = (props) => {
  const educationItemId = props.id;
  const titleRectId = getEducationItemTitleRectId(educationItemId);
  const titleMaterial = useMaterial((theme) => theme.palette.background.paper);

  return (
    <>
      <ThreeTransdimensionalBorder
        id={props.id}
        left={THREE_FULL_WIDTH}
        right={THREE_FULL_WIDTH}
      />
      <ThreeTransdimensionalWalls id={props.id} all={1} />

      <ThreeTransdimensionalRect id={titleRectId}>
        <ThreeBox
          material={titleMaterial}
          position={[0, 0, -0.2 / 2]}
          scale={[1, 1, 0.2]}
        />
      </ThreeTransdimensionalRect>
    </>
  );
};

export const MainPageEducationSectionThree: FC = () => {
  return (
    <Three
      id="mainPage_educationSection_three"
      inset
      sx={{
        position: 'absolute',
        width: '100%',
        height: '100%',
      }}
    >
      <ThreeLighting />
      <ThreeScrollOffsetContainer>
        <TransdimensionalUnitConversionServiceContext.Consumer>
          {(transdimensionalUnitConversionService) => {
            if (!transdimensionalUnitConversionService) return null;

            const sceneHeight =
              transdimensionalUnitConversionService.getVisibleHeightAtDepth(0);

            return (
              <>
                <ThreeTransdimensionalRect id="mainPage_educationSection_title">
                  <ThreeQuad scale={[THREE_FULL_WIDTH, 1, 1]} />
                </ThreeTransdimensionalRect>
                <EducationItemThree id="mainPage_educationSection_universityOfWisconsin" />
                <ThreeTransdimensionalRect id="mainPage_educationSection_divider">
                  <ThreeQuad scale={[THREE_FULL_WIDTH, 1, 1]} />
                </ThreeTransdimensionalRect>
                <EducationItemThree id="mainPage_educationSection_madisonCollege" />
                <ThreeTransdimensionalRect id="mainPage_educationSection_footer">
                  <ThreeQuad scale={[THREE_FULL_WIDTH, 1, 1]} />
                </ThreeTransdimensionalRect>
              </>
            );
          }}
        </TransdimensionalUnitConversionServiceContext.Consumer>
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
