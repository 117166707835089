import { FC } from 'react';
import { Box, Stack, Typography, useTheme } from '@mui/material';
import ArrowDownwardOutlinedIcon from '@mui/icons-material/ArrowDownwardOutlined';
import { useWindowSize } from '../../../../hooks';
import { getVisibleHeightAtDepth, roundScalar } from '../../../../utilities';
import {
  DebugModeToggle,
  Logo,
  Section,
  TransdimensionalButton,
} from '../../..';
import {
  MainPageIntroductionSectionThree,
  MainPageIntroductionTypealong,
} from '.';

import './MainPageIntroductionSection.scss';

const LOGO_CONTAINER_HEIGHT_THREE = 1;
const LOGO_PADDING_THREE = 0.15;
const LOGO_HEIGHT_THREE = LOGO_CONTAINER_HEIGHT_THREE - 2 * LOGO_PADDING_THREE;

export const MainPageIntroductionSection: FC = () => {
  const theme = useTheme();
  const sceneHeight = getVisibleHeightAtDepth();
  const windowSize = useWindowSize();
  const padding = roundScalar(
    windowSize.y * (LOGO_PADDING_THREE / sceneHeight)
  );

  return (
    <Section
      id="section-introduction"
      three={<MainPageIntroductionSectionThree />}
      sx={{ marginBottom: '-352px' }}
    >
      <header
        className="Introduction-header"
        style={{
          paddingTop: padding + 'px',
          paddingBottom: padding + 'px',
        }}
      >
        <DebugModeToggle>
          <Logo height={(windowSize.y * LOGO_HEIGHT_THREE) / sceneHeight} />
        </DebugModeToggle>
      </header>
      <Box component="div" className="Introduction-content">
        <MainPageIntroductionTypealong />
        <TransdimensionalButton
          id="introduction-get-in-touch"
          color="secondary"
          sx={{
            width: 'auto',
            padding: theme.spacing(1, 2),
          }}
          onClick={(event) => {
            window.scrollTo({
              top: document.body.scrollHeight,
              behavior: 'smooth',
            });
          }}
        >
          <Stack
            direction="row"
            spacing={1}
            alignItems="center"
            sx={{
              color: theme.palette.secondary.contrastText,
            }}
          >
            <Typography variant="body2" color="inherit">
              Get In Touch
            </Typography>
            <ArrowDownwardOutlinedIcon fontSize="inherit" />
          </Stack>
        </TransdimensionalButton>
      </Box>
      <Box component="div" sx={{ height: 352 }} />
    </Section>
  );
};

export default MainPageIntroductionSection;
