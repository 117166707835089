import { FC, PropsWithChildren, ReactNode } from 'react';
import { Box, Container, Typography, useTheme } from '@mui/material';
import {
  Badge,
  Chip,
  ChipList,
  Heading,
  Section,
  TransdimensionalRect,
} from '../../..';
import {
  COMPUTER_SCIENCE_COURSEWORK_ITEMS,
  PARAMEDIC_SKILL_ITEMS,
} from './constants';
import { getEducationItemTitleRectId } from './utilities';
import { MainPageEducationSectionThree } from '.';

const EducationItem: FC<
  PropsWithChildren<{
    id: string;
    institutionName: ReactNode;
    degreeName: ReactNode;
  }>
> = (props) => {
  const educationItemId = props.id;
  const titleRectId = getEducationItemTitleRectId(educationItemId);

  return (
    <TransdimensionalRect id={educationItemId} display="block">
      <Box component="div" sx={{ padding: 4, textAlign: 'center' }}>
        <TransdimensionalRect id={titleRectId} display="inline-block">
          <Box
            component="div"
            sx={{
              display: 'inline-block',
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            <Typography variant="h3">{props.institutionName}</Typography>
            <Typography variant="h3" fontStyle="italic">
              {props.degreeName}
            </Typography>
          </Box>
        </TransdimensionalRect>
        <br />
        <br />
        {props.children}
      </Box>
    </TransdimensionalRect>
  );
};

export const MainPageEducationSection: FC = () => {
  const theme = useTheme();

  return (
    <Section
      id="mainPage_educationSection"
      three={<MainPageEducationSectionThree />}
    >
      <Container maxWidth="md">
        <TransdimensionalRect id="mainPage_educationSection_title">
          <Heading variant="h2" id="my-education">
            My Education
          </Heading>
        </TransdimensionalRect>
        <EducationItem
          id="mainPage_educationSection_universityOfWisconsin"
          institutionName="University of Wisconsin - Madison"
          degreeName="Bachelor of Science, Computer Sciences"
        >
          <ChipList label="Advanced Coursework">
            {COMPUTER_SCIENCE_COURSEWORK_ITEMS.map((item) => {
              return (
                <TransdimensionalRect id={item.id} display="inline-block">
                  <Chip>{item.label}</Chip>
                </TransdimensionalRect>
              );
            })}
          </ChipList>
        </EducationItem>
        <TransdimensionalRect
          id="mainPage_educationSection_divider"
          sx={{ height: theme.spacing(8) }}
        />
        <EducationItem
          id="mainPage_educationSection_madisonCollege"
          institutionName="Madison Area Technical College"
          degreeName="Paramedic Technical Diploma"
        >
          <Badge background={theme.palette.secondary.main}>
            Completion in December 2023
          </Badge>
          <br />
          <br />
          <ChipList label="Skills">
            {PARAMEDIC_SKILL_ITEMS.map((item) => (
              <TransdimensionalRect id={item.id} display="inline-block">
                <Chip>{item.label}</Chip>
              </TransdimensionalRect>
            ))}
          </ChipList>
        </EducationItem>
        <TransdimensionalRect
          id="mainPage_educationSection_footer"
          sx={{ height: theme.spacing(4) }}
        />
      </Container>
    </Section>
  );
};
