import { FC, PropsWithChildren, useContext } from 'react';
import { alpha, Box, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { DialogContext } from './DialogContext';

export interface DialogHeaderProps {
  showCloseButton?: boolean;
}

const SCROLLBAR_SIZE = 17;

const HEIGHT = 84;
const CLOSE_BUTTON_SIZE = 52;

export const DialogHeader: FC<PropsWithChildren<DialogHeaderProps>> = (
  props
) => {
  const dialogContext = useContext(DialogContext);
  const theme = useTheme();
  const showCloseButton = props.showCloseButton ?? true;
  const handleClose = () => {
    dialogContext.onClose();
  };

  return (
    <>
      <Box
        component="div"
        sx={{
          display: 'flex',
          maxWidth: '100%',
          overflowX: 'scroll',
          alignItems: 'center',
          height: HEIGHT,
        }}
      >
        <Box
          component="div"
          sx={{
            flexGrow: 1,
            marginLeft: 3,
            paddingRight: `calc(${CLOSE_BUTTON_SIZE}px + ${theme.spacing(
              3 - 0 + 1.5
            )})`,
          }}
        >
          {props.children}
        </Box>
      </Box>
      {showCloseButton && (
        <>
          <Box
            component="div"
            sx={{
              position: 'absolute',
              right: theme.spacing(3 - 0),
              top: (HEIGHT - CLOSE_BUTTON_SIZE) / 2,
            }}
          >
            <Box
              component="div"
              sx={{
                position: 'absolute',
                right: 0,
                top: 0,
                width: CLOSE_BUTTON_SIZE,
                height: CLOSE_BUTTON_SIZE,
                backgroundColor: 'rgba(204, 204, 204, 0.875)',
                borderRadius: '50%',
                boxShadow: `0px 0px 8px ${alpha(
                  theme.palette.background.default,
                  0.25
                )}`,
              }}
            />
            <IconButton color="primary" size="large" onClick={handleClose}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
        </>
      )}
    </>
  );
};
