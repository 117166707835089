import { Object3D, Material, Mesh } from 'three';

export const applyMaterial = (modelObject: Object3D, material: Material) => {
  modelObject.children.forEach((child) => {
    if (child instanceof Mesh) {
      child.material = material;
    }

    applyMaterial(child, material);
  });
};
