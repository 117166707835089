import { FC, PropsWithChildren } from 'react';
import { useTheme } from '@mui/material/styles';
import { DARK_THEME, LIGHT_THEME } from '../../constants';
import { ThemeProvider } from '..';

export const ThemeInverter: FC<PropsWithChildren<{}>> = (props) => {
  const parentTheme = useTheme();
  let theme = {
    ...parentTheme,
  };

  switch (parentTheme.palette.mode) {
    case 'dark':
      theme = {
        ...LIGHT_THEME,
        ...parentTheme,
        palette: {
          ...parentTheme.palette,
          ...LIGHT_THEME.palette,
        },
      };

      break;
    case 'light':
      theme = {
        ...DARK_THEME,
        ...parentTheme,
        palette: {
          ...parentTheme.palette,
          ...DARK_THEME.palette,
        },
      };

      break;
  }

  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};
