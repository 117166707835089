import { useContext } from 'react';
import { TransdimensionalUnitConversionServiceContext } from '../../contexts';
import { ThreeContainerBounds } from '../../types';
import { useContainerWidth } from '..';

export const useThreeContainerBounds = () => {
  const transdimensionalUnitConversionService = useContext(
    TransdimensionalUnitConversionServiceContext
  );
  const containerWidthScreen = useContainerWidth();

  if (!transdimensionalUnitConversionService) return null;

  const windowCenter =
    transdimensionalUnitConversionService.getWindowCenterThree();
  const containerWidthThree =
    transdimensionalUnitConversionService.clientToThreeX(containerWidthScreen);

  return {
    left: windowCenter.x - containerWidthThree / 2,
    right: windowCenter.x + containerWidthThree / 2,
  } as ThreeContainerBounds;
};
