export const staggerOne = ({
  value,
  index,
  count,
  step,
}: {
  value: number;
  index: number;
  count: number;
  step: number;
}): number => {
  const padding = (count - 1) * step;
  const range = 1 + padding;

  return (-padding + index * step + value * (range + padding)) / range;
};
