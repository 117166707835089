import { FC } from 'react';
import { Grid } from '@mui/material';
import { MeetingLocation } from '../../constants';
import { ButtonSelectionButton, ButtonSelectionContext } from '..';
import { useMeetingLocationOptions } from './utilities';

export const MeetingLocationSelection: FC<{
  value: MeetingLocation | null;
  onChange: (value: MeetingLocation | null) => void;
}> = (props) => {
  const options = useMeetingLocationOptions();

  return (
    <ButtonSelectionContext.Provider
      value={{
        value: props.value,
        onChange: (value) => props.onChange(value as MeetingLocation | null),
      }}
    >
      <Grid container spacing={2}>
        {options.map((option) => (
          <Grid key={option.value} item xs={6} sm={3} md={6}>
            <ButtonSelectionButton
              value={option.value}
              color={option.color}
              icon={option.icon}
              label={option.label}
            />
          </Grid>
        ))}
      </Grid>
    </ButtonSelectionContext.Provider>
  );
};
