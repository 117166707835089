import { FC, useContext } from 'react';
import { Box, LinearProgress, useTheme } from '@mui/material';
import { ProgressContext } from '../../contexts';

export const LinearProgressIndicator: FC<{
  position: 'fixed' | 'sticky' | 'static';
}> = (props) => {
  const progressContext = useContext(ProgressContext);
  const theme = useTheme();

  return (
    <Box
      component="div"
      sx={{
        zIndex: theme.zIndex.appBar + 1,
        position: props.position,
        top: 0,
        width: '100%',
        height: 0,
        pointerEvents: 'none',
        color: theme.palette.text.secondary,
        transition: theme.transitions.create('opacity'),
        opacity: progressContext.active ? 1 : 0,
      }}
    >
      <LinearProgress variant="indeterminate" color="inherit" sx={{}} />
    </Box>
  );
};
