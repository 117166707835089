import { FC, PropsWithChildren } from 'react';
import { Box } from '@mui/material';

export const ChipInlineWrapper: FC<PropsWithChildren<{}>> = ({ children }) => {
  return (
    <Box
      component="span"
      sx={{
        display: 'inline-block',
        position: 'relative',
        top: 2,
        height: 28,
        lineHeight: '28px',
      }}
    >
      {children}
    </Box>
  );
};
