import { FC } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Box, useTheme } from '@mui/material';

import './DynamicList.scss';

interface DynamicListItem {
  key: string;
  height: number | string;
  content: JSX.Element;
}

export const DynamicList: FC<{
  duration?: number;
  delay?: number;
  items: DynamicListItem[];
}> = ({ items, ...props }) => {
  const theme = useTheme();
  const duration = props.duration ?? theme.transitions.duration.standard;
  const delay = props.delay ?? 0;

  return (
    <TransitionGroup component="div" className="DynamicList-root">
      {items.map((item) => {
        const itemHeight =
          typeof item.height === 'number' ? `${item.height}px` : item.height;

        return (
          <CSSTransition
            key={item.key}
            classNames="DynamicListTransition"
            timeout={2 * duration + delay}
            appear={false}
            enter={true}
            exit={true}
            onEnter={(node: HTMLElement) => {
              node.style.height = '0px';
              node.style.opacity = '0';
              node.style.transition = theme.transitions.create(
                ['opacity', 'height'],
                { duration }
              );
              node.style.transitionDelay = `${duration + delay}ms, ${delay}ms`;
            }}
            onEntering={(node: HTMLElement) => {
              node.style.height = itemHeight;
              node.style.opacity = '1';
            }}
            onEntered={(node: HTMLElement) => {
              // node.style.height = '';
              // node.style.opacity = '';
            }}
            onExit={(node: HTMLElement) => {
              node.style.transition = theme.transitions.create(
                ['opacity', 'height'],
                { duration }
              );
              node.style.transitionDelay = `${delay}ms, ${duration + delay}ms`;
              node.style.height = itemHeight;
            }}
            onExiting={(node: HTMLElement) => {
              node.style.height = '0px';
              node.style.opacity = '0';
            }}
            onExited={(node: HTMLElement) => {
              // node.style.height = '';
              // node.style.opacity = '';
              // node.style.transition = '';
              // node.style.transitionDelay = '';
            }}
          >
            <Box component="div">{item.content}</Box>
          </CSSTransition>
        );
      })}
    </TransitionGroup>
  );
};
