import { FC, PropsWithChildren } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { TransdimensionalRect } from '..';

export const BrandCircle: FC<
  PropsWithChildren<{ id?: string; size: number; sx?: SxProps<Theme> }>
> = (props) => {
  const circleNode = (
    <Box
      component="div"
      className="BrandCircle-root"
      sx={[
        {
          borderRadius: '50%',
          backgroundImage: "url('/images/LogoGradientCircle.png')",
          backgroundSize: 'contain',
          backgroundPosition: 'center center',
          backgroundRepeat: 'no-repeat',
          overflow: 'hidden',
          width: props.size,
          height: props.size,
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
    >
      {props.children}
    </Box>
  );

  if (props.id) {
    return (
      <TransdimensionalRect
        id={props.id}
        className="BrandCircle-transdimensionalRect"
        sx={{
          verticalAlign: 'text-top',
        }}
      >
        {circleNode}
      </TransdimensionalRect>
    );
  } else {
    return circleNode;
  }
};
