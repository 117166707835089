// THREE.js
import { Object3D } from 'three';
import { GLTF, GLTFLoader } from 'three-stdlib';

// Types
import {
  ThreeModelTransformation,
  ThreeModelTransformationParameters,
  ThreeModelTransformationPipelineParameters,
} from '../types';

const getThreeModelObject = (model: GLTF): Object3D => model.scene;

export class ThreeModelsService {
  private static transformations = new Map<
    string /* transformation ID */,
    ThreeModelTransformation
  >();

  public static defineTransformation(
    transformationId: string,
    transformation: ThreeModelTransformation
  ) {
    this.transformations.set(transformationId, transformation);
  }

  public static fetch(url: string): Promise<GLTF> {
    return new Promise<GLTF>((resolve, reject) => {
      const loader = new GLTFLoader();
      loader.load(
        url,
        resolve,
        (xhr: ProgressEvent) => {
          if (!xhr.total) return;

          console.log(
            `Progress loading '${url}': ${xhr.loaded} / ${
              xhr.total
            } (${Math.round((100 * xhr.loaded) / xhr.total)}%)`
          );
        },
        (error: ErrorEvent) => {
          console.error(`Error occurred while loading model '${url}':`);
          console.error(error);

          reject(error);
        }
      );
    });
  }

  public static async applyTransformationPipeline(
    model: GLTF,
    pipelineParameters: ThreeModelTransformationPipelineParameters
  ): Promise<void> {
    for (let index = 0; index < pipelineParameters.length; index++) {
      const parameters = pipelineParameters[index];

      await ThreeModelsService.applyTransformation(model, parameters);
    }
  }

  public static async applyTransformation(
    model: GLTF,
    parameters: ThreeModelTransformationParameters
  ): Promise<void> {
    const { id, transformationArguments } =
      typeof parameters === 'string'
        ? { id: parameters, transformationArguments: [] }
        : parameters;
    const transformation = ThreeModelsService.transformations.get(id);

    if (!transformation) {
      console.error(`Error due to unknown transformation with ID '${id}'.`);

      return Promise.reject();
    }

    try {
      const modelObject = getThreeModelObject(model);

      await transformation.apply(
        null,
        [modelObject].concat(transformationArguments as any) as [
          Object3D,
          ...any
        ]
      );
    } catch (error) {
      console.error(
        `Error occurred while invoking transformation with ID '${id}':`
      );
      console.error(error);

      return Promise.reject(error);
    }
  }
}
