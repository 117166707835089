import { FC, LegacyRef, useEffect, useRef, useContext } from 'react';
import { ThreeStatisticsContext } from '../../../contexts';
import { DebugModeMask } from '..';

import './DebugThreeStatistics.scss';

const DebugThreeStatisticsInner: FC = () => {
  const statistics = useContext(ThreeStatisticsContext);
  const containerRef = useRef<HTMLDivElement>();

  useEffect(() => {
    if (!containerRef.current) return;

    statistics.dom.style.position = 'absolute';

    containerRef.current.appendChild(statistics.dom);
  }, [containerRef.current]);

  return (
    <div
      ref={containerRef as LegacyRef<HTMLDivElement>}
      className="DebugThreeStatistics"
    />
  );
};

export const DebugThreeStatistics: FC = () => {
  return (
    <DebugModeMask>
      <DebugThreeStatisticsInner />
    </DebugModeMask>
  );
};
