// Redux
import { createSelector } from '@reduxjs/toolkit';

// Types
import { RootState } from '../store';

export const selectDebug = (state: RootState) => state.debug;

export const selectDebugMode = createSelector(
  selectDebug,
  (debugState) => debugState.debugMode
);
