import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LayoutAnchor } from '../types';

export type LayoutState = {
  layoutAnchorById: {
    [anchorId: string]: LayoutAnchor;
  };
};

const INITIAL_STATE: LayoutState = {
  layoutAnchorById: {},
};

export const layoutSlice = createSlice({
  name: 'layout',
  initialState: INITIAL_STATE,
  reducers: {
    setLayoutAnchorScreenPosition: (
      state,
      action: PayloadAction<LayoutAnchor>
    ) => {
      const { payload } = action;

      state.layoutAnchorById = {
        ...state.layoutAnchorById,
        [payload.id]: {
          ...state.layoutAnchorById[payload.id],
          ...payload,
        },
      };
    },
  },
});
