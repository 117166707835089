import { FC, ReactNode } from 'react';
import { standardMaterial } from '../../materials';
import {
  ThreeBox,
  ThreeTransdimensionalRect,
  TransdimensionalButtonThree,
  TransdimensionalButtonThreeBox,
} from '..';
import {
  getTransdimensionalTableHeadRectId,
  getTransdimensionalTableRowRectId,
} from './utilities';

export enum TransdimensionlTableVariant {
  TableContained = 'table-contained',
  RowContained = 'row-contained',
}

const TABLE_DEPTH = 0.25;
const TABLE_HEAD_DEPTH = 0;
const TABLE_ROW_DEPTH = 0.25;

export const TransdimensionalTableThree: FC<{
  variant: TransdimensionlTableVariant;
  id: string;
  rowIds: Set<string>;
}> = (props) => {
  const tableId = props.id;

  switch (props.variant) {
    case TransdimensionlTableVariant.TableContained: {
      const headRectId = getTransdimensionalTableHeadRectId(tableId);
      const rowElements: ReactNode[] = [];

      Array.from(props.rowIds).map((rowId, rowIndex) => {
        const rowRectId = getTransdimensionalTableRowRectId(tableId, rowId);

        rowElements.push(
          <ThreeTransdimensionalRect key={rowIndex} id={rowRectId}>
            <ThreeBox
              position={[0, 0, -(TABLE_DEPTH + TABLE_ROW_DEPTH) / 2]}
              scale={[1, 1, TABLE_DEPTH - TABLE_ROW_DEPTH]}
            />
          </ThreeTransdimensionalRect>
        );
        rowElements.push(
          <ThreeTransdimensionalRect
            key={`${rowIndex}-divider`}
            id={rowRectId}
            screenHeight={1}
            relative="bottom"
          >
            <ThreeBox
              position={[0, 0, -TABLE_ROW_DEPTH / 2]}
              scale={[1, 1, TABLE_ROW_DEPTH]}
            />
          </ThreeTransdimensionalRect>
        );
      });

      return (
        <>
          <ThreeTransdimensionalRect
            id={tableId}
            screenWidth={16}
            relative="left"
          >
            <ThreeBox
              position={[-1, 0, -(TABLE_HEAD_DEPTH + TABLE_DEPTH / 2)]}
              scale={[1, 1, TABLE_DEPTH - TABLE_HEAD_DEPTH]}
            />
          </ThreeTransdimensionalRect>
          <ThreeTransdimensionalRect
            id={tableId}
            screenWidth={16}
            relative="right"
          >
            <ThreeBox
              position={[1, 0, -(TABLE_HEAD_DEPTH + TABLE_DEPTH / 2)]}
              scale={[1, 1, TABLE_DEPTH - TABLE_HEAD_DEPTH]}
            />
          </ThreeTransdimensionalRect>
          <ThreeTransdimensionalRect id={headRectId}>
            <ThreeBox
              position={[0, 0, -(TABLE_HEAD_DEPTH + TABLE_DEPTH / 2)]}
              scale={[1, 1, TABLE_DEPTH - TABLE_HEAD_DEPTH]}
            />
          </ThreeTransdimensionalRect>
          {rowElements}
        </>
      );
    }
    case TransdimensionlTableVariant.RowContained: {
      const rowElements: ReactNode[] = [];

      Array.from(props.rowIds).map((rowId, rowIndex) => {
        const rowRectId = getTransdimensionalTableRowRectId(tableId, rowId);

        rowElements.push(
          <TransdimensionalButtonThree key={rowIndex} id={rowRectId}>
            <TransdimensionalButtonThreeBox colorProfile="default" />
            {/* <ThreeBox
              position={[0, 0, -TABLE_ROW_DEPTH / 2]}
              scale={[1, 1, TABLE_ROW_DEPTH]}
            /> */}
          </TransdimensionalButtonThree>
        );
      });

      return <>{rowElements}</>;
    }
    default:
      return null;
  }
};
