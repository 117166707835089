import { FC, ReactNode } from 'react';
import { Box, ListItem, SxProps, Theme, Typography } from '@mui/material';
import { NoWrap } from '..';

export const KeyValueListItem: FC<{
  label: ReactNode;
  value: ReactNode;
  valueSx?: SxProps<Theme>;
  disableGutters?: boolean;
}> = (props) => {
  return (
    <ListItem
      disableGutters={props.disableGutters}
      sx={{ display: 'flex', alignItems: 'center' }}
    >
      <Box
        component="div"
        sx={{
          marginRight: 1,
        }}
      >
        <NoWrap>
          <Typography variant="body2">{props.label}</Typography>
        </NoWrap>
      </Box>
      <Box component="div" sx={{ flexGrow: 1, textAlign: 'right' }}>
        <NoWrap>
          <Typography variant="body1" sx={props.valueSx}>
            {props.value}
          </Typography>
        </NoWrap>
      </Box>
    </ListItem>
  );
};
