import { configureStore } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import { THREE_MODELS } from './constants';
import {
  debugSlice,
  layoutSlice,
  threeModelsSlice,
  transdimensionalInteractionSlice,
} from './slices';
import { debugSaga, threeModelsSaga } from './sagas';
import { requestThreeModel } from './actions';

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer: {
    debug: debugSlice.reducer,
    layout: layoutSlice.reducer,
    threeModels: threeModelsSlice.reducer,
    transdimensionalInteraction: transdimensionalInteractionSlice.reducer,
  },
  middleware: [sagaMiddleware],
});

sagaMiddleware.run(debugSaga);
sagaMiddleware.run(threeModelsSaga);

export type RootState = ReturnType<typeof store.getState>;

export default store;

setTimeout(() => {
  Array.from(THREE_MODELS.entries()).forEach((entry) => {
    const [id, { url, transform }] = entry;
    const action = requestThreeModel({ id, url, transform });

    store.dispatch(action);
  });
}, 0);
