import { FC, useEffect, useState } from 'react';
import moment from 'moment';
import { Stack, Box, Button, useTheme } from '@mui/material';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';
import { CalendlyAvailableTime } from '../../types';

export const MeetingTimeSelection: FC<{
  clearSymbol?: Symbol;
  availableTimes: CalendlyAvailableTime[];
  onSelect: (time: CalendlyAvailableTime) => void;
}> = (props) => {
  const theme = useTheme();
  const [selectedStartTime, setSelectedStartTime] = useState<string | null>(
    null
  );
  const someStartTimeIsSelected = !!selectedStartTime;

  useEffect(() => {
    setSelectedStartTime(null);
  }, [props.clearSymbol]);

  return (
    <Stack direction="column" spacing={1} sx={{ margin: theme.spacing(2, 0) }}>
      {props.availableTimes.map((availableTime) => {
        const startTime = moment(availableTime.start_time);
        const availableTimeIsSelected =
          selectedStartTime === availableTime.start_time;

        return (
          <Box
            key={availableTime.scheduling_url}
            component="div"
            sx={{ position: 'relative' }}
          >
            <Button
              variant={
                availableTimeIsSelected
                  ? 'contained'
                  : someStartTimeIsSelected
                  ? 'outlined'
                  : 'contained'
              }
              color={availableTimeIsSelected ? 'secondary' : 'primary'}
              size="large"
              disableElevation
              onClick={() => {
                if (!availableTimeIsSelected) {
                  setSelectedStartTime(availableTime.start_time);
                } else {
                  setSelectedStartTime(null);
                }
              }}
              sx={{
                transition: theme.transitions.create(
                  ['width', 'border-color', 'background-color', 'color'],
                  {
                    duration: theme.transitions.duration.shortest,
                  }
                ),
                transitionDelay: theme.transitions.duration.shortest,
                width: '100%',
                height: 48,
                ...(availableTimeIsSelected && {
                  transitionDelay: '0s',
                  width: `calc((100% - ${theme.spacing(1)}) / 2)`,
                }),
              }}
            >
              {startTime.format('h:mm A')}
            </Button>
            <Button
              variant="contained"
              size="large"
              disableElevation
              onClick={() => props.onSelect(availableTime)}
              sx={{
                position: 'absolute',
                right: 0,
                width: `calc((100% - ${theme.spacing(1)}) / 2)`,
                height: 48,
                transition: theme.transitions.create('opacity', {
                  duration: theme.transitions.duration.shortest,
                }),
                transitionDelay: '0s',
                opacity: 0,
                pointerEvents: 'none',
                ...(availableTimeIsSelected && {
                  transitionDelay: theme.transitions.duration.shortest,
                  opacity: 1,
                  pointerEvents: 'default',
                }),
              }}
              endIcon={<ArrowForwardOutlinedIcon />}
            >
              Next
            </Button>
          </Box>
        );
      })}
    </Stack>
  );
};
