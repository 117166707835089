import { CONTAINER_WIDTH } from '../../constants';
import { useWindowSize } from '.';

export const useContainerWidth = () => {
  const windowSize = useWindowSize();

  if (windowSize.x > CONTAINER_WIDTH) {
    return CONTAINER_WIDTH;
  } else {
    return windowSize.x;
  }
};
