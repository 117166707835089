import { FC, PropsWithChildren, useState } from 'react';
import { ProgressContext } from '../../contexts';

export const ProgressManager: FC<PropsWithChildren<{}>> = (props) => {
  const [active, setActive] = useState(false);

  return (
    <ProgressContext.Provider
      value={{
        active,
        setActive,
      }}
    >
      {props.children}
    </ProgressContext.Provider>
  );
};
