// React
import { FC, PropsWithChildren, ReactNode } from 'react';
import classNames from 'classnames';
import { Typography, useTheme } from '@mui/material';
import { AWSCertificationLevel } from '../../types';
import { AWS_CERTIFICATION_LEVEL_LABELS } from '../../constants';
import { TransdimensionalRect } from '..';
import {
  getCertificationAwsBodyRectId,
  getCertificationAwsHeaderRectId,
} from './utilities';

export const CertificationAWS: FC<
  PropsWithChildren<{
    id: string;
    level: AWSCertificationLevel;
    label: ReactNode;
    log?: boolean;
  }>
> = ({ id, level, label, children, log }) => {
  const theme = useTheme();
  const headerRectId = getCertificationAwsHeaderRectId(id);
  const bodyRectId = getCertificationAwsBodyRectId(id);

  return (
    <TransdimensionalRect
      id={id}
      className={classNames('Certification', 'CertificationAWS')}
      log={log}
    >
      <TransdimensionalRect
        id={headerRectId}
        className="CertificaitonAWS-header"
        display="block"
        sx={{
          position: 'relative',
          paddingTop: '100%',
          textAlign: 'center',
        }}
        log={log}
      >
        <Typography
          variant="body1"
          fontWeight="bold"
          lineHeight="1.8rem"
          sx={{
            position: 'absolute',
            width: '80%',
            left: '10%',
            top: '22.5%',
          }}
        >
          {label}
        </Typography>
        <Typography
          variant="body2"
          sx={{
            position: 'absolute',
            width: '80%',
            left: '10%',
            top: '57.5%',
          }}
        >
          {AWS_CERTIFICATION_LEVEL_LABELS.get(level)}
        </Typography>
      </TransdimensionalRect>
      <TransdimensionalRect
        id={bodyRectId}
        className="Certification-body"
        display="block"
        sx={{
          padding: 1.5,
          paddingTop: 2,
        }}
        log={log}
      >
        {children}
      </TransdimensionalRect>
    </TransdimensionalRect>
  );
};
