// React
import { createContext } from 'react';

// Services
import { TransdimensionalUnitConversionService } from '../services';

const TransdimensionalUnitConversionServiceContext =
  createContext<TransdimensionalUnitConversionService | null>(null);
TransdimensionalUnitConversionServiceContext.displayName =
  'TransdimensionalUnitConversionServiceContext';

export { TransdimensionalUnitConversionServiceContext };
