// THREE.js
import { MathUtils } from 'three';

// Constants
import { THREE_DEFAULT_FOV, THREE_CAMERA_DEPTH } from '../../constants';

export const getVisibleHeightAtDepth = (
  fovDegrees: number = THREE_DEFAULT_FOV,
  depth: number = THREE_CAMERA_DEPTH
) => {
  const fovRadians = MathUtils.degToRad(fovDegrees);

  return 2 * Math.tan(fovRadians / 2) * Math.abs(depth);
};
