import { FC, PropsWithChildren } from 'react';
import { Box, Breakpoint, Container as MuiContainer } from '@mui/material';

export const Container: FC<
  PropsWithChildren<{
    maxWidth: Breakpoint;
    disableGutters?: boolean;
  }>
> = ({ maxWidth, disableGutters, children }) => {
  return (
    <Box component="div" sx={{ display: 'flex', justifyContent: 'center' }}>
      <MuiContainer
        maxWidth={maxWidth}
        sx={{
          position: 'relative',
          marginLeft: '',
          marginRight: '',
        }}
        disableGutters={disableGutters}
      >
        {children}
      </MuiContainer>
    </Box>
  );
};
