import { FC, cloneElement } from 'react';
import { Moment } from 'moment';
import { Button, ListItem, useTheme } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { MeetingLocation } from '../../constants';
import { enhance } from '../../utilities';
import { DynamicList, KeyValueListItem } from '..';
import { useMeetingLocationOptions } from './utilities';

export const MeetingDetailsList: FC<{
  active?: boolean;
  delay?: number;
  localeDate: Moment;
  startTime: Moment | null;
  showChangeTimeButton?: boolean;
  onChangeTime?: () => void;
  meetingLocation?: MeetingLocation;
}> = (props) => {
  const theme = useTheme();
  const meetingLocationOptions = useMeetingLocationOptions();
  const meetingLocationOption =
    props.meetingLocation &&
    meetingLocationOptions.find(
      (option) => option.value === props.meetingLocation
    );

  return (
    <DynamicList
      delay={props.delay}
      items={
        props.active
          ? [
              {
                key: 'date',
                height: 48,
                content: (
                  <KeyValueListItem
                    label="Date"
                    value={props.localeDate.format('dddd, MMMM Do')}
                    disableGutters
                  />
                ),
              },
              ...(props.startTime
                ? [
                    {
                      key: 'time',
                      height: 48,
                      content: (
                        <KeyValueListItem
                          label="Time"
                          value={props.startTime.format('h:mm A')}
                          disableGutters
                        />
                      ),
                    },
                    ...(props.showChangeTimeButton
                      ? [
                          {
                            key: 'edit-time',
                            height: 52.5 + 8,
                            content: (
                              <ListItem
                                disableGutters
                                sx={{ paddingBottom: 2 }}
                              >
                                <Button
                                  fullWidth
                                  variant="outlined"
                                  startIcon={<EditOutlinedIcon />}
                                  onClick={props.onChangeTime}
                                >
                                  Change Time
                                </Button>
                              </ListItem>
                            ),
                          },
                        ]
                      : []),
                  ]
                : []),
              ...(meetingLocationOption
                ? [
                    {
                      key: 'location',
                      height: 48,
                      content: (
                        <KeyValueListItem
                          label="Location"
                          value={
                            <>
                              {cloneElement(meetingLocationOption.icon, {
                                sx: {
                                  position: 'relative',
                                  top: 2,
                                  fontSize: 'inherit',
                                  marginRight: 0.5,
                                },
                              })}
                              {meetingLocationOption.label}
                            </>
                          }
                          valueSx={{
                            color: enhance(meetingLocationOption.color, 0.375),
                          }}
                          disableGutters
                        />
                      ),
                    },
                  ]
                : []),
            ]
          : []
      }
    />
  );
};
