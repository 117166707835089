import { FC } from 'react';
import { Container, Grid, Typography } from '@mui/material';

import {
  CertificationType,
  AWSCertificationType,
  AWSCertificationLevel,
  HashicorpCertificationType,
  HashicorpCertificationLevel,
} from '../../../../types';
import {
  CertificationAWS,
  CertificationGeneric,
  CertificationHashicorp,
  Heading,
  Section,
} from '../../..';
import { MainPageCertificationsSectionThree } from '.';

export const MainPageCertificationsSection: FC = () => {
  return (
    <Section
      id="section-certifications"
      three={<MainPageCertificationsSectionThree />}
    >
      <Container maxWidth="md">
        <Heading variant="h2" id="my-certifications">
          My Certifications
        </Heading>
        <Typography variant="h4" sx={{ marginBottom: 2 }}>
          Amazon Web Services
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={6} md={4}>
            <CertificationAWS
              id={`certifications-${CertificationType.AWS}-${AWSCertificationType.CloudPractitioner}-${AWSCertificationLevel.Foundational}`}
              level={AWSCertificationLevel.Foundational}
              label={
                <>
                  Cloud
                  <br />
                  Practitioner
                </>
              }
            >
              <Typography variant="body1">
                This certification validates cloud fluency and foundational AWS
                knowledge.
              </Typography>
            </CertificationAWS>
          </Grid>
          <Grid item sm={6} md={4}>
            <CertificationAWS
              id={`certifications-${CertificationType.AWS}-${AWSCertificationType.SolutionsArchitect}-${AWSCertificationLevel.Associate}`}
              level={AWSCertificationLevel.Associate}
              label={
                <>
                  Solutions
                  <br />
                  Architect
                </>
              }
            >
              <Typography variant="body1">
                This certification validates the ability to design and implement
                distributed systems on AWS.
              </Typography>
            </CertificationAWS>
          </Grid>
          <Grid item sm={6} md={4}>
            <CertificationAWS
              id={`certifications-${CertificationType.AWS}-${AWSCertificationType.DevOpsEngineer}-${AWSCertificationLevel.Professional}`}
              level={AWSCertificationLevel.Professional}
              label={
                <>
                  DevOps
                  <br />
                  Engineer
                </>
              }
            >
              <Typography variant="body1">
                This certification validates technical expertise in
                provisioning, operating, and managing distributed application
                systems AWS.
              </Typography>
            </CertificationAWS>
          </Grid>
        </Grid>
        <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 2 }}>
          HashiCorp
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={6} md={4}>
            <CertificationHashicorp
              id={`certifications-${CertificationType.Hashicorp}-${HashicorpCertificationType.Terraform}-${HashicorpCertificationLevel.Associate}`}
              type={HashicorpCertificationType.Terraform}
              level={HashicorpCertificationLevel.Associate}
              label="Associate"
            >
              <Typography variant="body1">
                This certification demonstrates understanding of the basic
                concepts and skills associated with HashiCorp Terraform.
              </Typography>
            </CertificationHashicorp>
          </Grid>
        </Grid>
        {/* <Typography variant="h4" sx={{ marginTop: 2, marginBottom: 2 }}>
          American Heart Association
        </Typography>
        <Grid container spacing={4}>
          <Grid item sm={6} md={4}>
            <CertificationGeneric
              id="certifications-aha-acls"
              label="Advanced Cardiovascular Life Support"
              level="Provider"
            >
              Test
            </CertificationGeneric>
          </Grid>
        </Grid> */}
      </Container>
    </Section>
  );
};
