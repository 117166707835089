import { FC, forwardRef } from 'react';
import {
  Typography,
  Box,
  SxProps,
  Theme,
  darken,
  useTheme,
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import { TransdimensionalButton } from '..';
import { enhance } from '../../utilities';

export const MeetingCreationCalendarDate = forwardRef<
  HTMLButtonElement,
  {
    date: number;
    isSelected: boolean;
    isToday: boolean;
    hasAvailableTimes: boolean;
    sx?: SxProps<Theme>;
    onClick?: () => void;
  }
>((props, ref) => {
  const theme = useTheme();

  return (
    <TransdimensionalButton
      id={props.date.toString()}
      disabled={!props.hasAvailableTimes}
      ref={ref}
      sx={[
        {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          textAlign: 'center',
          position: 'relative',
          // transition: theme.transitions.create('background-color'),
          ...(props.isSelected && {
            backgroundColor: theme.palette.secondary.main,
            '&:hover': {
              backgroundColor: theme.palette.secondary.dark,
            },
          }),
        },
        ...(Array.isArray(props.sx) ? props.sx : [props.sx]),
      ]}
      onClick={() => {
        if (!props.hasAvailableTimes) return;

        props.onClick?.();
      }}
    >
      <Box
        component="div"
        sx={{
          position: 'absolute',
          backgroundColor: theme.palette.text.primary,
          opacity: 0,
          transition: theme.transitions.create('opacity'),
          [theme.breakpoints.up('sm')]: {
            left: theme.spacing(1.5),
            top: theme.spacing(1.5),
            width: `calc(100% - 2 * ${theme.spacing(1.5)})`,
            height: `calc(100% - 2 * ${theme.spacing(1.5)})`,
          },
          [theme.breakpoints.down('sm')]: {
            left: '12.5%',
            top: '12.5%',
            width: '75%',
            height: '75%',
          },
          ...(props.hasAvailableTimes && {
            opacity: 1,
          }),
        }}
      />
      <Typography
        variant="body1"
        fontSize="1rem"
        fontWeight="bold"
        sx={{
          position: 'relative',
          transition: theme.transitions.create('color'),
          ...(props.hasAvailableTimes
            ? {
                color: theme.palette.getContrastText(
                  theme.palette.text.primary
                ),
              }
            : {
                color: theme.palette.text.secondary,
              }),
        }}
      >
        {props.date}
        {props.isToday && (
          <CircleIcon
            sx={{
              position: 'absolute',
              left: 'calc((100% - 5px) / 2)',
              bottom: 'calc((100% - 1rem) / 2 - 8px)',
              width: 5,
              height: 5,
              fontSize: '5px',
              [theme.breakpoints.down('sm')]: {
                display: 'none',
              },
            }}
          />
        )}
      </Typography>
    </TransdimensionalButton>
  );
});
