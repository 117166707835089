import { FC, PropsWithChildren, ReactNode, createElement } from 'react';
import classNames from 'classnames';
import { Box, Stack, SxProps, Theme, useTheme } from '@mui/material';
import { NoWrap, TransdimensionalRect } from '..';
import './Chip.scss';

type ChipProps = PropsWithChildren<{
  id?: string;
  className?: string;
  component?: keyof JSX.IntrinsicElements;
  variant?: 'round' | 'square';
  background?: string;
  textColor?: string;
  startAdornment?: ReactNode;
  endAdornment?: ReactNode;
  sx?: SxProps<Theme>;
}>;

export const Chip: FC<ChipProps> = ({
  id,
  className,
  component,
  startAdornment,
  endAdornment,
  sx,
  ...props
}) => {
  const theme = useTheme();
  const variant = props.variant ?? 'square';
  const background = props.background ?? theme.palette.background.chip;
  const textColor =
    props.textColor ?? theme.palette.getContrastText(background);
  const children = (
    <NoWrap sx={{ display: 'inline-block', padding: theme.spacing(0, 0.75) }}>
      <Stack direction="row" spacing={0.5} sx={{ alignItems: 'center' }}>
        {startAdornment}
        <Box component="span">{props.children}</Box>
        {endAdornment}
      </Stack>
    </NoWrap>
  );

  return (
    <Box
      component="div"
      className={classNames('Chip', className, {
        'Chip-variantSquare': variant === 'square',
        'Chip-variantRound': variant === 'round',
      })}
      sx={[
        {
          background,
          color: textColor,
        },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      {id ? (
        <TransdimensionalRect id={id} display="inline-block">
          {children}
        </TransdimensionalRect>
      ) : (
        children
      )}
    </Box>
  );
};
