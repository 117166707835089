import {
  BRAND_COLOR_RED,
  BRAND_COLOR_ORANGE,
  BRAND_COLOR_YELLOW_DARK,
} from '../../constants';
import { Project } from '../../types';

export const PROJECT_SAMUELGOODELL_PORTFOLIO_WEB: Project = {
  id: '@samuelgoodell/portfolio-web',
  name: 'Portfolio',
  description:
    'My website including professional information, personal tools, and reference pages',
  status: 'active-development',
  chip: {
    background: `linear-gradient(135deg, ${BRAND_COLOR_RED} 0%, ${BRAND_COLOR_ORANGE} 50%, ${BRAND_COLOR_YELLOW_DARK} 100%)`,
    color: 'rgb(255, 255, 255)',
    content: '@samuelgoodell/portfolio-web',
  },
  page: {
    tabs: [
      {
        label: 'Overview',
        page: {
          markdown: `\
## Objectives

- Showcase my professional skills, relevant projects, and technical interests in order to advance my career
- Provide a platform for my personal tools and reference pages
- Serve as a testing ground for new technologies and techniques

## Status

This project is in __Active Development__ as I continue my search for a new role in 2023.

## Roadmap

- Integrate [meeting creation](/meet) using Calendly
- Complete detailed [reference pages](/reference)
- Add a [blog](/blog)
`,
        },
      },
    ],
  },
};
