import { FC, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import simplePlantUML from '@akebifiky/remark-simple-plantuml';
import remarkMath from 'remark-math';
import rehypeKatex from 'rehype-katex';
import 'katex/dist/katex.min.css';
import { Tab, Tabs, Typography } from '@mui/material';
import { PresentationPage as PresentationPageType } from '../../types';
import { InlineLink } from '../inline-link';

export const PresentationPage: FC<{
  presentationPage: PresentationPageType;
}> = ({ presentationPage }) => {
  const [tabIndex, setTabIndex] = useState(0);

  return (
    <>
      {presentationPage.tabs && (
        <>
          <Tabs
            value={tabIndex}
            onChange={() => console.log('changed tab')}
            sx={{ marginBottom: 1 }}
          >
            {presentationPage.tabs.map((tab, tabIndex) => (
              <Tab key={tabIndex} label={tab.label} />
            ))}
          </Tabs>
          <PresentationPage
            presentationPage={presentationPage.tabs[tabIndex].page}
          />
        </>
      )}
      {presentationPage.markdown && (
        <ReactMarkdown
          remarkPlugins={[
            [
              simplePlantUML as any,
              { baseUrl: 'https://www.plantuml.com/plantuml/svg' },
            ],

            remarkMath,
          ]}
          rehypePlugins={[
            [
              rehypeKatex,
              {
                strict: (errorCode: string, errorMsg: any, token: string) => {
                  console.log('strict callback', errorCode);
                  switch (errorCode) {
                    case 'htmlExtension':
                      console.log('html extension, ignore');
                      // return false;
                      return 'ignore';
                    default:
                      return 'error';
                  }
                },
                trust: true,
              },
            ],
          ]}
          components={{
            ...(['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] as const).reduce(
              (components, variant) => ({
                ...components,
                [variant]: ({ node, ...props }: any) => {
                  return <Typography variant={variant} {...props} />;
                },
              }),
              {}
            ),
            p: ({ node, ...props }) => {
              return <Typography variant="body1" {...props} />;
            },
            a: ({ node, ...props }) => {
              return <InlineLink to={props.href ?? ''} {...props} />;
            },
            img: ({ node, ...props }) => {
              return <img {...props} style={{ maxWidth: '100%' }} />;
            },
          }}
        >
          {presentationPage.markdown}
        </ReactMarkdown>
      )}
    </>
  );
};
