import {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { TransitionGroup } from 'react-transition-group';
import { useInView } from 'react-intersection-observer';
import { useSearchParams } from 'react-router-dom';
import {
  PopupModal as CalendlyPopupModal,
  useCalendlyEventListener,
} from 'react-calendly';
import moment from 'moment';
import _ from 'lodash';
import {
  Box,
  Grid,
  Stack,
  SxProps,
  Theme,
  Typography,
  useTheme,
} from '@mui/material';

import {
  DARK_THEME,
  ABBREVIATED_DAY_NAMES,
  CALENDLY_INTRODUCTION_EVENT_TYPE_URI,
  CALENDLY_PAGE_SETTINGS,
  DAYS_IN_WEEK,
} from '../../constants';
import { CalendlyAvailableTime } from '../../types';
import { ProgressContext } from '../../contexts';
import { colorToCalendlyColor } from '../../utilities';
import { useEnvironment } from '../../hooks';
import { HeightMaintainer } from '..';
import {
  MeetingTypeDetails,
  MeetingCreationFormValues,
  MeetingConfirmation,
  MeetingCreationDateDialog,
  MeetingCreationCalendar,
  MeetingCreationCalendarDate,
  MeetingDetailsList,
} from '.';
import { useMeetingLocationOptions, useSearchParamsDate } from './utilities';
import './MeetingCreation.scss';

export const MeetingCreation: FC = () => {
  const {
    ref: inViewRef,
    inView,
    entry,
  } = useInView({
    threshold: [0, 1],
    initialInView: false,
    trackVisibility: true,
    delay: 100,
    fallbackInView: true,
  });

  const localeNow = moment();
  const localeMinimumDate = localeNow.clone();
  const localeMaximumDate = localeNow.clone().add(2, 'weeks');
  const [searchParams, setSearchParams] = useSearchParams();
  const { year, month, date } = useSearchParamsDate(searchParams);
  const localeDate = moment({ year, month, date: date ?? undefined });
  const localeMonthStart = localeDate.clone().startOf('month');
  const localeMonthEnd = localeDate.clone().endOf('month');

  const { calendlyProxyClient, localeTimeZoneName } = useEnvironment();
  const progressContext = useContext(ProgressContext);
  const theme = useTheme();

  const [isCalendlyModalOpen, setIsCalendlyModalOpen] = useState(false);
  const [formValues, setFormValues] =
    useState<MeetingCreationFormValues | null>(null);
  const meetingLocationOptions = useMeetingLocationOptions();
  const meetingLocationOption =
    formValues?.meetingLocation &&
    meetingLocationOptions.find(
      (option) => option.value === formValues.meetingLocation
    );

  const [availableTimes, setAvailableTimes] = useState<CalendlyAvailableTime[]>(
    []
  );
  const getAvailableTimesDebounceRef = useRef<symbol>(Symbol());

  useEffect(() => {
    (async () => {
      if (!inView) return;

      progressContext.setActive(true);

      const debounce = (getAvailableTimesDebounceRef.current = Symbol());
      const availableTimes = await calendlyProxyClient.getAvailableTimes(
        CALENDLY_INTRODUCTION_EVENT_TYPE_URI,
        {
          start: localeMonthStart,
          end: localeMonthEnd,
        }
      );

      if (debounce !== getAvailableTimesDebounceRef.current) return;

      setAvailableTimes(availableTimes);

      progressContext.setActive(false);
    })();
  }, [inView, year, month]);

  const getLocaleDateAvailableTimes = (
    year: number,
    month: number,
    date: number
  ) => {
    const localeDate = moment({ year, month, date });
    const localeDateStart = localeDate.clone().startOf('day');
    const localeDateEnd = localeDate.clone().endOf('day');

    return (availableTimes ?? []).filter(
      (availableTime) =>
        localeDateStart.isBefore(availableTime.start_time) &&
        localeDateEnd.isAfter(availableTime.start_time)
    );
  };

  const selectedDateKey = date ? localeDate.format('YYYY-MM-DD') : null;
  const isDateDialogOpen = !!date;

  const dayBoxSx: SxProps<Theme> = {
    width: `calc((100% - ${theme.spacing(1.5)} * ${
      DAYS_IN_WEEK - 1
    }) / ${DAYS_IN_WEEK})`,
    aspectRatio: '1 / 1',
  };
  const dayElements: ReactElement[] = [];
  const dayElementsRef = useRef(
    new Map<string /* YYYY-MM-DD */, HTMLButtonElement>()
  );
  const firstDayOfMonthDayOfWeek = localeMonthStart
    .clone()
    .startOf('month')
    .day();

  for (
    let dayOfWeekIndex = 0;
    dayOfWeekIndex < firstDayOfMonthDayOfWeek;
    dayOfWeekIndex++
  ) {
    dayElements.push(
      <Box key={`-${dayOfWeekIndex}`} component="div" sx={dayBoxSx}></Box>
    );
  }

  for (let date = 1; date <= localeDate.daysInMonth(); date++) {
    const localeDate = moment({ year, month, date });
    const key = localeDate.format('YYYY-MM-DD');

    const dateIsSelected = key === selectedDateKey;
    const dateIsToday = localeDate.isSame(localeNow, 'day');
    const dateAvailableTimes = getLocaleDateAvailableTimes(year, month, date);
    const dateHasAvailableTimes = dateAvailableTimes.length > 0;

    dayElements.push(
      <MeetingCreationCalendarDate
        key={key}
        ref={(element: HTMLButtonElement) => {
          dayElementsRef.current.set(key, element);
        }}
        date={date}
        isSelected={dateIsSelected}
        isToday={dateIsToday}
        hasAvailableTimes={dateHasAvailableTimes}
        sx={dayBoxSx}
        onClick={() => {
          setSearchParams((searchParams) => {
            searchParams.set('date', key);

            return searchParams;
          });
        }}
      />
    );
  }

  const selectedAvailableTime =
    (formValues?.startTime &&
      availableTimes.find(
        (availableTime) => availableTime.start_time === formValues.startTime
      )) ??
    null;

  useEffect(() => {
    if (!inView) {
      setSearchParams((searchParams) => {
        searchParams.delete('month');
        searchParams.delete('date');

        return searchParams;
      });
    }
  }, [inView]);

  const [isMeetingCreated, setIsMeetingCreated] = useState(false);

  useCalendlyEventListener({
    onProfilePageViewed: () => {
      console.warn(
        'Received "onProfilePageViewed" event from Calendly embed, this should not be possible with the provided configuration.'
      );
    },
    onDateAndTimeSelected: () => {
      console.warn(
        'Received "onDateAndTimeSelected" event from Calendly embed, the user likely selected a different available time.'
      );
    },
    onEventTypeViewed: () => {
      console.warn(
        'Received "onEventTypeViewed" event from Calendly embed, the user will likely select a different available time.'
      );

      setIsCalendlyModalOpen(false);
    },
    onEventScheduled: (event) => {
      setIsMeetingCreated(true);
      setSearchParams((searchParams) => {
        searchParams.delete('month');
        searchParams.delete('date');

        return searchParams;
      });
      setIsCalendlyModalOpen(false);
    },
  });

  const confirmationElementRef = useRef<HTMLDivElement>(null);

  return (
    <>
      <Grid ref={inViewRef} container spacing={4}>
        <Grid item xs={12} md={4}>
          <MeetingTypeDetails />
        </Grid>
        <Grid item xs={12} md={8}>
          <HeightMaintainer sx={{ position: 'relative' }}>
            {({ measureRef }) => (
              <>
                <Box
                  ref={(element: HTMLDivElement) => {
                    if (!isMeetingCreated) {
                      measureRef(element);
                    }
                  }}
                  component="div"
                  sx={{ position: 'relative' }}
                >
                  <MeetingCreationCalendar
                    month={month}
                    year={year}
                    canNavigatePreviousMonth={localeMonthStart.isAfter(
                      localeMinimumDate
                    )}
                    canNavigateNextMonth={localeMonthEnd.isBefore(
                      localeMaximumDate
                    )}
                    onNavigatePreviousMonth={() => {
                      setSearchParams((searchParams) => {
                        searchParams.set(
                          'month',
                          localeDate
                            .clone()
                            .subtract(1, 'month')
                            .format('YYYY-MM')
                        );

                        return searchParams;
                      });
                    }}
                    onNavigateNextMonth={() => {
                      setSearchParams((searchParams) => {
                        searchParams.set(
                          'month',
                          localeDate.clone().add(1, 'month').format('YYYY-MM')
                        );

                        return searchParams;
                      });
                    }}
                  >
                    <Stack direction="column" spacing={1.5}>
                      <Stack
                        direction="row"
                        spacing={1.5}
                        sx={{
                          position: 'relative',
                        }}
                      >
                        {ABBREVIATED_DAY_NAMES.map(
                          (abbreviatedDayName, dayOfWeekIndex) => (
                            <Box
                              key={dayOfWeekIndex}
                              component="div"
                              sx={{
                                width: `calc(100% / ${DAYS_IN_WEEK})`,
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="body2">
                                {abbreviatedDayName}
                              </Typography>
                            </Box>
                          )
                        )}
                      </Stack>
                      {_.chunk(dayElements, DAYS_IN_WEEK).map(
                        (weekDayElements, weekIndex) => (
                          <Stack key={weekIndex} direction="row" spacing={1.5}>
                            {weekDayElements}
                          </Stack>
                        )
                      )}
                    </Stack>
                    <Typography variant="body1" sx={{ marginTop: 1 }}>
                      Times are shown in your local <u>{localeTimeZoneName}</u>{' '}
                      ({localeNow.format('h:mm A')}).
                    </Typography>
                  </MeetingCreationCalendar>
                </Box>
                <MeetingConfirmation
                  ref={(element) => {
                    if (confirmationElementRef instanceof Function) {
                      confirmationElementRef(element);
                    }

                    if (isMeetingCreated) {
                      measureRef(element);
                    }
                  }}
                  active={isMeetingCreated}
                  formValues={formValues}
                />
              </>
            )}
          </HeightMaintainer>
        </Grid>
      </Grid>
      <TransitionGroup>
        {isDateDialogOpen && (
          <MeetingCreationDateDialog
            key={selectedDateKey}
            onClose={() =>
              setSearchParams((searchParams) => {
                searchParams.delete('date');

                return searchParams;
              })
            }
            originElement={() => {
              if (isMeetingCreated) {
                return confirmationElementRef.current ?? null;
              } else if (selectedDateKey) {
                return dayElementsRef.current.get(selectedDateKey) ?? null;
              }

              return null;
            }}
            localeDate={localeDate}
            availableTimes={
              date ? getLocaleDateAvailableTimes(year, month, date) : []
            }
            onSubmit={(formValues) => {
              setFormValues(formValues);
              setIsCalendlyModalOpen(true);
            }}
          />
        )}
      </TransitionGroup>
      {selectedAvailableTime && (
        <CalendlyPopupModal
          url={selectedAvailableTime.scheduling_url}
          pageSettings={{
            ...CALENDLY_PAGE_SETTINGS,
            backgroundColor: colorToCalendlyColor(
              DARK_THEME.palette.background.paper
            ),
            textColor: colorToCalendlyColor(DARK_THEME.palette.text.primary),
            primaryColor: colorToCalendlyColor(
              DARK_THEME.palette.secondary.main
            ),
          }}
          prefill={{
            name: formValues?.name,
            email: formValues?.email,
            location: meetingLocationOption?.calendlyLocationValue ?? undefined,
            customAnswers: {},
          }}
          onModalClose={() => {
            setIsCalendlyModalOpen(false);
          }}
          open={isCalendlyModalOpen}
          rootElement={document.getElementById('embed-root') as HTMLElement}
        />
      )}
      <Box
        component="div"
        sx={{
          position: 'fixed',
          left: 0,
          top: 0,
          width: '100vw',
          height: '100vh',
          zIndex: 9995,
          pointerEvents: 'none',
          transition: theme.transitions.create([
            'background-color',
            'backdrop-filter',
          ]),
          ...(isCalendlyModalOpen
            ? {
                backdropFilter: 'blur(5px)',
                backgroundColor: 'rgba(0, 0, 0, 0.25)',
              }
            : {
                backdropFilter: 'none',
                backgroundColor: 'transparent',
              }),
        }}
      />
    </>
  );
};
