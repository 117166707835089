import { Box, useTheme } from '@mui/material';
import { FC, ReactNode } from 'react';

export const Slide: FC<{
  activeIndex: number;
  renderIndex: (index: number) => ReactNode;
}> = (props) => {
  const theme = useTheme();
  const previousIndex = props.activeIndex - 1;
  const nextIndex = props.activeIndex + 1;

  const renderIndexWithOffset = (index: number) => {
    const indexOffset = index - props.activeIndex;

    return (
      <Box
        key={index}
        component="div"
        sx={{
          position: 'absolute',
          width: '100%',
          maxHeight: '100%',
          overflow: 'auto',
          transform: `translateX(${indexOffset * 100}%)`,
          transition: theme.transitions.create('transform'),
        }}
      >
        {props.renderIndex(index)}
      </Box>
    );
  };

  return (
    <Box
      component="div"
      sx={{
        position: 'relative',
        width: '100%',
        height: '100%',
        overflow: 'hidden',
      }}
    >
      {renderIndexWithOffset(previousIndex)}
      {renderIndexWithOffset(props.activeIndex)}
      {renderIndexWithOffset(nextIndex)}
    </Box>
  );
};
