import { FC, PropsWithChildren } from 'react';
import { LinearProgress, useTheme } from '@mui/material';

import './ApplicationLoadingContainer.scss';

export const ApplicationLoadingContainer: FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const theme = useTheme();

  return <>{children}</>;
};
