import { FC, ReactElement, ReactNode, cloneElement, useContext } from 'react';
import { Box, Button, Typography, alpha, useTheme } from '@mui/material';
import { enhance } from '../../utilities';
import { SelectionOutline } from '..';
import { ButtonSelectionContext } from '.';

export const ButtonSelectionButton: FC<{
  value: string;
  color: string;
  icon: ReactElement<any, any>;
  label: ReactNode;
}> = (props) => {
  const theme = useTheme();
  const context = useContext(ButtonSelectionContext);
  const isSelected = context.value === props.value;

  return (
    <Box component="div" sx={{ position: 'relative', aspectRatio: '1 / 1' }}>
      <SelectionOutline active={isSelected}>
        <Button
          type="button"
          variant="contained"
          fullWidth
          disableElevation
          sx={{
            aspectRatio: '1 / 1',
            width: '100%',
            height: '100%',
            backgroundColor: alpha(props.color, 0.25),
            color: enhance(props.color, 0.625),
            '&:hover': {
              backgroundColor: alpha(props.color, 0.375),
              color: enhance(props.color, 0.8),
            },
          }}
          onClick={() => {
            if (isSelected) {
              context.onChange(null);
            } else {
              context.onChange(props.value);
            }
          }}
        >
          <Box
            component="div"
            sx={{
              position: 'absolute',
              left: 'calc((100% - 48px) / 2)',
              top: `calc((100% - 48px - ${theme.spacing(4)}) / 2)`,
              width: 48,
              height: 48,
            }}
          >
            {cloneElement(props.icon, { sx: { fontSize: 48 } })}
          </Box>
          <Typography
            variant="body2"
            color="inherit"
            lineHeight="1.25"
            sx={{
              position: 'relative',
              top: `calc((48px  + ${theme.spacing(1.5)}) / 2)`,
            }}
          >
            {props.label}
          </Typography>
        </Button>
      </SelectionOutline>
    </Box>
  );
};
