import { FC } from 'react';
import {
  PageHeaderThree,
  Three,
  ThreeLighting,
  ThreeScrollOffsetContainer,
} from '../../..';

export const ReferencePageIntroductionSectionThree: FC = () => {
  return (
    <Three
      id="referencePage_introductionSection-three"
      interactive={false}
      sx={{
        position: 'absolute',
        width: '100%',
        height: 'calc(100% + 352px)',
      }}
    >
      <ThreeScrollOffsetContainer>
        <ThreeLighting />
        <PageHeaderThree variant="search" />
      </ThreeScrollOffsetContainer>
    </Three>
  );
};
