import { FC, PropsWithChildren } from 'react';
import classNames from 'classnames';
import { Box, InputBase, InputAdornment, useTheme } from '@mui/material';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';

import { Logo, TransdimensionalRect } from '..';
import { PAGE_HEADER_SEARCH_RECT_ID } from './constants';

import './PageHeader.scss';

export type PageHeaderVariant = 'search';

export const PageHeader: FC<
  PropsWithChildren<{
    variant?: PageHeaderVariant;
    className?: string;
  }>
> = (props) => {
  const theme = useTheme();

  return (
    <Box
      className={classNames('PageHeader-root', props.className)}
      component="div"
      sx={{
        display: 'flex',
        padding: theme.spacing(1.5, 0),
      }}
    >
      <Box
        className="PageHeader-side"
        component="div"
        sx={{
          display: 'block',
          height: 60,
          flexShrink: 0,
          paddingRight: 2,
        }}
      >
        <Logo height={60} />
      </Box>
      <Box
        className="PageHeader-main"
        component="div"
        sx={{
          position: 'relative',
          flexGrow: 1,
        }}
      >
        {(() => {
          switch (props.variant) {
            case 'search':
              return (
                <TransdimensionalRect
                  id={PAGE_HEADER_SEARCH_RECT_ID}
                  sx={{
                    height: 48,
                    width: 240,
                    position: 'absolute',
                    top: `calc((100% - ${'48px'}) / 2)`,
                    right: 0,
                  }}
                >
                  <InputBase
                    size="medium"
                    placeholder="Search..."
                    fullWidth
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchOutlinedIcon />
                      </InputAdornment>
                    }
                    sx={{
                      margin: 1,
                    }}
                  />
                  <Box
                    component="div"
                    sx={{
                      position: 'absolute',
                      right: theme.spacing(2),
                      top: `calc((100% - 24px) / 2)`,
                      width: 'fit-content',
                      height: 24,
                      padding: theme.spacing(0, 0.5),
                      lineHeight: '22px',
                      pointerEvents: 'none',
                      color: theme.palette.text.secondary,
                      border: '1px solid',
                      borderColor: theme.palette.text.secondary,
                      borderRadius: '4px',
                    }}
                  >
                    <Box component="span" sx={{}}>
                      ⌘
                    </Box>
                    K
                  </Box>
                </TransdimensionalRect>
              );
            default:
              return props.children;
          }
        })()}
      </Box>
    </Box>
  );
};
