import { FC } from 'react';

export const ThreeLighting: FC = () => {
  return (
    <>
      <ambientLight intensity={0.675} />
      <directionalLight color="white" position={[10, 5, 7.5]} intensity={0.4} />
    </>
  );
};
