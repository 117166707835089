// Types
import { LayoutAnchor, LayoutPointExpressionVariables } from '../../types';

export const getLayoutPointExpressionVariables = (layoutAnchors: {
  [anchorId: string]: LayoutAnchor;
}): LayoutPointExpressionVariables => {
  const variables: LayoutPointExpressionVariables = {
    WINDOW_INNERWIDTH: window.innerWidth,
    WINDOW_INNERHEIGHT: window.innerHeight,
  };

  return Array.from(Object.entries(layoutAnchors)).reduce(
    (variables: LayoutPointExpressionVariables, entry) => {
      const anchorId = entry[0];
      const { x, y } = entry[1];

      variables[anchorId] = screenY;
      variables[anchorId + '_x'] = x;
      variables[anchorId + '_y'] = y;

      return variables;
    },
    variables
  );
};
