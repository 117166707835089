import { FC, Fragment, ReactElement, ReactNode, cloneElement } from 'react';
import {
  Box,
  Stack,
  TableCell,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useSpacing } from '../../../hooks';
import {
  Chip,
  Section,
  PageHeader,
  Breadcrumbs,
  Container,
  PresentationChip,
  ProjectStatusChip,
  TransdimensionalOffset,
  TransdimensionalRect,
  TransdimensionalTable,
  TABLE_ROW_DIVIDER_HEIGHT,
  NoWrap,
  PageTitle,
} from '../..';
import { StatusPageThree } from '.';
import ServerOutlinedIcon from '../../../@mui/icons-material/ServerOutlined';
import NasOutlinedIcon from '../../../@mui/icons-material/NasOutlined';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import { PresentationChip as PresentationChipType } from '../../../types';
import { BRAND_COLOR_GREEN } from '../../../constants';

enum ServiceItemType {
  Host = 'host',
  ContainerHost = 'container-host',
  ContainerGroup = 'container-group',
  Container = 'container',
  Proxy = 'proxy',
  NetworkStorage = 'network-storage',
}

const SERVICE_ITEM_TYPE_LABELS = new Map<
  ServiceItemType,
  { icon: ReactElement; text: ReactNode }
>([
  [ServiceItemType.Host, { icon: <ServerOutlinedIcon />, text: 'Host' }],
  [
    ServiceItemType.ContainerHost,
    { icon: <ServerOutlinedIcon />, text: 'Container Host' },
  ],
  [
    ServiceItemType.ContainerGroup,
    { icon: <ServerOutlinedIcon />, text: 'Container Group' },
  ],
  [
    ServiceItemType.Container,
    { icon: <ServerOutlinedIcon />, text: 'Container' },
  ],
  [ServiceItemType.Proxy, { icon: <ServerOutlinedIcon />, text: 'Proxy' }],
  [
    ServiceItemType.NetworkStorage,
    { icon: <NasOutlinedIcon />, text: 'Network Storage' },
  ],
]);

const ServiceItemTypeLabel: FC<{
  type: ServiceItemType;
}> = ({ type }) => {
  const theme = useTheme();
  const labelData = SERVICE_ITEM_TYPE_LABELS.get(type);

  if (!labelData) return null;

  const { icon, text } = labelData;

  return (
    <Stack direction="row" spacing={0.5} alignItems="center">
      {cloneElement(icon, {
        sx: { fontSize: 'inherit' },
      })}
      <Typography variant="body1">
        <NoWrap>{text}</NoWrap>
      </Typography>
    </Stack>
  );
};

interface ServiceItem {
  id: string;
  chip: PresentationChipType;
  type: ServiceItemType;
  description: ReactNode;
  children?: ServiceItem[];
}

const SERVICE_ITEMS: ServiceItem[] = [
  {
    id: '@local/homebridge',
    chip: {
      background: 'linear-gradient(135deg, red 0%, orange 100%)',
      color: 'white',
      content: '@local/homebridge',
    },
    type: ServiceItemType.Container,
    description: 'Homebridge instance for most home automation functionality',
  },
  {
    id: '@local/airblock',
    chip: {
      background: 'linear-gradient(135deg, red 0%, orange 100%)',
      color: 'white',
      content: '@local/airblock',
    },
    type: ServiceItemType.Proxy,
    description: 'Instance of AirBlock Manager for white noise in my home',
  },
  {
    id: '@local/docker',
    chip: {
      background: 'linear-gradient(135deg, red 0%, orange 100%)',
      color: 'white',
      content: '@local/docker',
    },
    type: ServiceItemType.ContainerHost,
    description: 'Local Docker engine for running containers',
    children: [
      {
        id: '@local/proxy',
        chip: {
          background: 'linear-gradient(135deg, red 0%, orange 100%)',
          color: 'white',
          content: '@local/proxy',
        },
        type: ServiceItemType.Proxy,
        description: 'Reverse proxy for local services',
      },
      {
        id: '@local/homebridge-multibridge',
        chip: {
          background: 'linear-gradient(135deg, red 0%, orange 100%)',
          color: 'white',
          content: '@local/homebridge-multibridge',
        },
        type: ServiceItemType.ContainerGroup,
        description:
          'Homebridge multi-bridge composition for faster responsiveness and better isolation between bridges',
        children: [
          {
            id: '@local/homebridge-camera-ui',
            chip: {
              background: 'linear-gradient(135deg, red 0%, orange 100%)',
              color: 'white',
              content: '@local/homebridge-camera-ui',
            },
            type: ServiceItemType.Container,
            description: 'Homebridge child bridge for Camera UI',
          },
          {
            id: '@local/homebridge-zing',
            chip: {
              background: 'linear-gradient(135deg, red 0%, orange 100%)',
              color: 'white',
              content: '@local/homebridge-zing',
            },
            type: ServiceItemType.Container,
            description: 'Homebridge child bridge for Zing smart night lights',
          },
        ],
      },
    ],
  },
  {
    id: '@local/samba',
    chip: {
      background: 'linear-gradient(135deg, red 0%, orange 100%)',
      color: 'white',
      content: '@local/samba',
    },
    type: ServiceItemType.NetworkStorage,
    description: 'Network-attached block storage',
  },
];

interface HierarchyItemContext {
  depth: number;
  isRoot: boolean;
  isParent: boolean;
  isNotLastDescendantAt: Set<number>;
  isLastChild: boolean;
}

interface HierarchyItem<T> {
  value: T;
  context: HierarchyItemContext;
}

const flattenServiceItem = (
  serviceItem: ServiceItem,
  parentContext?: HierarchyItemContext,
  isLastChild: boolean = false
): HierarchyItem<ServiceItem>[] => {
  const depth = parentContext ? parentContext.depth + 1 : 0;
  const children = serviceItem.children ?? [];
  const isNotLastDescendantAt = new Set(parentContext?.isNotLastDescendantAt);

  if (depth > 0 && !isLastChild) {
    isNotLastDescendantAt.add(depth);
  }

  const context = {
    depth,
    isRoot: !parentContext,
    isParent: children.length > 0,
    isNotLastDescendantAt,
    isLastChild,
  };

  return [
    {
      value: serviceItem,
      context,
    },
    ...children
      .map((childServiceItem, index) =>
        flattenServiceItem(
          childServiceItem,
          context,
          index + 1 === children.length
        )
      )
      .flat(),
  ];
};

const HierarchyItemIndicator: FC<{
  itemHeight?: string;
  itemDividerHeight?: string;
  context: HierarchyItemContext;
}> = ({ context, ...props }) => {
  const itemHeight = props.itemHeight ?? '100%';
  const itemDividerHeight = props.itemDividerHeight ?? '0px';
  const theme = useTheme();
  const spacing = useSpacing();
  const depthSpacing = theme.spacing(2);

  return (
    <>
      {context.isRoot && (
        <Box
          component="div"
          sx={{
            position: 'absolute',
            left: 0,
            top: `calc((${itemHeight} - 20px) / 2)`,
            width: 20,
            height: 20,
            backgroundColor: theme.palette.divider,
          }}
        />
      )}
      {context.isParent && (
        <Box
          component="div"
          sx={{
            position: 'absolute',
            left: `calc((20px - 4px) / 2 + ${context.depth} * ${depthSpacing})`,
            bottom: `calc(${itemDividerHeight} / -2)`,
            width: 4,
            height: `calc(100% + (${itemDividerHeight} - ${itemHeight} - 4px) / 2)`,
            backgroundColor: theme.palette.divider,
            ...(context.isRoot && {
              height: `calc((${itemHeight} + ${itemDividerHeight} - 20px) / 2)`,
            }),
          }}
        />
      )}
      {Array.from(context.isNotLastDescendantAt.values()).map((depth) => {
        return (
          <Fragment key={depth}>
            <Box
              component="div"
              sx={{
                position: 'absolute',
                left: `calc((20px - 4px) / 2 + ${depth - 1} * ${depthSpacing})`,
                top: `calc(${itemDividerHeight} / -2)`,
                width: 4,
                height: `calc(${itemHeight} + ${itemDividerHeight})`,
                backgroundColor: theme.palette.divider,
              }}
            />
            <Box
              component="div"
              sx={{
                position: 'absolute',
                left: `calc((20px + 4px) / 2 + ${depth - 1} * ${depthSpacing})`,
                top: `calc((${itemHeight} - 4px) / 2)`,
                width: (20 - 4) / 2 + 2 * spacing,
                height: 4,
                backgroundColor: theme.palette.divider,
              }}
            />
          </Fragment>
        );
      })}
      {context.isLastChild && (
        <>
          <Box
            component="div"
            sx={{
              position: 'absolute',
              left: `calc((20px - 4px) / 2 + ${
                context.depth - 1
              } * ${depthSpacing})`,
              top: `calc((${itemHeight} - 4px) / 2)`,
              width: (20 + 4) / 2 + 2 * spacing,
              height: 4,
              backgroundColor: theme.palette.divider,
            }}
          />
          <Box
            component="div"
            sx={{
              position: 'absolute',
              left: `calc((20px - 4px) / 2 + ${
                context.depth - 1
              } * ${depthSpacing})`,
              top: `calc(${itemDividerHeight} / -2)`,
              width: 4,
              height: `calc((${itemHeight} + ${itemDividerHeight} - 4px) / 2)`,
              backgroundColor: theme.palette.divider,
            }}
          />
        </>
      )}
    </>
  );
};

enum Status {
  Online = 'online',
  Offline = 'offline',
  Problem = 'problem',
}

const STATUS_CHIP_DATA = new Map<
  Status,
  { color: string; icon: ReactElement; label: ReactNode }
>([
  [
    Status.Online,
    { color: BRAND_COLOR_GREEN, icon: <CheckOutlinedIcon />, label: 'Online' },
  ],
]);

const StatusChip: FC<{
  status: Status;
}> = (props) => {
  const data = STATUS_CHIP_DATA.get(props.status);

  if (!data) return null;

  const { color, icon, label } = data;

  return (
    <Chip background={color} textColor="white" startAdornment={icon}>
      {label}
    </Chip>
  );
};

export const StatusPage: FC = () => {
  const theme = useTheme();
  const spacing = useSpacing();
  const hierarchyItems = SERVICE_ITEMS.flatMap((serviceItem) =>
    flattenServiceItem(serviceItem)
  );

  return (
    <>
      <PageTitle>Status</PageTitle>
      <Section id="statusPage_introductionSection" three={<StatusPageThree />}>
        <Container maxWidth="lg">
          <PageHeader></PageHeader>
          <Breadcrumbs
            variant="pageHeader"
            prefixBrandCircle
            items={[
              {
                to: '/status',
                text: 'Status',
              },
            ]}
          />
          {/* <Typography variant="h3" fontWeight="normal">
            Some services are unreachable
          </Typography> */}
          <TransdimensionalTable
            id="statusPage_indexSection-table"
            headCells={[
              {
                id: 'id',
                width: 0,
                offsetLeft: 20 + spacing * 2,
                content: 'Identifier',
                align: 'left',
              },
              {
                id: 'type',
                content: 'Type',
                align: 'left',
              },
              {
                id: 'description',
                content: 'Description',
                align: 'left',
              },
              {
                id: 'status',
                content: 'Status',
                align: 'right',
              },
            ]}
            rows={hierarchyItems.map((hierarchyItem) => {
              const { value, context } = hierarchyItem;

              return {
                id: value.id,
                cursor: 'pointer' as const,
                offsetLeft: 20 + spacing * 2 + context.depth * spacing * 2,
                content: (
                  <>
                    <TableCell
                      align="left"
                      sx={{
                        paddingLeft: `calc(20px + ${theme.spacing(
                          2 * 2
                        )} + ${theme.spacing(context.depth * 2)})`,
                      }}
                    >
                      <HierarchyItemIndicator
                        context={context}
                        itemHeight="64px"
                        itemDividerHeight={TABLE_ROW_DIVIDER_HEIGHT + 'px'}
                      />
                      <TransdimensionalRect
                        id={value.id}
                        display="inline-block"
                      >
                        <TransdimensionalOffset z={0.05} display="inline-block">
                          <PresentationChip presentationChip={value.chip} />
                        </TransdimensionalOffset>
                      </TransdimensionalRect>
                    </TableCell>
                    <TableCell align="left">
                      <ServiceItemTypeLabel type={value.type} />
                    </TableCell>
                    <TableCell align="left">
                      <Typography variant="body1">
                        {value.description}
                      </Typography>
                    </TableCell>
                    <TableCell align="right">
                      <StatusChip status={Status.Online} />
                    </TableCell>
                  </>
                ),
              };
            })}
          />
        </Container>
        <Box component="div" sx={{ height: '100vh' }} />
      </Section>
    </>
  );
};
