import { FC, PropsWithChildren } from 'react';
import Color from 'colorjs.io';
import { Container, PaletteColor, Typography, useTheme } from '@mui/material';
import {
  Chip,
  Heading,
  InlineLink,
  NoWrap,
  Section,
  TransdimensionalRect,
} from '../../../..';
import {
  ReferenceBrandPagePaletteTable,
  ReferenceBrandPagePaletteSectionThree,
} from '.';

export const ReferenceBrandPagePaletteSection: FC = () => {
  const theme = useTheme();

  return (
    <Section
      id="color-palette-section"
      three={<ReferenceBrandPagePaletteSectionThree />}
    >
      <Heading variant="h3" id="color-palette">
        Color Palette
      </Heading>
      <Typography variant="body1">
        Color is at the core of my brand's visual identity.&nbsp;&nbsp;The
        palette is inspired by the rich gradient of colors produced by{' '}
        <InlineLink to="https://en.wikipedia.org/wiki/Amberina">
          amberina glass
        </InlineLink>
        .&nbsp;&nbsp;This glass chemistry achieves its vibrant range of colors
        by including additives such as selenium, cadmium, and gold.&nbsp;&nbsp;I
        view it as a symbol of our scientific advancements enabling us to create
        immense beauty.
      </Typography>
      <br />
      <Typography variant="body1">
        In homage to its mid-century roots, the palette is composed of a
        gradient starting at{' '}
        <NoWrap>
          <Chip
            id="palette-paragraph-color-brand-red"
            background={theme.palette.brandRed.main}
          >
            Selenium Red
          </Chip>
          ,
        </NoWrap>{' '}
        arcing through{' '}
        <NoWrap>
          <Chip
            id="palette-paragraph-color-brand-orange"
            background={theme.palette.brandOrange.main}
            textColor={theme.palette.common.white}
          >
            Cadmium Orange
          </Chip>
          ,
        </NoWrap>{' '}
        and terminating at{' '}
        <NoWrap>
          <Chip
            id="palette-paragraph-color-brand-yellow"
            background={theme.palette.brandYellow.main}
          >
            New Yeller
          </Chip>
          .
        </NoWrap>
      </Typography>
      <br />
      <Typography variant="body1">
        For situations where white text is rendered over a gradient of the brand
        colors, the dark variant of New Yeller should be used.
      </Typography>
      <br />
      <ReferenceBrandPagePaletteTable
        colors={[
          {
            id: 'theme.palette.brandRed.main',
            color: theme.palette.brandRed.main,
            textColor: theme.palette.brandRed.contrastText,
          },
          {
            id: 'theme.palette.brandOrange.main',
            color: theme.palette.brandOrange.main,
            textColor: theme.palette.brandOrange.contrastText,
          },
          {
            id: 'theme.palette.brandYellow.main',
            color: theme.palette.brandYellow.main,
            textColor: theme.palette.brandYellow.contrastText,
          },
          {
            id: 'theme.palette.brandYellow.dark',
            color: theme.palette.brandYellow.dark,
            textColor: theme.palette.common.white,
          },
        ]}
      />
      <br />
    </Section>
  );
};
