import {
  FC,
  PropsWithChildren,
  MutableRefObject,
  useEffect,
  useRef,
} from 'react';
import { Color, Group, Material, Mesh, Object3D, Vector3 } from 'three';
import { useSelector } from 'react-redux';
import { getOrCreateThreeModelSelector } from '../../selectors';
import {
  toVector3,
  transformThreeModelScale,
  getMaterialType,
  createMaterialOfType,
  setMeshColorParametric,
} from '../../utilities';
import {
  Vector3Coercible,
  EulerCoercible,
  BoxColorCoercible,
  MaterialType,
  ObjectProps,
  MeshProps,
} from '../../types';
import { useMaterial } from '../../hooks';

export interface ThreeModelProps extends ObjectProps, MeshProps {
  id: string;
  log?: boolean;
}

export const ThreeModel: FC<PropsWithChildren<ThreeModelProps>> = ({
  id,
  position,
  scale,
  rotation,
  log,
  ...props
}) => {
  const standardMaterial = useMaterial();
  const material = props.material ?? standardMaterial;
  const groupRef = useRef<Group>();
  const modelRef = useRef<Object3D>();
  const threeModelOriginal = useSelector(getOrCreateThreeModelSelector(id));

  useEffect(() => {
    (async () => {
      const group = groupRef.current;

      if (!group) {
        if (log) console.log('group ref not set');

        return;
      }

      if (!threeModelOriginal) {
        if (log) console.log('model not loaded');

        return;
      }

      if (log) console.log('adding model to group', threeModelOriginal);

      const model = (modelRef.current = threeModelOriginal.scene.clone(true));

      group.add(model);
      group.traverse((object) => {
        if (object.type !== 'Mesh') return;

        const mesh = object as Mesh;

        if (typeof props.castShadow === 'boolean') {
          mesh.castShadow = props.castShadow;
        }

        if (typeof props.receiveShadow === 'boolean') {
          mesh.receiveShadow = props.receiveShadow;
        }

        mesh.material = material;
      });
    })();
  }, [threeModelOriginal]);

  useEffect(() => {
    const group = groupRef.current;

    if (!group) {
      return;
    }

    group.traverse((object) => {
      if (object.type !== 'Mesh') return;

      const mesh = object as Mesh;
      mesh.material = material;
    });
  }, [material]);

  if (log) {
    console.log('render with position', position);
  }

  return (
    <group
      ref={groupRef as MutableRefObject<any>}
      scale={scale && transformThreeModelScale(scale)}
      position={position}
      rotation={rotation}
    />
  );
};
